import { Form, Formik } from "formik";
import TextField from "../Common/Fields/TextField";
import PageHeader from "../Common/PageHeader";
import { Constants } from "../Common/Constants";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import * as Yup from "yup";
import {
  addInvoiceSetting,
  editInvoiceSetting,
  getInvoiceSetting,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import usePermissions from "../Common/Functions/usePermissions";
import ToggleField from "../Common/Fields/ToggleField";
import TextEditor from "../Common/Fields/TextEditor";
import { useSelector } from "react-redux";

const section = { type: Constants.configContactUsKey };

const Invoice = () => {
  const dispatch = useDispatch();
  // Permission
  const addPermission = usePermissions(Constants.permission.CONFG_ADD);
  const editPermission = usePermissions(Constants.permission.CONFG_EDIT);
  const { invoiceList } = useSelector((state) => state.Configuration);

  useEffect(() => {
    // Get invoice setting detail
    dispatch(getInvoiceSetting());
  }, []);

  // Initial values for invoice form
  const [initialValues, setInitialValues] = useState({
    toggle_generate_invoice: true,
    prefix: "",
    serial_no: "",
    note_on_invoice: "",
  });

  useEffect(() => {
    if (invoiceList?.data) {
      const editValue = {};
      for (const key of Object.keys(initialValues)) {
        editValue[key] = invoiceList?.data[key];
      }
      setInitialValues(editValue);
    }
  }, [invoiceList]);

  // Validation schema for invoice form
  const validationSchema = Yup.object().shape({
    serial_no: Yup.string()
      .required(Constants.required)
      .matches(Constants.validPositiveNumber, Constants.invalid),
    prefix: Yup.string()
      .required(Constants.required)
      .matches(Constants.validPrefix, Constants.invalid),
  });

  // Handle the change of editor
  const onEditorChange = function (editor, value, setFieldValue) {
    const data = editor.getData();
    setFieldValue(value, data);
  };

  return (
    <div className="container-xxl">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          // add invoice values API
          let submitFunc = addInvoiceSetting(values);
          if (invoiceList?.data?._id) {
            submitFunc = editInvoiceSetting(values);
          }
          dispatch(submitFunc).then((response) => {
            if (response.error) {
              dispatch({
                type: types.ERROR_ALERT_VISIBLE,
                payload: response.error,
              });
            } else {
              dispatch({
                type: types.SUCCESS_ALERT_VISIBLE,
                payload: response.data.message,
              });
              dispatch(getInvoiceSetting(section));
            }
          });
        }}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form className="form-control p-3 w-100 list-view card">
            <div className="row">
              <PageHeader pagetitle={Constants.configInvoice} />
              <div className="col-md-4 col-sm-6">
                <TextField
                  type="text"
                  label={Constants.prefix}
                  name="prefix"
                  errors={touched?.prefix ? errors?.prefix : undefined}
                  touched={touched?.prefix}
                />
              </div>
              <div className="col-md-4 col-sm-6">
                <TextField
                  type="text"
                  label={Constants.serialNo}
                  name="serial_no"
                  errors={touched?.serial_no ? errors?.serial_no : undefined}
                  touched={touched?.serial_no}
                />
              </div>
              <div className="col-md-4 col-sm-6">
                <label className="form-label">
                  {Constants.generateInvoice}
                </label>
                <div className="flex-toggle">
                  <ToggleField
                    onChange={(e) => {
                      setFieldValue(
                        "toggle_generate_invoice",
                        e.target.checked
                      );
                    }}
                    checked={values.toggle_generate_invoice}
                  />
                </div>
              </div>
              <div className="col-md-12 my-2">
                <TextEditor
                  label={Constants.noteOnInvoice}
                  hideRequiredIcon={true}
                  onChange={(_event, editor) =>
                    onEditorChange(editor, "note_on_invoice", setFieldValue)
                  }
                  data={`${values?.note_on_invoice || ""}`}
                />
              </div>
            </div>
            {(addPermission || editPermission) && (
              <ButtonCustom type="submit" label={Constants.submit} />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Invoice;

import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TabsBootstrap from "../../Common/UiModel/TabsBootstrap";
import PackageDetailPage from "./Detail/PackageDetailPage";
import PackageCourse from "./Course";
import { Constants } from "../../Common/Constants";
import WelcomeEmail from "../../Courses/CourseDetail/WelcomeEmail";
import { RoutesUrl } from "../../Common/RoutesUrl";
import PageHeader from "../../Common/PageHeader";
import EnrolledUsersList from "../../Courses/CourseDetail/EnrolledUsers";
import usePermissions from "../../Common/Functions/usePermissions";

const PackageDetail = () => {
  const addPermission = usePermissions(Constants.permission.PACKAGE_ADD);
  const editPermission = usePermissions(Constants.permission.PACKAGE_EDIT);
  const userViewPermission = usePermissions(Constants.permission.USER_VIEW);

  const tabsList = [
    {
      eventKey: "detail",
      title: Constants.detail,
      component: <PackageDetailPage />,
    },
    {
      eventKey: "course",
      title: Constants.course,
      component: <PackageCourse />,
    },
    {
      eventKey: "email",
      title: Constants.welcomeEmail,
      component: (
        <WelcomeEmail
          type={Constants.package}
          addPermission={addPermission}
          editPermission={editPermission}
        />
      ),
    },
    {
      eventKey: "enrolled",
      title: Constants.enrolledUsers,
      component: (
        <EnrolledUsersList
          type={Constants.package}
          userViewPermission={userViewPermission}
        />
      ),
    },
  ];

  const [activetab, setActiveTab] = useState(tabsList?.[0]?.eventKey);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get(Constants.activeTab);
    if (tabParam && tabsList.map((elt) => elt.eventKey).includes(tabParam)) {
      setActiveTab(tabParam);
    }
  }, [location]);

  const handleTabSelect = (key) => {
    setActiveTab(key);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(Constants.activeTab, key);
    navigate(`?${queryParams.toString()}`);
  };

  return (
    <div className="container-xxl common-space">
      <PageHeader list={RoutesUrl.package} />
      <div className="row catalog_box al_catalog_box">
        <div className="col-md-12 mb-4 order-list-view">
          {tabsList.length > 0 && (
            <TabsBootstrap
              defaultActiveKey={activetab}
              onSelect={(index) => {
                setActiveTab(index);
              }}
              activeKey={activetab}
              handleTabSelect={handleTabSelect}
              options={tabsList}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PackageDetail;

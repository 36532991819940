import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Constants } from "../../../Common/Constants";
import { types } from "../../../../Redux/Actions/types";
import TextField from "../../../Common/Fields/TextField";
import TextEditor from "../../../Common/Fields/TextEditor";
import ButtonCustom from "../../../Common/Fields/ButtonCustom";
import {
  welcomeEmailInsert,
  welcomeEmailList,
} from "../../../../Redux/Actions";

const WelcomeEmailForm = ({ data, type, editPermission, addPermission }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    subject: "",
    content: "",
  });

  // Validation schme for welcome email form
  const validationSchema = Yup.object().shape({
    subject: Yup.string().required(Constants.required),
    content: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    return () => {
      dispatch({ type: types.WELCOME_EMAIL_LIST, payload: [] });
    };
  }, []);

  // Pre fill the email form
  useEffect(() => {
    if (data) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (data[key]) {
          editValue[key] = data[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [data]);

  // Handle the change of editor
  const onEditorChange = function (editor, value, setFieldValue) {
    const data = editor.getData();
    setFieldValue(value, data);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Set the email type based on email type
        if (type === Constants.package) {
          values.package = id;
          values.emailType = 1;
        } else if (type === Constants.event) {
          values.event = id;
          values.emailType = 2;
        } else {
          values.course = id;
        }
        // Welcome email API
        const submitFunc = welcomeEmailInsert(values);
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
          }
          const data = {
            type,
            id,
          };
          dispatch(welcomeEmailList(data));
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="subject"
              className="form-control"
              errors={touched?.subject ? errors?.subject : undefined}
              touched={touched?.subject}
              label={Constants.subject}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextEditor
              label={Constants.content}
              onChange={(_event, editor) =>
                onEditorChange(editor, "content", setFieldValue)
              }
              data={`${values?.content || ""}`}
              errors={touched?.content ? errors?.content : undefined}
              touched={touched?.content}
            />
          </div>
          {((addPermission && !data) || (editPermission && data)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default WelcomeEmailForm;

export const RoutesUrl = {
  abandonedCourses: "/abandoned-courses",
  affiliateVideo: "/afiliate-video",
  affiliateUsers: "/affiliate",
  banner: "/banner",
  banners: "/banner",
  catalog: "/catalog",
  configBasic: "config-basic",
  configContactUs: "/config_contact_us",
  configFaq: "config_faq",
  configGoals: "/config-goals",
  configLoyalty: "/config_loyalty",
  configInvoice: "/invoice",
  configPricing: "/pricing",
  configPrivacyPolicy: "/privacy_policy",
  configRefer: "/config_refer_earn",
  configRefund: "/config_refund",
  configSales: "/config_sales_key",
  configTermsConditions: "terms_conditions_key",
  configWalkthrough: "/config-walkthrough",
  courses: "/courses",
  dashboard: "/dashboard",
  event: "/event",
  forbidden: "forbidden",
  freeVideo: "/freeVideo",
  kyc: "/kyc",
  logs: "/logs",
  manageRoles: "/manage-roles",
  manageUsers: "/manage-cust",
  news: "/news",
  notificationEmail: "/notification-mail",
  notificationNotification: "/notification-notifi",
  overview: "/overview-report",
  package: "/package",
  pageNotFound: "/page-404",
  promocode: "/promocode",
  reportDeletedUsers: "/deletedreport",
  signIn: "/sign-in",
  staticDashboard: "/static-dashboard",
  support: "/support",
  transactions: "/transactions",
  users: "/users",
  withdraw: "/withdraw",
};

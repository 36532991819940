import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { useParams } from "react-router-dom";
import TextField from "../../../Common/Fields/TextField";
import { Constants } from "../../../Common/Constants";
import ButtonCustom from "../../../Common/Fields/ButtonCustom";
import RequiredIcon from "../../../Common/Fields/RequiredIcon";
import MultiSelect from "../../../Common/Fields/MultiSelect";
import ReactSelect from "../../../Common/Fields/ReactSelect";
import { quesInsert, quesList, quesUpdate } from "../../../../Redux/Actions";
import { types } from "../../../../Redux/Actions/types";
import RadioButton from "../../../Common/Fields/RadioButton";
import usePermissions from "../../../Common/Functions/usePermissions";

const QuesForm = ({ set }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [ansOptions, setAnsOptions] = useState([]);
  const [ansSelected, setAnsSelected] = useState([]);
  const [questionType, setQuestionType] = useState(
    Constants.ansChoiceOptions[0]
  );
  const initValues = {
    question: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: "",
    question_type: Constants.ansChoiceOptions[0].key,
    course: id,
    set,
  };
  const [initialValues, setInitialValues] = useState(initValues);
  const { quesSingle } = useSelector((state) => state.Quiz);
  // permissions
  const addPermission = usePermissions(Constants.permission.COURSES_ADD);
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);

  // Validations for quiz form
  const validationSchema = Yup.object().shape({
    question: Yup.string().required(Constants.required),
    option1: Yup.object().required(Constants.required),
    option2: Yup.object().required(Constants.required),
    option3: Yup.object().required(Constants.required),
    option4: Yup.object().required(Constants.required),
  });

  // Set initial values to pre-fill the quiz form
  useEffect(() => {
    if (quesSingle) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (quesSingle[key]) {
          editValue[key] = quesSingle[key];
        }
        if (key === "question_type") {
          const type = +quesSingle[key];
          editValue[key] = type;
          const typeObj = Constants.ansChoiceOptions.find(
            (elt) => elt.key === type
          );
          setQuestionType(typeObj);
          type === 0
            ? setAnsSelected(quesSingle?.answer)
            : setAnsSelected(
                quesSingle?.answer.map(({ _id, ...rest }) => rest)
              );
        }
        if (key === "answer") {
          editValue[key] = quesSingle?.[key].map(({ _id, ...rest }) => rest);
        }
      }
      const option1 = {
        value: quesSingle?.options?.[0].value,
        label: quesSingle?.options?.[0].label,
      };
      const option2 = {
        value: quesSingle?.options?.[1].value,
        label: quesSingle?.options?.[1].label,
      };
      const option3 = {
        value: quesSingle?.options?.[2].value,
        label: quesSingle?.options?.[2].label,
      };
      const option4 = {
        value: quesSingle?.options?.[3].value,
        label: quesSingle?.options?.[3].label,
      };
      editValue.option1 = option1;
      editValue.option2 = option2;
      editValue.option3 = option3;
      editValue.option4 = option4;
      const options = [option1, option2, option3, option4];
      editValue.options = options;
      editValue.set = set;
      editValue.course = id;
      setAnsOptions(options);
      setInitialValues(editValue);
    }
  }, [quesSingle]);

  // On adding the option for quiz
  const onOptionChange = (e, setKey, key, setFieldValue) => {
    const obj = { value: key, label: e.target.value };
    setFieldValue(setKey, obj);
    const options = [...ansOptions];
    const idx = options.findIndex((elt) => elt.value === key);
    if (idx !== -1) {
      options.splice(idx, 1);
    }
    options.push(obj);
    setAnsOptions(options);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        const { option1, option2, option3, option4 } = values;
        delete values.option1;
        delete values.option2;
        delete values.option3;
        delete values.option4;
        values.options = ansOptions;
        // Quiz add or edit API
        let submitFunc = quesInsert(values);
        if (quesSingle?._id) {
          const id = quesSingle?._id;
          submitFunc = quesUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            if (quesSingle?._id) {
              resetForm();
            } else {
              values.option1 = option1;
              values.option2 = option2;
              values.option3 = option3;
              values.option4 = option4;
            }
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(quesList({ id: set }));
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.QUES_SINGLE, payload: "" });
            dispatch({ type: types.QUES_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="question"
              className="form-control"
              errors={touched?.question ? errors?.question : undefined}
              touched={touched?.question}
              label={Constants.ques}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="option1.label"
              className="form-control"
              errors={touched?.option1 ? errors?.option1 : undefined}
              touched={touched?.option1}
              onChange={(e) => {
                onOptionChange(e, "option1", 1, setFieldValue);
              }}
              label={Constants.option1}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="option2.label"
              className="form-control"
              errors={touched?.option2 ? errors?.option2 : undefined}
              touched={touched?.option2}
              onChange={(e) => {
                onOptionChange(e, "option2", 2, setFieldValue);
              }}
              label={Constants.option2}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="option3.label"
              className="form-control"
              errors={touched?.option3 ? errors?.option3 : undefined}
              touched={touched?.option3}
              onChange={(e) => {
                onOptionChange(e, "option3", 3, setFieldValue);
              }}
              label={Constants.option3}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="option4.label"
              className="form-control"
              errors={touched?.option4 ? errors?.option4 : undefined}
              touched={touched?.option4}
              onChange={(e) => {
                onOptionChange(e, "option4", 4, setFieldValue);
              }}
              label={Constants.option4}
            />
          </div>
          {ansOptions?.length === 4 && (
            <>
              <div className="col-md-6 mt-2">
                <RadioButton
                  label={Constants.ansChoiceType}
                  options={Constants.ansChoiceOptions}
                  name="question_type"
                  checked={questionType}
                  onChange={(option) => {
                    setFieldValue("question_type", option.key);
                    setAnsSelected(option.key === 0 ? [] : "");
                    setQuestionType(option);
                  }}
                />
              </div>
              {questionType.key === 0 ? (
                <div className="col-md-6 mb-2">
                  <label className="form-label">{`${Constants.selectAns}`}</label>
                  <RequiredIcon />
                  <MultiSelect
                    key="multi_select"
                    options={ansOptions}
                    onChange={(selected) => {
                      setFieldValue("answer", selected);
                      setAnsSelected(selected);
                    }}
                    value={ansSelected}
                    errors={touched?.answer ? errors?.answer : undefined}
                    touched={touched?.answer}
                    isSelectAll={true}
                    menuPlacement={"bottom"}
                  />
                </div>
              ) : (
                <div className="col-md-6 mb-4">
                  <ReactSelect
                    label={`${Constants.select} ${Constants.selectAns}`}
                    required={true}
                    options={ansOptions}
                    onChange={(selected) => {
                      setFieldValue("answer", [selected]);
                      setAnsSelected(selected);
                    }}
                    value={ansSelected}
                  />
                </div>
              )}
            </>
          )}
          {((addPermission && !quesSingle?._id) ||
            (editPermission && quesSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default QuesForm;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import PageHeader from "../../../Common/PageHeader";
import { Constants } from "../../../Common/Constants";
import { types } from "../../../../Redux/Actions/types";
import ModalPopup from "../../../Common/UiModel/ModalPopup";
import CoursesFaqsForm from "./CourseFaqsForm";
import {
  coursesFaqsDelete,
  coursesFaqsList,
  coursesFaqsUpdate,
} from "../../../../Redux/Actions";
import TableList from "../../../Common/UiModel/TableList";
import responseMethod from "../../../Common/Functions/responseMethod";
import { deleteConfirmBox } from "../../../Common/Functions/deleteConfirmBox";
import dayjs from "dayjs";
import EditListButton from "../../../Common/Fields/EditListButton";
import DeleteListButton from "../../../Common/Fields/DeleteListButton";
import ToggleListField from "../../../Common/Fields/ToggleListField";
import { Dropdown } from "react-bootstrap";
import ReactPagination from "../../../Common/Fields/ReactPagination";
import usePermissions from "../../../Common/Functions/usePermissions";

const CoursesFaqs = () => {
  const dispatch = useDispatch();
  const { coursesFaqslist, popupmodel } = useSelector(
    (state) => state.CoursesFaqs
  );
  const { id } = useParams();
  const [coursesFaqsData, setCoursesFaqsData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  // Permissions
  const addPermission = usePermissions(Constants.permission.COURSES_ADD);
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);
  const deletePermission = usePermissions(Constants.permission.COURSES_DELETE);

  // Get course faqs list
  useEffect(() => {
    dispatch(coursesFaqsList({ id }));
    dispatch({
      type: types.COURSES_FAQS_SINGLE,
      payload: "",
    });
  }, []);

  // Set data from course API to state
  useEffect(() => {
    setCoursesFaqsData(coursesFaqslist.data);
  }, [coursesFaqslist]);

  // Method to change the status of course faq
  const triggerToggle = (rowData) => {
    const itemId = rowData._id;
    const data = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(coursesFaqsUpdate(itemId, data)).then((response) => {
      const tempList = coursesFaqsData;
      const index = tempList.findIndex((item) => item._id === itemId);
      tempList[index].status = data.status;
      setCoursesFaqsData(tempList);
      responseMethod(response, dispatch, coursesFaqsList, id);
    });
  };

  // Function to delete the course faq
  const onDelete = (delete_id) => {
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(coursesFaqsDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(coursesFaqsList({ id }));
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  // Function to edit the course faq
  const handleEdit = (row) => {
    dispatch({
      type: types.COURSES_FAQS_FORM_MODEL,
      payload: {
        msg: Constants.editFaq,
        show: true,
        add: 0,
      },
    });
    dispatch({
      type: types.COURSES_FAQS_SINGLE,
      payload: row,
    });
  };

  // Columns for course faq list
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.ques,
      width: "30%",
      cell: (row) => (
        <Link to="" onClick={() => handleEdit(row)}>
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.question}
            onClick={() => navigator.clipboard.writeText(row?.question)}
          >
            {row?.question?.slice(0, 40) + "..."}
          </span>
        </Link>
      ),
    },
    {
      name: Constants.statusKey,
      cell: (row) => row?.status,
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    // Dropdpwn to edit, delete, change the status of course faq
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <EditListButton
                      onClick={() => {
                        handleEdit(row);
                      }}
                    />
                  </div>
                  {editPermission && (
                    <div className="list-group">
                      <ToggleListField
                        label={Constants.statusKey}
                        onChange={() => {
                          triggerToggle(row);
                        }}
                        checked={row.status === Constants.status.ACTIVE}
                      />
                    </div>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Pagination function
  const handleOffset = (newOff) => {
    const data = {
      id,
      skip: newOff,
      limit: Constants.limitCount,
    };
    dispatch(coursesFaqsList(data));
  };

  return (
    <>
      <div className="mt-3">
        <PageHeader
          pagetitle={Constants.faqList}
          buttonLink={addPermission}
          onClick={(e) => {
            e.preventDefault();
            dispatch({
              type: types.COURSES_FAQS_FORM_MODEL,
              payload: { msg: Constants.addFaq, show: true },
            });
          }}
        />
      </div>
      <TableList
        columns={columns}
        data={coursesFaqsData}
        defaultSortFieldId
        highlightOnHover
      />
      {coursesFaqslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={coursesFaqslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.COURSES_FAQS_SINGLE, payload: "" });
          dispatch({ type: types.COURSES_FAQS_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-lg"}
        title={popupmodel.msg}
        component={<CoursesFaqsForm />}
      />
    </>
  );
};

export default CoursesFaqs;

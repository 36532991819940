import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const resourcesAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.RESOURCES, data);
  return response;
};

export const resourcesEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.RESOURCES + "?id=" + id,
    data
  );
  return response;
};

export const resourcesRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.RESOURCES + "?id=" + id
  );
  return response;
};

export const resourcesStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.RESOURCES_STATUS_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const resources = async (data) => {
  const url = `${ApiUrl.RESOURCES}?course_id=${data?.id}&skip=${
    data?.skip || 0
  }&limit${data?.limit || Constants.limitCount}`;
  const response = await httpServiceInstance.get(url);
  return response;
};

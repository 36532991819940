import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const packagesAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.PACKAGES, data);
  return response;
};

export const packagesEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.PACKAGES + "?id=" + id,
    data
  );
  return response;
};

export const packagesRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.PACKAGES + "?id=" + id
  );
  return response;
};

export const packagesStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.PACKAGES_STATUS_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const packages = async (data) => {
  const url = `${ApiUrl.PACKAGES}?${
    data?.skipPagination ? Constants.paginationFalse : `skip=${data?.skip || 0}`
  }&limit=${data?.limit || Constants.limitCount}${
    data && data?.search ? `&search=${data?.search}` : ""
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const detailPackage = async (id) => {
  const response = await httpServiceInstance.get(ApiUrl.PACKAGES + `?id=${id}`);
  return response;
};

import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Constants } from "../../Common/Constants";
import TextField from "../../Common/Fields/TextField";
import ButtonCustom from "../../Common/Fields/ButtonCustom";
import TextArea from "../../Common/Fields/TextArea";
import RequiredIcon from "../../Common/Fields/RequiredIcon";
import {
  addAdminConfigurations,
  getAdminConfigurations,
} from "../../../Redux/Actions/Configuration/Configuration";
import { types } from "../../../Redux/Actions/types";
import ViewImageFile from "../../Common/Fields/ViewImageFile";
import FileAwsUpload from "../../Common/Fields/FileAwsUpload";
import usePermissions from "../../Common/Functions/usePermissions";

const WalkthroughForm = (props) => {
  const dispatch = useDispatch();
  const section = { type: Constants.configKeyWKTScreen };
  const [settings, setSettings] = useState([]);
  const [initialValues, setInitialValues] = useState({
    title1: "",
    title2: "",
    description: "",
    file: "",
    id: "",
    status: "",
    media_type: "",
  });
  // Permission
  const addPermission = usePermissions(Constants.permission.CONFG_ADD);
  const editPermission = usePermissions(Constants.permission.CONFG_EDIT);
  const { wktscreenSingle } = useSelector((state) => state.Walkthrough);

  // Validation schema for WKT form
  const validationSchema = Yup.object().shape({
    title1: Yup.string().required(Constants.required),
    title2: Yup.string().required(Constants.required),
    description: Yup.string().required(Constants.required),
    file: Yup.string().required(Constants.required),
  });
  // get admin confg data
  useEffect(() => {
    dispatch(getAdminConfigurations(section)).then((response) => {
      setSettings(response?.data?.data);
    });
  }, []);

  // Pre fill the WKT form
  useEffect(() => {
    if (wktscreenSingle) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (wktscreenSingle[key]) {
          editValue[key] = wktscreenSingle[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [wktscreenSingle]);

  // set WKT aws url
  const handleFileChange = (file, setFieldValue, field) => {
    setFieldValue(field, file?.[0]);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        let value;
        if (!wktscreenSingle) {
          // Set unique id for WKT screen
          values.id = Date.now();
          values.status = Constants.status.ACTIVE;
          if (settings.length > 0) {
            value = JSON.parse(settings?.[0]?.value);
            value.push(values);
          } else value = [values];
        } else {
          const array = JSON.parse(settings?.[0]?.value);
          const index = array.findIndex((elt) => elt.id === wktscreenSingle.id);
          array.splice(index, 1, values);
          value = array;
        }
        const dataForm = { ...section };
        const data = {};
        data[section.type] = JSON.stringify(value);
        dataForm.key_value = data;
        // WKT data values API
        const submitFunc = addAdminConfigurations(dataForm);
        dispatch(submitFunc).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response.data.message,
            });
            // Listing of WKT data
            props.newScreen();
            dispatch({ type: types.WKT_SCREEN_FORM_MODEL, payload: "" });
            dispatch({ type: types.WKT_SCREEN_SINGLE, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="title1"
              className="form-control"
              errors={touched?.title1 ? errors?.title1 : undefined}
              touched={touched?.title1}
              label={Constants.title1}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="title2"
              errors={touched?.title2 ? errors?.title2 : undefined}
              touched={touched?.title2}
              className="form-control"
              label={Constants.title2}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextArea
              type="text"
              name="description"
              className="form-control"
              errors={touched?.description ? errors?.description : undefined}
              touched={touched?.description}
              label={Constants.description}
            />
          </div>
          <div className="col-md-12 mb-2">
            <label className="form-label">{`${Constants.imageGif}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up">
                {values && !values?.file && (
                  <FileAwsUpload
                    fileType={[".jpeg", ".png", ".jpg", ".gif"]}
                    width="375"
                    height="317"
                    errors={touched?.file ? errors?.file : undefined}
                    touched={touched?.file}
                    label={Constants.uploadImageGif}
                    handleFile={(file) => {
                      handleFileChange(file, setFieldValue, "file");
                    }}
                    handleMediaType={(fileExtension) => {
                      setFieldValue(
                        "media_type",
                        fileExtension === "gif" ? "gif" : "image"
                      );
                    }}
                    maxFiles={1}
                  />
                )}
                {values && values?.file && (
                  <ViewImageFile
                    file={values?.file}
                    onClick={() => setFieldValue("file", "")}
                  />
                )}
              </div>
            </div>
          </div>
          {(addPermission || editPermission) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default WalkthroughForm;

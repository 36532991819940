import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { Constants } from "../../Common/Constants";
import { coursesList, sendNotification } from "../../../Redux/Actions";
import { types } from "../../../Redux/Actions/types";
import TextArea from "../../Common/Fields/TextArea";
import ButtonCustom from "../../Common/Fields/ButtonCustom";
import TextField from "../../Common/Fields/TextField";
import ReactSelect from "../../Common/Fields/ReactSelect";
import MultiSelect from "../../Common/Fields/MultiSelect";
import getOptionsFromData from "../../Common/Functions/getOptionsFromData";
import usePermissions from "../../Common/Functions/usePermissions";

const NotificationForm = ({ data }) => {
  const today = new Date().toISOString().split("T")[0];
  const dispatch = useDispatch();
  const [selectedSetRule, setSelectedSetRule] = useState(
    Constants.setRuleOptions?.[0]
  );
  const [deviceSelected, setDeviceSelected] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const { courseslist } = useSelector((state) => state.Courses);
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [coursesSelected, setCoursesSelected] = useState([]);
  const addPermission = usePermissions(Constants.permission.NOTIFI_ADD);
  // Initial values for notification form
  const [initialValues, setInitialValues] = useState({
    message: "",
    subject: "",
    user_type: [],
    device_type: [],
    courses: [],
    set_rule: Constants.setRuleOptions?.[0]?.value,
    scheduled_date: "",
    scheduled_time: "",
  });

  // Validation for notification form
  const validationSchema = Yup.object().shape({
    message: Yup.string()
      .required(Constants.required)
      .matches(/^.{1,1000}$/, `${Constants.characterLimitCrossed} (1000)`),
    subject: Yup.string()
      .required(Constants.required)
      .matches(/^.{1,100}$/, `${Constants.characterLimitCrossed} (100)`),
    scheduled_date:
      selectedSetRule?.value === "scheduled"
        ? Yup.string().required(Constants.required)
        : "",
    scheduled_time:
      selectedSetRule?.value === "scheduled"
        ? Yup.string().required(Constants.required)
        : "",
  });

  // COurse list API
  useEffect(() => {
    dispatch(coursesList({ skipPagination: true }));
  }, []);

  // Convert course list API data to array of objects (value & label)
  useEffect(() => {
    setCoursesOptions(getOptionsFromData(courseslist?.data));
  }, [courseslist?.data]);

  // set prefilled value for notification form (while viewing the notification)
  useEffect(() => {
    if (data) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (data[key]) {
          editValue[key] = data[key];
        }
        if (key === "user_type") {
          const arr = data[key]?.map((elt) =>
            Constants.usersTypeOptions?.find((item) => item.value === elt)
          );
          setUsersSelected(arr);
        }
        if (key === "device_type") {
          const arr = data[key]?.map((elt) =>
            Constants?.deviceTypeOptions?.find((item) => item.value === elt)
          );
          setDeviceSelected(arr);
        }
        if (key === "set_rule") {
          const arr = Constants?.setRuleOptions?.find(
            (item) => item.value === data[key]
          );
          setSelectedSetRule(arr);
        }
        setInitialValues(editValue);
      }
    }
  }, [data]);

  // Handle the change for device type, user type & course selection
  const handleOptionsChange = (e, setFieldValue, field, setSelected) => {
    setSelected(e);
    const ids = e.map((elt) => elt.value);
    setFieldValue(field, ids);
  };

  return (
    <div className="course-form card">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          // Send notification API
          const submitFunc = sendNotification(values);
          dispatch(submitFunc).then((response) => {
            if (response?.error) {
              dispatch({
                type: types.ERROR_ALERT_VISIBLE,
                payload: response?.error,
              });
            } else {
              // Reset the form values
              resetForm();
              setUsersSelected([]);
              setDeviceSelected([]);
              setSelectedSetRule(Constants.setRuleOptions?.[0]);
              dispatch({
                type: types.SUCCESS_ALERT_VISIBLE,
                payload: response?.data?.message,
              });
            }
          });
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form className="row">
            <div className="col-md-12 mb-4">
              <TextField
                type="text"
                name="subject"
                className="form-control"
                errors={touched?.subject ? errors?.subject : undefined}
                touched={touched?.subject}
                label={Constants.title}
                disabled={!!data}
              />
            </div>
            <div className="col-md-12 mb-4">
              <TextArea
                type="text"
                name="message"
                className="form-control"
                errors={touched?.message ? errors?.message : undefined}
                touched={touched?.message}
                label={Constants.message}
                disabled={!!data}
              />
            </div>
            <div className="col-md-4 mb-4">
              <label className="form-label">{Constants.deviceType}</label>
              <MultiSelect
                key="multi_select"
                options={Constants.deviceTypeOptions}
                onChange={(selected) =>
                  handleOptionsChange(
                    selected,
                    setFieldValue,
                    "device_type",
                    setDeviceSelected
                  )
                }
                value={deviceSelected}
                errors={touched?.device_type ? errors?.device_type : undefined}
                touched={touched?.device_type}
                isSelectAll={true}
                menuPlacement={"bottom"}
                isDisabled={!!data}
              />
            </div>
            <div className="col-md-4 mb-4">
              <label className="form-label">{Constants.usersType}</label>
              <MultiSelect
                key="multi_select"
                options={Constants.usersTypeOptions}
                onChange={(selected) =>
                  handleOptionsChange(
                    selected,
                    setFieldValue,
                    "user_type",
                    setUsersSelected
                  )
                }
                value={usersSelected}
                errors={touched?.user_type ? errors?.user_type : undefined}
                touched={touched?.user_type}
                isSelectAll={true}
                menuPlacement={"bottom"}
                isDisabled={!!data}
              />
            </div>
            {usersSelected
              ?.map((elt) => elt.value)
              .includes(Constants.usersTypeOptions?.[0]?.value) && (
              <div className="col-md-4 mb-4">
                <label className="form-label">{`${Constants.select} ${Constants.course}`}</label>
                <MultiSelect
                  key="multi_select"
                  options={coursesOptions}
                  onChange={(selected) =>
                    handleOptionsChange(
                      selected,
                      setFieldValue,
                      "courses",
                      setCoursesSelected
                    )
                  }
                  value={coursesSelected}
                  errors={touched?.courses ? errors?.courses : undefined}
                  touched={touched?.courses}
                  isSelectAll={true}
                  menuPlacement={"bottom"}
                />
              </div>
            )}
            <div className="col-md-4 mb-4">
              <ReactSelect
                label={Constants.setRule}
                required={true}
                options={Constants.setRuleOptions}
                value={selectedSetRule}
                onChange={(selected) => {
                  setFieldValue("set_rule", selected.value);
                  setSelectedSetRule(selected);
                }}
                isDisabled={!!data}
              />
            </div>
            {selectedSetRule?.value === "scheduled" && (
              <>
                <div className="col-md-4 mb-4">
                  <TextField
                    type="date"
                    name="scheduled_date"
                    className="form-control"
                    min={today}
                    errors={
                      touched?.scheduled_date
                        ? errors?.scheduled_date
                        : undefined
                    }
                    touched={touched?.scheduled_date}
                    label={Constants.selectDate}
                    disabled={!!data}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <TextField
                    type="time"
                    name="scheduled_time"
                    className="form-control"
                    errors={
                      touched?.scheduled_time
                        ? errors?.scheduled_time
                        : undefined
                    }
                    touched={touched?.scheduled_time}
                    label={`${Constants.select}${Constants.time}`}
                    disabled={!!data}
                  />
                </div>
              </>
            )}
            {!data && addPermission && (
              <ButtonCustom label={Constants.submit} type="submit" />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NotificationForm;

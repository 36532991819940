import React from "react";
import { Tab, Tabs } from "react-bootstrap";

const TabsBootstrap = (props) => {
  return (
    <>
      <Tabs
        defaultActiveKey={props.defaultActiveKey}
        id="uncontrolled-tab-example"
        className={props.className || ""}
        onSelect={props.handleTabSelect}
        activeKey={props.activeKey}
      >
        {props.options.map((tabs, i) => {
          return (
            <Tab eventKey={tabs.eventKey} title={tabs.title} key={i}>
              {props.activeKey === tabs.eventKey ? tabs.component : ""}
            </Tab>
          );
        })}
      </Tabs>
    </>
  );
};

export default TabsBootstrap;

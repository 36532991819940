import { types } from "../Actions/types";

const initialState = { isLoader: false };

export default function Loader (state = initialState, action) {
  const { type } = action;

  switch (type) {
    case types.LOADER_VISIBLE:
      return {
        isLoader: true,
      };

    case types.LOADER_HIDE:
      return {
        isLoader: false,
      };

    default:
      return state;
  }
}

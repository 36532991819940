import {
  detailPromocode,
  promocodes,
  promocodesAdd,
  promocodesEdit,
  promocodesRemove,
  promocodesStatusUpdate,
  usersPromocode,
} from "../../services/promocode.service";
import { types } from "./types";

export const promocodesInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await promocodesAdd(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const promocodesList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await promocodes(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.PROMOCODES_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.PROMOCODES_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const promocodesUpdate = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await promocodesEdit(id, data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const promocodesDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await promocodesRemove(id);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const promocodesUpdateStatus = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await promocodesStatusUpdate(id, data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const promocodesDetail = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await detailPromocode(id);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.PROMOCODES_SINGLE, payload: result.data.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.PROMOCODES_SINGLE,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const promocodesUsers = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await usersPromocode(id);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.PROMOCODES_USERS_LIST, payload: result.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.PROMOCODES_USERS_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

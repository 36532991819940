import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const newsAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.NEWS, data);
  return response;
};

export const newsEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.NEWS + "?id=" + id,
    data
  );
  return response;
};

export const newsRemove = async (id) => {
  const response = await httpServiceInstance.delete(ApiUrl.NEWS + "?id=" + id);
  return response;
};

export const newsStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.NEWS_STATUS_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const news = async (data) => {
  const url = `${ApiUrl.NEWS}?skip=${data?.skip || 0}&limit=${
    data?.limit || Constants.limitCount
  }${data && data?.search ? `&search=${data?.search}` : ""}`;
  const response = await httpServiceInstance.get(url);
  return response;
};

import React, { useEffect, useState } from "react";
import ButtonCustom from "../../../Common/Fields/ButtonCustom";
import { Constants } from "../../../Common/Constants";
import ReactSelect from "../../../Common/Fields/ReactSelect";
import { Form, Formik } from "formik";
import getOptionsFromData from "../../../Common/Functions/getOptionsFromData";
import {
  categoryList,
  coursesCategoryList,
  packageCoursesInsert,
  packageCoursesList,
  packageCoursesUpdate,
} from "../../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { types } from "../../../../Redux/Actions/types";
import usePermissions from "../../../Common/Functions/usePermissions";

const PackageCourseForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  // const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  // const [subCategorySelected, setSubCategorySelected] = useState([]);
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [coursesSelected, setCoursesSelected] = useState([]);
  // const { subCategorylist } = useSelector((state) => state.SubCategory);
  const { categorylist } = useSelector((state) => state.Category);
  const { coursescategorylist } = useSelector((state) => state.Courses);
  const { packageCoursesSingle } = useSelector((state) => state.PackageCourses);
  // Initial calues for package course form
  const [initialValues, setInitialValues] = useState({
    name: "",
    category_id: "",
    subcategory_id: null,
    course: "",
    package: id,
  });
  const addPermission = usePermissions(Constants.permission.PACKAGE_ADD);
  const editPermission = usePermissions(Constants.permission.PACKAGE_EDIT);

  // Validations for package course form
  const validationSchema = Yup.object().shape({
    category_id: Yup.string().required(Constants.required),
    course: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    // Category list API
    dispatch(categoryList());

    return () => {
      dispatch({ type: types.COURSES_CAT_LIST, payload: [] });
    };
  }, []);

  useEffect(() => {
    // Convert category list API data to array of objects (value & label)
    setCategoryOptions(getOptionsFromData(categorylist?.data));
  }, [categorylist?.data]);

  // useEffect(() => {
  //   setSubCategoryOptions(getOptionsFromData(subCategorylist?.data));
  // }, [subCategorylist?.data]);

  useEffect(() => {
    // Convert courses list API data to array of objects (value & label)
    setCoursesOptions(getOptionsFromData(coursescategorylist?.data));
  }, [coursescategorylist?.data]);

  useEffect(() => {
    // Set initial values to pre fill the package course form
    if (packageCoursesSingle) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        editValue[key] = packageCoursesSingle[key];
        if (key === "category_id") {
          const val = packageCoursesSingle[key];
          editValue[key] = val?._id;
          setCategorySelected({ value: val?._id, label: val?.name });
          // dispatch(subCategoryOfCategory(val?._id));
          const data = { category_id: val?.id };
          dispatch(coursesCategoryList(data));
        }
        // if (key === "subcategory_id") {
        //   let val = packageCoursesSingle[key];
        //   editValue[key] = val?._id;
        //   setSubCategorySelected({ value: val?._id, label: val?.name });
        //   let data = {
        //     category_id: packageCoursesSingle["category_id"]?._id,
        //     subcategory_id: val?._id,
        //   };
        //   dispatch(coursesCategoryList(data));
        // }
        if (key === "course") {
          const val = packageCoursesSingle[key];
          editValue[key] = val?._id;
          setCoursesSelected({ value: val?._id, label: val?.name });
        }
        setInitialValues(editValue);
      }
    }
  }, [packageCoursesSingle]);

  // Category selection
  const handleCategoryOptionChange = (e, setFieldValue, field, setSelected) => {
    // setSubCategorySelected([]);
    setCoursesSelected([]);
    // dispatch(subCategoryOfCategory(e.value));
    const data = { category_id: e.value };
    dispatch(coursesCategoryList(data));
    setSelected(e);
    setFieldValue(field, e.value);
  };

  // Sub category selection
  // const handleSubCategoryOptionChange = (
  //   e,
  //   setFieldValue,
  //   field,
  //   setSelected
  // ) => {
  //   setCoursesSelected([]);
  //   let data = { category_id: categorySelected.value, subcategory_id: e.value };
  //   dispatch(coursesCategoryList(data));
  //   setSelected(e);
  //   setFieldValue(field, e.value);
  // };

  // Course selection
  const handleCourseChange = (e, setFieldValue, field, setSelected) => {
    // setCoursesSelected([]);
    // let data = { category_id: categorySelected.value, subcategory_id: e.value };
    // dispatch(coursesCategoryList(data));
    setSelected(e);
    setFieldValue(field, e.value);
    setFieldValue("name", e.label);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update package course API
        let submitFunc = packageCoursesInsert(values);
        if (packageCoursesSingle?._id) {
          const id = packageCoursesSingle?._id;
          submitFunc = packageCoursesUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(packageCoursesList(id));
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.PACKAGE_COURSES_SINGLE, payload: "" });
            dispatch({ type: types.PACKAGE_COURSES_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mb-4">
            <ReactSelect
              label={Constants.selectCategory}
              required={true}
              options={categoryOptions}
              errors={touched?.category_id ? errors?.category_id : undefined}
              touched={touched?.category_id}
              onChange={(selected) =>
                handleCategoryOptionChange(
                  selected,
                  setFieldValue,
                  "category_id",
                  setCategorySelected
                )
              }
              value={categorySelected}
            />
          </div>
          {/* <div className="col-md-12 mb-4">
            <ReactSelect
              label={`${Constants.select} ${Constants.subCategory}`}
              required={false}
              options={subCategoryOptions}
              onChange={(selected) =>
                handleSubCategoryOptionChange(
                  selected,
                  setFieldValue,
                  "subcategory_id",
                  setSubCategorySelected
                )
              }
              value={subCategorySelected}
            />
          </div> */}
          <div className="col-md-12 mb-4">
            <ReactSelect
              label={`${Constants.select} ${Constants.course}`}
              required={true}
              options={coursesOptions}
              errors={touched?.course ? errors?.course : undefined}
              touched={touched?.course}
              onChange={(selected) =>
                handleCourseChange(
                  selected,
                  setFieldValue,
                  "course",
                  setCoursesSelected
                )
              }
              value={coursesSelected}
            />
          </div>
          {((addPermission && !packageCoursesSingle?._id) ||
            (editPermission && packageCoursesSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default PackageCourseForm;

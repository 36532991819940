import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import TextField from "../../../Common/Fields/TextField";
import { Constants } from "../../../Common/Constants";
import RequiredIcon from "../../../Common/Fields/RequiredIcon";
import * as Yup from "yup";
import ButtonCustom from "../../../Common/Fields/ButtonCustom";

import { types } from "../../../../Redux/Actions/types";
import { useParams } from "react-router-dom";
import ViewImageFile from "../../../Common/Fields/ViewImageFile";
import ViewVideoFile from "../../../Common/Fields/ViewVideoFile";
import {
  reviewsInsert,
  reviewsList,
  reviewsUpdate,
} from "../../../../Redux/Actions";
import VideoTusUpload from "../../../Common/Fields/VideoTusUpload";
import FileAwsUpload from "../../../Common/Fields/FileAwsUpload";
import VideoDuration from "../../../Common/Fields/VideoDuration";
import usePermissions from "../../../Common/Functions/usePermissions";

const ReviewForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: "",
    designation: "",
    thumbnail: "",
    thumbnail_web: "",
    video_thumbnail: "",
    video: "",
    duration: "",
    course: id,
  });
  const { reviewsSingle } = useSelector((state) => state.Reviews);
  const [showProgress, setShowProgress] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  // Permission
  const addPermission = usePermissions(Constants.permission.COURSES_ADD);
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);

  // Validation schema for review form
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
    designation: Yup.string().required(Constants.required),
    video: Yup.string().required(Constants.required),
    thumbnail: Yup.string().required(Constants.required),
    thumbnail_web: Yup.string().required(Constants.required),
    video_thumbnail: Yup.string().required(Constants.required),
  });

  // set initial values to pre-fill the review form
  useEffect(() => {
    if (reviewsSingle) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (reviewsSingle[key]) {
          editValue[key] = reviewsSingle[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [reviewsSingle]);

  // set thumbnail & video thumbnail aws url
  const handleFileChange = (file, setFieldValue, field) => {
    setFieldValue(field, file?.[0]);
  };

  // set videos aws url
  const handleVideoChange = (file, setFieldValue, field) => {
    setFieldValue(field, file);
    setShowProgress(true);
    setVideoUrl(file);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Review add or delete API
        let submitFunc = reviewsInsert(values);
        if (reviewsSingle?._id) {
          const id = reviewsSingle?._id;
          submitFunc = reviewsUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(reviewsList({ id }));
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.REVIEWS_SINGLE, payload: "" });
            dispatch({ type: types.REVIEWS_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="designation"
              className="form-control"
              errors={touched?.designation ? errors?.designation : undefined}
              touched={touched?.designation}
              label={Constants.designation}
            />
          </div>
          {[
            {
              name: "thumbnail",
              type: Constants.imageApp,
              width: 159,
              height: 185,
            },
            {
              name: "thumbnail_web",
              type: Constants.imageWeb,
              width: 228,
              height: 197,
            },
          ].map((item) => (
            <div className="col-md-6 mb-2" key={item.name}>
              <label className="form-label">{`${Constants.thumbnail} ${item?.type}`}</label>
              <RequiredIcon />
              <div className="out-cr">
                <div className="width-outer-up">
                  {values && !values?.[item.name] && (
                    <FileAwsUpload
                      fileType={[".jpeg", ".png", ".jpg"]}
                      width={item.width}
                      height={item.height}
                      errors={
                        touched?.[item.name] ? errors?.[item.name] : undefined
                      }
                      touched={touched?.[item.name]}
                      label={`${Constants.upload} ${Constants.thumbnail}`}
                      handleFile={(thumbnail) =>
                        handleFileChange(thumbnail, setFieldValue, item.name)
                      }
                      maxFiles={1}
                    />
                  )}
                  {values && values[item.name] && (
                    <ViewImageFile
                      file={values[item.name]}
                      onClick={() => setFieldValue(item.name, "")}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="col-md-6 mb-4">
            <label className="form-label">{`${Constants.video}`}</label>
            <RequiredIcon />
            <div>
              {values && !values?.video && (
                <VideoTusUpload
                  errors={touched?.video ? errors?.video : undefined}
                  touched={touched?.video}
                  label={`${Constants.upload} ${Constants.video}`}
                  handleFile={(video) =>
                    handleVideoChange(video, setFieldValue, "video")
                  }
                />
              )}
              {showProgress && videoUrl && (
                <VideoDuration
                  url={videoUrl}
                  fetchDuration={(duration) => {
                    setFieldValue("duration", duration);
                    setShowProgress(false);
                  }}
                />
              )}
              {values && !showProgress && values?.video && (
                <ViewVideoFile
                  file={values?.video}
                  onClick={() => {
                    setFieldValue("video", "");
                    setFieldValue("duration", "");
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">{`${Constants.video} ${Constants.thumbnail}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up">
                {values && !values?.video_thumbnail && (
                  <FileAwsUpload
                    fileType={[".jpeg", ".png", ".jpg"]}
                    width="1280"
                    height="720"
                    errors={
                      touched?.video_thumbnail
                        ? errors?.video_thumbnail
                        : undefined
                    }
                    touched={touched?.video_thumbnail}
                    label={`${Constants.upload} ${Constants.video} ${Constants.thumbnail}`}
                    handleFile={(video_thumbnail) =>
                      handleFileChange(
                        video_thumbnail,
                        setFieldValue,
                        "video_thumbnail"
                      )
                    }
                    maxFiles={1}
                  />
                )}
                {values && values?.video_thumbnail && (
                  <ViewImageFile
                    file={values?.video_thumbnail}
                    onClick={() => setFieldValue("video_thumbnail", "")}
                  />
                )}
              </div>
            </div>
          </div>
          {((addPermission && !reviewsSingle?._id) ||
            (editPermission && reviewsSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ReviewForm;

import { Form, Formik } from "formik";
import TextField from "../Common/Fields/TextField";
import TextArea from "../Common/Fields/TextArea";
import PageHeader from "../Common/PageHeader";
import { Constants } from "../Common/Constants";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import * as Yup from "yup";
import {
  addAdminConfigurations,
  getAdminConfigurations,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import PhoneField from "../Common/Fields/PhoneField";
import usePermissions from "../Common/Functions/usePermissions";

const section = { type: Constants.configContactUsKey };

const ContactUs = () => {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState([]);
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  // Permission
  const addPermission = usePermissions(Constants.permission.CONFG_ADD);
  const editPermission = usePermissions(Constants.permission.CONFG_EDIT);

  // Initial values for contact us form
  const [initialValues, setInitialValues] = useState({
    email: "",
    address: "",
    contact_dial_code: "",
    contact_number: "",
    whatsapp_dial_code: "",
    whatsapp_number: "",
  });

  // Validation schema for contact us form
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidEmailRegex, Constants.invalidEmail),
    address: Yup.string().required(Constants.required),
    contact_dial_code: Yup.string().required(Constants.required),
    contact_number: Yup.string().required(Constants.required),
    whatsapp_dial_code: Yup.string().required(Constants.required),
    whatsapp_number: Yup.string().required(Constants.required),
  });

  // get admin confg data
  useEffect(() => {
    dispatch(getAdminConfigurations(section)).then((response) => {
      setSettings(response.data.data);
    });
  }, []);

  // pre fill the contact us form
  useEffect(() => {
    const listdata = settings;
    if (listdata && listdata.length > 0) {
      const getDetail = (name) =>
        (listdata.find((item) => item.name === name) || {}).value;
      const contactDialCode = getDetail("contact_dial_code");
      const contactNumber = getDetail("contact_number");
      const whatsappDialCode = getDetail("whatsapp_dial_code");
      const whatsappNumber = getDetail("whatsapp_number");

      const editVal = {};
      for (const value of Object.values(listdata)) {
        setContactNumber(contactDialCode + contactNumber);
        setWhatsappNumber(whatsappDialCode + whatsappNumber);
        editVal[value.name] = value.value;
        setInitialValues(editVal);
      }
    }
  }, [settings]);

  return (
    <div className="container-xxl">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const dataForm = {};
          dataForm.key_value = values;
          dataForm.type = section.type;
          // add contact us values API
          const submitFunc = addAdminConfigurations(dataForm);
          dispatch(submitFunc).then((response) => {
            if (response.error) {
              dispatch({
                type: types.ERROR_ALERT_VISIBLE,
                payload: response.error,
              });
            } else {
              dispatch({
                type: types.SUCCESS_ALERT_VISIBLE,
                payload: response.data.message,
              });
              dispatch(getAdminConfigurations(section));
            }
          });
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form className="form-control p-3 w-100 list-view card">
            <div className="row">
              <PageHeader pagetitle={Constants.configContactUs} />
              <div className="col-md-4 col-sm-6">
                <TextField
                  type="text"
                  label={Constants.email}
                  name="email"
                  errors={touched?.email ? errors?.email : undefined}
                  touched={touched?.email}
                />
              </div>
              <div className="col-md-4 col-sm-6">
                <PhoneField
                  value={contactNumber}
                  name="phone"
                  onChange={(e, country) => {
                    setFieldValue("contact_dial_code", country.dialCode);
                    setFieldValue(
                      "contact_number",
                      e.slice(country.dialCode.length)
                    );
                  }}
                  label={Constants.phoneNumber}
                  errors={
                    touched?.contact_number ? errors?.contact_number : undefined
                  }
                  touched={touched?.contact_number}
                />
              </div>
              <div className="col-md-4 col-sm-6">
                <PhoneField
                  value={whatsappNumber}
                  name="phone"
                  onChange={(e, country) => {
                    setFieldValue("whatsapp_dial_code", country.dialCode);
                    setFieldValue(
                      "whatsapp_number",
                      e.slice(country.dialCode.length)
                    );
                  }}
                  label={Constants.whatsAppPhoneNumber}
                  errors={
                    touched?.whatsapp_number
                      ? errors?.whatsapp_number
                      : undefined
                  }
                  touched={touched?.whatsapp_number}
                />
              </div>
              <div className="col-md-12">
                <TextArea
                  type="text"
                  label={Constants.address}
                  name="address"
                  errors={touched?.address ? errors?.address : undefined}
                  touched={touched?.address}
                />
              </div>
            </div>
            {(addPermission || editPermission) && (
              <ButtonCustom type="submit" label={Constants.submit} />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactUs;

import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu } from "./Menu";
import logo from "../../assets/images/logo.png";
import { Constants } from "./Constants";
import { deleteConfirmBox } from "./Functions/deleteConfirmBox";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/Actions";
import { RoutesUrl } from "./RoutesUrl";

const Sidebar = (props) => {
  const [isSidebarMini, setIsSidebarMini] = useState(false);
  const [menuData, setMenuData] = useState([...Menu]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const roleType = localStorage.getItem(Constants.roleType);
  const { permissionsArr } = useSelector((state) => state.Auth);
  const {
    permission: {
      DASHBOARD_VIEW,
      CATEGORY_VIEW,
      COURSES_VIEW,
      TRANSACTION_VIEW,
      PACKAGE_VIEW,
      FREE_VIDEO_VIEW,
      PROMO_VIEW,
      BANNER_VIEW,
      EVENT_VIEW,
      NEWS_VIEW,
      USER_VIEW,
      KYC_VIEW,
      AFFILIATE_LESSON_VIEW,
      AFFILIATE_VIEW,
      WITHDRAW_VIEW,
      SUPPORT_VIEW,
      REPORT_VIEW,
      ROLE_MGT_VIEW,
      NOTIFI_VIEW,
      CONFG_VIEW,
    },
  } = Constants;

  // on click of main tab that has no children
  const openChildren = (id) => {
    const otherTabs = document.getElementsByClassName("has-children");
    if (otherTabs) {
      for (let i = 0; i < otherTabs.length; i++) {
        if (otherTabs[i].id !== id) {
          otherTabs[i].className = otherTabs[i].className.replace(" show", "");
          if (otherTabs[i].parentElement.children.length > 1) {
            otherTabs[i].parentElement.children?.[0].setAttribute(
              "aria-expanded",
              "false"
            );
          }
        }
      }
    }
    const menutab = document.getElementById(id);
    if (menutab) {
      if (menutab.classList.contains("show")) {
        menutab.classList.remove("show");
        if (menutab.parentElement.children.length > 1) {
          menutab.parentElement.children?.[0].setAttribute(
            "aria-expanded",
            "false"
          );
        }
      } else {
        menutab.classList.add("show");
        if (menutab.parentElement.children.length > 1) {
          menutab.parentElement.children?.[0].setAttribute(
            "aria-expanded",
            "true"
          );
        }
      }
    }
  };

  // Main side tab that has 1 or more children
  const openChildren1 = (id) => {
    const otherTabs = document.getElementsByClassName("has-children");
    if (otherTabs) {
      for (let i = 0; i < otherTabs.length; i++) {
        otherTabs[i].className = otherTabs[i].className.replace(" show", "");
      }
    }
    const menutab = document.getElementById(id);
    if (menutab) {
      menutab.classList.add("show");
      if (menutab.parentElement.children.length > 1) {
        menutab.parentElement.children?.[0].setAttribute(
          "aria-expanded",
          "true"
        );
      }
    }
  };

  const GotoChangeMenu = () => {
    props.history.push("dashboard");
    setMenuData([...Menu]);
  };

  // Signout API
  const signOut = () => {
    deleteConfirmBox({ text: Constants.youWantToSignOut }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout()).then((response) => {
          if (response?.data === 200) {
            navigate(RoutesUrl.signIn);
            localStorage.clear();
          }
        });
      }
    });
  };

  const { activekey } = props;

  // Whether user role has permission or not
  const checkPermission = (permissionKey, valueToCheck = true) => {
    return permissionsArr?.[permissionKey] !== valueToCheck;
  };

  // Sidebar tabs cases for view
  const shouldHideMenuItem = (d) => {
    switch (d.name) {
      case Constants.dashboard:
        return checkPermission(DASHBOARD_VIEW);
      case Constants.category:
        return checkPermission(CATEGORY_VIEW);
      case Constants.course:
        return checkPermission(COURSES_VIEW);
      case Constants.transactions:
        return checkPermission(TRANSACTION_VIEW);
      case Constants.package:
        return checkPermission(PACKAGE_VIEW);
      case Constants.freeVideo:
        return checkPermission(FREE_VIDEO_VIEW);
      case Constants.promoCode:
        return checkPermission(PROMO_VIEW);
      case Constants.banner:
        return checkPermission(BANNER_VIEW);
      case Constants.event:
        return checkPermission(EVENT_VIEW);
      case Constants.news:
        return checkPermission(NEWS_VIEW);
      case Constants.customer:
        return checkPermission(USER_VIEW);
      case Constants.kycRequest:
        return checkPermission(KYC_VIEW);
      case Constants.affiliateVideo:
        return checkPermission(AFFILIATE_LESSON_VIEW);
      case Constants.affiliateUsers:
        return checkPermission(AFFILIATE_VIEW);
      case Constants.withdraw:
        return checkPermission(WITHDRAW_VIEW);
      case Constants.support:
        return checkPermission(SUPPORT_VIEW);
      case Constants.report:
        return checkPermission(REPORT_VIEW);
      case Constants.roleMgt:
        return checkPermission(ROLE_MGT_VIEW);
      case Constants.notification:
        return checkPermission(NOTIFI_VIEW);
      case Constants.cms:
        return checkPermission(CONFG_VIEW);
      default:
        return false;
    }
  };

  return (
    <div
      id="mainsidemenu"
      className={`sidebar py-4 py-md-4 me-0 ${
        isSidebarMini ? "sidebar-mini" : ""
      }`}
      style={{ overflow: "scroll" }}
    >
      <div className="d-flex flex-column h-100">
        {!isSidebarMini && (
          <img
            onClick={() => navigate(Constants.dashboard)}
            src={logo}
            className="ms-2 mb-0 brand-icon cursor-pointer"
            alt="homie"
            style={{ width: "160px", height: "40px" }}
          />
        )}
        <ul className="menu-list flex-grow-1 mt-3">
          {menuData.map((d, i) => {
            if (d.isToggled) {
              return (
                <li key={"shsdg" + i}>
                  <a
                    className={"m-link"}
                    href="#!"
                    onClick={(e) => {
                      const sidebar = document.getElementById("mainsidemenu");
                      sidebar?.classList?.remove("open");
                      e.preventDefault();
                      GotoChangeMenu(d.name);
                    }}
                  >
                    <i className={d.iconClass}></i>
                    <span>{d.name}</span>
                  </a>
                </li>
              );
            }
            // Check permission for tabs
            if (roleType !== Constants.defaultAdmin && shouldHideMenuItem(d)) {
              return null;
            }
            // If tab has 0 sub tabs
            if (d.children.length === 0) {
              return (
                <li key={"dsfshsdg" + i} className=" collapsed">
                  <Link
                    to={
                      d.name !== Constants.signout
                        ? process.env.PUBLIC_URL + "/" + d.routerLink[0]
                        : location
                    }
                    onClick={
                      d.name === Constants.signout ? () => signOut() : ""
                    }
                    className={`m-link ${
                      activekey.includes(d.routerLink?.[0]) ? "active" : ""
                    }`}
                  >
                    <i className={d.iconClass}></i>
                    <span>{d.name}</span>
                    <span className="ms-auto text-end fs-5"></span>
                  </Link>
                </li>
              );
            }
            // If tab has sub tabs
            return (
              <li key={"shsdg" + i} className=" collapsed">
                <a
                  className={`m-link ${
                    d.children.filter(
                      (d) => "/" + d.routerLink[0] === activekey
                    ).length > 0
                      ? "active"
                      : ""
                  }`}
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    openChildren("menu-Pages" + i);
                  }}
                >
                  <i className={d.iconClass}></i>
                  <span>{d.name}</span>
                  <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                </a>
                {d.children.length > 0 ? (
                  <ul
                    className="sub-menu collapse has-children"
                    id={"menu-Pages" + i}
                  >
                    {d.children.map((data, ind) => {
                      if (d.children.length > 0) {
                        if (activekey === "/" + data.routerLink?.[0]) {
                          setTimeout(() => {
                            const sidebar =
                              document.getElementById("mainsidemenu");
                            sidebar?.classList?.remove("open");
                            openChildren1("menu-Pages" + i);
                          }, 500);
                        }
                      }
                      return (
                        <li key={"jfdgj" + ind}>
                          <Link
                            className={
                              activekey === "/" + data.routerLink?.[0]
                                ? "ms-link active"
                                : "ms-link"
                            }
                            to={
                              process.env.PUBLIC_URL +
                              "/" +
                              data.routerLink?.[0]
                            }
                          >
                            <span>{data.name}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </li>
            );
          })}
        </ul>
        <button
          type="button"
          className="btn btn-link sidebar-mini-btn text-light"
          onClick={() => {
            setIsSidebarMini(!isSidebarMini);
          }}
        >
          <span className="ms-2">
            <i className="icofont-bubble-right"></i>
          </span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;

import React, { useEffect, useState } from "react";
import ButtonCustom from "../../../Common/Fields/ButtonCustom";
import { Constants } from "../../../Common/Constants";
import { Form, Formik } from "formik";
import ReactSelect from "../../../Common/Fields/ReactSelect";
import { useSelector, useDispatch } from "react-redux";
import { coursesDetail, coursesUpdate } from "../../../../Redux/Actions";
import { useParams } from "react-router-dom";
import { types } from "../../../../Redux/Actions/types";
import usePermissions from "../../../Common/Functions/usePermissions";

const SetRuleForm = () => {
  const [selectedSetRule, setSelectedSetRule] = useState(
    Constants.lessonSetRule[1]
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedDay, setSelectedDay] = useState(Constants.weekDays[0]);
  const [initialValues, setInitialValues] = useState({
    set_rule: "",
    day_id: 0,
  });
  const { coursesSingle } = useSelector((state) => state.Courses);
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);

  // pre-fill the set rule form
  useEffect(() => {
    if (coursesSingle) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (key === "set_rule") {
          const setRule = Constants.lessonSetRule.find(
            (elt) => elt.value === +coursesSingle[key]
          );
          if (setRule !== -1) setSelectedSetRule(setRule);
        }
        if (key === "day_id") {
          Constants.weekDays.forEach((elt) => {
            if (coursesSingle.day_id === elt.value) {
              setSelectedDay(elt);
            }
          });
        }
        setInitialValues(editValue);
      }
    }
  }, [coursesSingle]);

  // Function to handle set rule change
  const handleOptionChange = (e, setFieldValue, field) => {
    setSelectedSetRule(e);
    setFieldValue(field, e.value);
  };

  // handle day change
  const onChangeSelect = (e, setFieldValue) => {
    setSelectedDay(e);
    setFieldValue("day_id", e.value);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        // course update API for set rule
        const submitFunc = coursesUpdate(id, values);
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(coursesDetail(id));
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.LESSONS_SINGLE, payload: "" });
            dispatch({ type: types.LESSONS_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ setFieldValue }) => (
        <Form className="row">
          <div
            className={`${
              selectedSetRule.value === 0 ? "col-md-6" : "col-md-12"
            }`}
          >
            <ReactSelect
              label={Constants.setRule}
              required={true}
              options={Constants.lessonSetRule}
              onChange={(selected) =>
                handleOptionChange(selected, setFieldValue, "set_rule")
              }
              value={selectedSetRule}
            />
          </div>
          {selectedSetRule.value === 0 && (
            <div className="col-md-6 mb-2">
              <ReactSelect
                label={`${Constants.select} ${Constants.day}`}
                required={true}
                options={Constants.weekDays}
                onChange={(e) => onChangeSelect(e, setFieldValue)}
                value={selectedDay}
              />
            </div>
          )}
          {editPermission && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SetRuleForm;

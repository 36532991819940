import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Constants } from "../Common/Constants";
import usePermissions from "../Common/Functions/usePermissions";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextField from "../Common/Fields/TextField";
import TextArea from "../Common/Fields/TextArea";
import RequiredIcon from "../Common/Fields/RequiredIcon";
import FileAwsUpload from "../Common/Fields/FileAwsUpload";
import ViewImageFile from "../Common/Fields/ViewImageFile";
import VideoTusUpload from "../Common/Fields/VideoTusUpload";
import VideoDuration from "../Common/Fields/VideoDuration";
import ViewVideoFile from "../Common/Fields/ViewVideoFile";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import {
  affiliateUsersList,
  affiliateVideoInsert,
  affiliateVideoUpdate,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import RadioButton from "../Common/Fields/RadioButton";

const AffiliateVideoForm = () => {
  const dispatch = useDispatch();
  const { affiliatevideoSingle } = useSelector((state) => state.AffiliateVideo);
  const [tagType, setTagType] = useState(Constants.affiliateTagsOptions?.[0]);
  // Initial values for affiliate video form
  const [initialValues, setInitialValues] = useState({
    tags: Constants?.affiliateTagsOptions[0]?.value,
    name: "",
    description: "",
    thumbnail_image: "",
    video_thumbnail: "",
    video: "",
    duration: "",
  });
  const [showProgress, setShowProgress] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const addPermission = usePermissions(
    Constants.permission.AFFILIATE_VIDEO_ADD
  );
  const editPermission = usePermissions(
    Constants.permission.AFFILIATE_VIDEO_EDIT
  );

  // Validation for affiliate video form
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
    description: Yup.string().required(Constants.required),
    thumbnail_image: Yup.string().required(Constants.required),
    video_thumbnail: Yup.string().required(Constants.required),
    video: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    // Set initial values for pre-filled form
    if (affiliatevideoSingle?._id) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        editValue[key] = affiliatevideoSingle[key];
        if (key === "tags") {
          const val = Constants.affiliateTagsOptions.find(
            (elt) => elt.key === affiliatevideoSingle[key]
          );
          setTagType(val);
        }
        setInitialValues(editValue);
      }
    }
  }, [affiliatevideoSingle]);

  // handle image for affiliate video
  const handleFileChange = (file, setFieldValue, field) => {
    setFieldValue(field, file?.[0]);
  };

  // add video AWS URL
  const handleVideoChange = (file, setFieldValue, field) => {
    setFieldValue(field, file);
    setShowProgress(true);
    setVideoUrl(file);
  };

  // tag selection handling
  const handleTagType = (option, setValue, type) => {
    setTagType(option);
    setValue(type, option.key);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update affiliate Video API
        let submitFunc = affiliateVideoInsert(values);
        if (affiliatevideoSingle?._id) {
          const id = affiliatevideoSingle?._id;
          submitFunc = affiliateVideoUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(affiliateUsersList());
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.AFFILIATE_VIDEO_SINGLE, payload: "" });
            dispatch({ type: types.AFFILIATE_VIDEO_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mt-2">
            <RadioButton
              label={Constants.videoType}
              options={Constants.affiliateTagsOptions}
              name="tags"
              checked={tagType}
              onChange={(option) =>
                handleTagType(option, setFieldValue, "tags")
              }
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextArea
              type="text"
              name="description"
              className="form-control"
              errors={touched?.description ? errors?.description : undefined}
              touched={touched?.description}
              label={Constants.description}
            />
          </div>
          {[
            {
              name: "thumbnail_image",
              type: Constants.imageApp,
              width: 343,
              height: 165,
            },
          ].map((item, index) => (
            <div className="col-md-6 mb-2" key={index}>
              <label className="form-label">{`${Constants.thumbnail} ${item.type}`}</label>
              <RequiredIcon />
              <div className="out-cr">
                <div className="width-outer-up">
                  {values && !values[item.name] && (
                    <FileAwsUpload
                      fileType={[".jpeg", ".png", ".jpg"]}
                      errors={
                        touched[item.name] ? errors[item.name] : undefined
                      }
                      touched={touched[item.name]}
                      width={item.width}
                      height={item.height}
                      label={`${Constants.upload} ${Constants.thumbnail}`}
                      handleFile={(thumbnailImage) =>
                        handleFileChange(
                          thumbnailImage,
                          setFieldValue,
                          item.name
                        )
                      }
                      maxFiles={1}
                    />
                  )}
                  {values && values[item.name] && (
                    <ViewImageFile
                      file={values[item.name]}
                      onClick={() => setFieldValue(item.name, "")}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}

          <div className="col-md-6 mb-2">
            <label className="form-label">{`${Constants.video}`}</label>
            <RequiredIcon />
            <div>
              {values && !values?.video && (
                <VideoTusUpload
                  errors={touched?.video ? errors?.video : undefined}
                  touched={touched?.video}
                  label={`${Constants.upload} ${Constants.video}`}
                  handleFile={(video) =>
                    handleVideoChange(video, setFieldValue, "video")
                  }
                />
              )}
              {showProgress && videoUrl && (
                <VideoDuration
                  url={videoUrl}
                  fetchDuration={(duration) => {
                    setFieldValue("duration", duration);
                    setShowProgress(false);
                  }}
                />
              )}
              {values && !showProgress && values?.video && (
                <ViewVideoFile
                  file={values?.video}
                  onClick={() => {
                    setFieldValue("video", "");
                    setFieldValue("duration", "");
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">{`${Constants.video} ${Constants.thumbnail}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up">
                {values && !values?.video_thumbnail && (
                  <FileAwsUpload
                    fileType={[".jpeg", ".png", ".jpg"]}
                    width="1280"
                    height="720"
                    errors={
                      touched?.video_thumbnail
                        ? errors?.video_thumbnail
                        : undefined
                    }
                    touched={touched?.video_thumbnail}
                    label={`${Constants.upload} ${Constants.video} ${Constants.thumbnail}`}
                    handleFile={(video_thumbnail) =>
                      handleFileChange(
                        video_thumbnail,
                        setFieldValue,
                        "video_thumbnail"
                      )
                    }
                    maxFiles={1}
                  />
                )}
                {values && values?.video_thumbnail && (
                  <ViewImageFile
                    file={values?.video_thumbnail}
                    onClick={() => setFieldValue("video_thumbnail", "")}
                  />
                )}
              </div>
            </div>
          </div>
          {((addPermission && !affiliatevideoSingle?._id) ||
            (editPermission && affiliatevideoSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AffiliateVideoForm;

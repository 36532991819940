import {
  courseCategory,
  courses,
  coursesAdd,
  coursesEdit,
  coursesRecommendedUpdate,
  coursesRemove,
  coursesStatusUpdate,
  detailCourse,
} from "../../../services/Courses/courses.service";
import { types } from "../types";

export const coursesInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await coursesAdd(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const coursesList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await courses(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.COURSES_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.COURSES_LIST, payload: { error: response.error } });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const coursesCategoryList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await courseCategory(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.COURSES_CAT_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.COURSES_CAT_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const coursesDetail = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await detailCourse(id);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.COURSES_SINGLE, payload: result.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.COURSES_SINGLE,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const coursesOptionDetail = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await detailCourse(id);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.COURSES_OPTIONS_SINGLE, payload: result.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.COURSES_OPTIONS_SINGLE,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const coursesUpdate = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await coursesEdit(id, data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const coursesDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await coursesRemove(id);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const coursesUpdateStatus = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await coursesStatusUpdate(id, data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const coursesUpdateRecommended = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await coursesRecommendedUpdate(id, data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

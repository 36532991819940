import React, { useState } from "react";
import { Constants } from "../Common/Constants";
import TableList from "../Common/UiModel/TableList";
import PageHeader from "../Common/PageHeader";
import dayjs from "dayjs";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { resendInvoiceToEmail } from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";

import ModalPopup from "../Common/UiModel/ModalPopup";
import CompletionPercentForm from "./CompletionPercentForm";
import { Link } from "react-router-dom";
import { RoutesUrl } from "../Common/RoutesUrl";
import usePermissions from "../Common/Functions/usePermissions";

const UserCourseList = ({ courses }) => {
  const dispatch = useDispatch();
  const { usersSingle } = useSelector((state) => state.Users);
  const [user, setUser] = useState("");
  const transactionViewPermission = usePermissions(
    Constants.permission.TRANSACTION_VIEW
  );
  const editPermission = usePermissions(Constants.permission.USER_EDIT);

  // invoice lint redirection
  const handleInvoiceLink = (link) => {
    window.open(link, "_blank");
  };

  // to resend the invoice to the user
  const handleResendInvoice = (user) => {
    const data = {
      email: usersSingle?.user?.email,
      invoice_link: user?.invoice_download_link,
    };
    dispatch(resendInvoiceToEmail(data)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        dispatch({
          type: types.SUCCESS_ALERT_VISIBLE,
          payload: response?.data?.message,
        });
      }
    });
  };

  // Columns for enrolled course list
  const columns = [
    {
      name: Constants.name,
      selector: (row) => (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.name}
        >
          {row?.name}
        </span>
      ),
    },
    {
      name: Constants.package,
      selector: (row) => (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.package || Constants.na}
        >
          {row?.package || Constants.na}
        </span>
      ),
    },
    {
      name: Constants.date,
      selector: (row) =>
        dayjs(row?.enrollmentDate).format(Constants.dateFormat),
    },
    {
      name: Constants.transactionId,
      selector: (row) => (
        <Link
          to={
            transactionViewPermission
              ? `${RoutesUrl?.transactions}/${row?.transaction_id}`
              : ""
          }
        >
          {row?.razorpay_payment_id || Constants.na}
        </Link>
      ),
    },
    {
      name: Constants.statusKey,
      selector: (row) => {
        const status = Constants.transactionFilterStatus.find(
          (elt) => elt.value === row?.payment_status
        );
        const val = status?.value;
        return (
          <div
            className={`payment-status-badges ${
              val === "captured" || val === "authorized"
                ? "paid"
                : val === "failed"
                ? "failed"
                : val === "refunded"
                ? "refunded"
                : val === "processed"
                ? "refund-int"
                : ""
            }`}
          >
            {status?.label}
          </div>
        );
      },
    },
    {
      name: Constants.validtill,
      selector: (row) => (
        <div
          className={`payment-status-badges ${row?.expiration ? "failed" : ""}`}
        >
          {row?.expiration
            ? dayjs(row?.enrollment_expired_date).format(Constants.dateFormat)
            : Constants.lifetime}
        </div>
      ),
    },
    {
      name: Constants.completionPer,
      selector: (row) => row?.course_completion || 0,
    },
    {
      name: Constants.promoCode,
      selector: (row) => row?.promo_code || Constants.na,
    },
    {
      name: Constants.usedLoyalty,
      selector: (row) => row?.used_loyalty_points || 0,
    },
    {
      name: Constants.discountInr,
      selector: (row) => row?.discount_amount || 0,
    },
    {
      name: Constants.paidAmount,
      selector: (row) => row?.payable_amount || 0,
    },
    {
      name: Constants.price,
      selector: (row) => row?.original_price || 0,
    },
    // Dropdown to handle invoice link, resend invoice to the user & change the course
    // completion percent(0: to enable sequential learning or 100: to disable sequential learning)
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div
                    className="list-group cursor-pointer"
                    onClick={() =>
                      handleInvoiceLink(row?.invoice_download_link)
                    }
                  >
                    <span className="ps-2 name-f">{Constants.invoiceLink}</span>
                  </div>
                  <div
                    className="list-group cursor-pointer"
                    onClick={() => handleResendInvoice(row)}
                  >
                    <span className="ps-2 name-f">
                      {Constants.resendInvoiceOnMail}
                    </span>
                  </div>
                  {editPermission && (
                    <div
                      className="list-group cursor-pointer"
                      onClick={() => setUser(row)}
                    >
                      <span className="ps-2 name-f">
                        {Constants.changeCompletionPer}
                      </span>
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="mt-3">
      <div className="mt-3">
        <PageHeader pagetitle={Constants.enrolledCourses} />
      </div>
      <TableList
        columns={columns}
        data={courses}
        defaultSortFieldId
        highlightOnHover
      />
      <ModalPopup
        show={user}
        onHide={() => {
          setUser("");
        }}
        dialogClassName={"modal-lg"}
        title={Constants.courseCompletionPer}
        component={
          <CompletionPercentForm data={user} onHide={() => setUser("")} />
        }
      />
    </div>
  );
};

export default UserCourseList;

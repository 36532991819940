import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

// Area Graph
const AreaGraph = ({ data, title, format }) => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: title,
        data: [],
      },
    ],
    options: {
      chart: {
        height: 250,
        type: "area",
      },
      xaxis: {
        categories: [],
        position: "top",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return format + val;
          },
        },
      },
      title: {
        text: title,
        align: "center",
        style: {
          color: "#444",
        },
      },
    },
  });

  // set the values for area graph based on props
  useEffect(() => {
    if (data?.data && (data?.dates || data?.courseNames)) {
      setChartData((prevGraphData) => ({
        ...prevGraphData,
        series: [
          {
            ...prevGraphData.series[0],
            data: data?.data,
          },
        ],
        options: {
          ...prevGraphData.options,
          xaxis: {
            ...prevGraphData.options.xaxis,
            categories: data?.dates
              ? data?.dates?.map((month) => month.slice(0, 3))
              : data?.courseNames,
          },
        },
      }));
    }
  }, [data]);

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="area"
      height={310}
    />
  );
};

export default AreaGraph;

import { ApiUrl } from "../../components/Common/ApiUrl";
import httpServiceInstance from "../../helper/HttpService";

export const invoiceAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.INVOICE_BILL, data);
  return response;
};

export const invoiceEdit = async (data) => {
  const response = await httpServiceInstance.put(ApiUrl.INVOICE_BILL, data);
  return response;
};

export const invoiceDetail = async () => {
  const response = await httpServiceInstance.get(ApiUrl.INVOICE_BILL);
  return response;
};

import React, { useState } from "react";
import { RiEyeLine, RiEyeCloseLine } from "react-icons/ri";

const PasswordFieldWithToggle = ({ field }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="position-relative">
      {/* Input field for password */}
      <input
        className="form-control form-control-lg"
        // Toggle between text and password type
        type={showPassword ? "text" : "password"}
        {...field}
        style={{ paddingRight: "40px" }}
      />
      {/* Button to toggle password visibility */}
      <button
        className="btn btn-link position-absolute end-0"
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        style={{ top: "50%", transform: "translateY(-50%)" }}
      >
        {/* Display eye icon based on showPassword state */}
        {!showPassword ? <RiEyeCloseLine /> : <RiEyeLine />}
      </button>
    </div>
  );
};

export default PasswordFieldWithToggle;

import React from "react";

const ToggleListField = (props) => (
  <div
    className={`switch-tn btn-dropdown2 ${props?.className} cursor-pointer`}
    onClick={(e) => {
      props?.onChange(e);
    }}
  >
    <label className="my-2 switch-label">
      <input
        type="checkbox"
        checked={props.checked}
        onChange={() => {}}
      ></input>
      <span className="slider round"></span>
    </label>
    <span className="ps-2 name-f mb-0">{props?.label}</span>
  </div>
);

export default ToggleListField;

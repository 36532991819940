import {
  abandonedUsersList,
  logsList,
  reportList,
} from "../../services/report";
import { types } from "./types";

export const fetchLogs = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await logsList(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.REPORTS_LOGS_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.REPORTS_LOGS_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const fetchAbandonedUsers = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await abandonedUsersList(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.ABANDONED_USERS_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.ABANDONED_USERS_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const fetchReports = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await reportList(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.REPORTS_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.REPORTS_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

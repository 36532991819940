import { Routes, Route, Navigate } from "react-router-dom";
import "./assets/scss/main.scss";
import Page404 from "./components/Auth/Page404";
import Header from "./components/Common/Header";
import { RoutesUrl } from "./components/Common/RoutesUrl";
import Walkthrough from "./components/Configuration/Walkthrough";
import SignIn from "./components/Auth/SignIn";
import Goals from "./components/Configuration/Goals";
import Courses from "./components/Courses";
import CourseDetail from "./components/Courses/CourseDetail";
import ContactUs from "./components/Configuration/ContactUs";
import Loyalty from "./components/Configuration/Loyalty";
import PrivacyPolicy from "./components/Configuration/PrivacyPolicy";
import Faq from "./components/Configuration/Faq";
import ReferEarn from "./components/Configuration/ReferEarn";
import SalesAnalytic from "./components/Configuration/SalesAnalytic";
import TermCondition from "./components/Configuration/TermCondition";
import Package from "./components/Package";
import PackageDetail from "./components/Package/PackageDetail";
import Lesson from "./components/Courses/CourseDetail/Lessons";
import Banner from "./components/Banner";
import BannerDetail from "./components/Banner/BannerDetail";
import UsersList from "./components/Users";
import UserDetail from "./components/Users/UserDetail";
import { Constants } from "./components/Common/Constants";
import EnrolledUserDetail from "./components/Courses/CourseDetail/EnrolledUsers/EnrolledUserDetail";
import UserFormResponse from "./components/Banner/BannerDetail/Response/UserFormResponse";
import Event from "./components/Event";
import EventDetail from "./components/Event/EventDetail";
import FreeVideo from "./components/FreeVideo.js";
import Transactions from "./components/Transactions/index.js";
import TransactionDetail from "./components/Transactions/TransactionDetail.js";
import Refund from "./components/Configuration/Refund.js";
import News from "./components/News/index.js";
import AffiliateUsers from "./components/Affiliate/index.js";
import KycRequestList from "./components/Kyc/index.js";
import KycDetail from "./components/Kyc/KycDetail.js";
import Category from "./components/Catalog/Category/index.js";
import Notification from "./components/Notification/Notification/index.js";
import ReportEmail from "./components/Notification/Email/index.js";
import Dashboard from "./components/Dashboard/Dashboard.js";
import ManageRoles from "./components/RoleManagement/Roles/index.js";
import ManageUsers from "./components/RoleManagement/Users/index.js";
import PromoCodeList from "./components/PromoCode/index.js";
import Overview from "./components/Report/Overview/index.js";
import SupportList from "./components/Support/index.js";
import Logs from "./components/Report/Logs/index.js";
import DeletedUsers from "./components/Report/DeletedUsers.js";
import Abandoned from "./components/Report/Abandoned.js";
import WithdrawList from "./components/Withdraw/index.js";
import WithdrawDetail from "./components/Withdraw/WithdrawDetail.js";
import PromoDetail from "./components/PromoCode/PromoCodeDetail/index.js";
import PricingRule from "./components/Configuration/PricingRule.js";
import Basic from "./components/Configuration/Basic.js";
import StaticDashboard from "./components/Dashboard/StaticDashboard.js";
import Forbidden from "./components/Common/Fields/Forbidden.js";
import usePermissions from "./components/Common/Functions/usePermissions.js";
import React from "react";
import Invoice from "./components/Configuration/Invoice.js";
import AffiliateVideoList from "./components/AffiliateVideo/index.js";

const MainRoutes = (props) => {
  const { activekey } = props;
  const dashboardViewPermission = usePermissions(
    Constants.permission.DASHBOARD_VIEW
  );

  return (
    <div
      className={`main space-side header-bg ${
        activekey === RoutesUrl.signIn ? "sign-in-bg" : ""
      }`}
    >
      {activekey === RoutesUrl.pageNotFound ||
      activekey === RoutesUrl.signIn ? (
        ""
      ) : (
        <Header />
      )}
      <div className="body d-flex">
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path={RoutesUrl.abandonedCourses} element={<Abandoned />} />
          <Route
            path={RoutesUrl.affiliateVideo}
            element={<AffiliateVideoList />}
          />
          <Route path={RoutesUrl.affiliateUsers} element={<AffiliateUsers />} />
          <Route path={RoutesUrl.banner} element={<Banner />} />
          <Route path={`${RoutesUrl.banner}/:id`} element={<BannerDetail />} />
          <Route
            path={`${RoutesUrl.banners}/${Constants.userFormResponseKey}/:id/:userId`}
            element={<UserFormResponse />}
          />
          <Route path={RoutesUrl.catalog} element={<Category />} />
          <Route path={RoutesUrl.configBasic} element={<Basic />} />
          <Route path={RoutesUrl.configContactUs} element={<ContactUs />} />
          <Route path={RoutesUrl.configFaq} element={<Faq />} />
          <Route path={RoutesUrl.configGoals} element={<Goals />} />
          <Route path={RoutesUrl.configLoyalty} element={<Loyalty />} />
          <Route path={RoutesUrl.configInvoice} element={<Invoice />} />
          <Route path={RoutesUrl.configPricing} element={<PricingRule />} />
          <Route
            path={RoutesUrl.configPrivacyPolicy}
            element={<PrivacyPolicy />}
          />
          <Route path={RoutesUrl.configRefer} element={<ReferEarn />} />
          <Route path={RoutesUrl.configRefund} element={<Refund />} />
          <Route path={RoutesUrl.configSales} element={<SalesAnalytic />} />
          <Route
            path={RoutesUrl.configTermsConditions}
            element={<TermCondition />}
          />
          <Route path={RoutesUrl.configWalkthrough} element={<Walkthrough />} />
          <Route path={RoutesUrl.courses} element={<Courses />} />
          <Route path={`${RoutesUrl.courses}/:id`} element={<CourseDetail />} />
          <Route
            path={`${RoutesUrl.courses}/:id/${Constants.moduleKey}/:moduleId`}
            element={<Lesson />}
          />
          <Route
            path={`${RoutesUrl.courses}/:id/${Constants.enrolledUsersKey}/:userId`}
            element={<EnrolledUserDetail />}
          />
          <Route
            path={RoutesUrl.dashboard}
            element={
              dashboardViewPermission ? <Dashboard /> : <StaticDashboard />
            }
          />
          <Route path={RoutesUrl.event} element={<Event />} />
          <Route path={`${RoutesUrl.event}/:id`} element={<EventDetail />} />
          <Route path={RoutesUrl.forbidden} element={<Forbidden />} />
          <Route path={RoutesUrl.freeVideo} element={<FreeVideo />} />
          <Route path={RoutesUrl.kyc} element={<KycRequestList />} />
          <Route path={`${RoutesUrl.kyc}/:id`} element={<KycDetail />} />
          <Route path={`${RoutesUrl.logs}`} element={<Logs />} />
          <Route path={`${RoutesUrl.manageRoles}`} element={<ManageRoles />} />
          <Route path={`${RoutesUrl.manageUsers}`} element={<ManageUsers />} />
          <Route path={RoutesUrl.news} element={<News />} />
          <Route path={RoutesUrl.overview} element={<Overview />} />
          <Route path={RoutesUrl.package} element={<Package />} />
          <Route
            path={`${RoutesUrl.package}/:id`}
            element={<PackageDetail />}
          />
          <Route path={RoutesUrl.pageNotFound} element={<Page404 />} />
          <Route path={RoutesUrl.promocode} element={<PromoCodeList />} />
          <Route
            path={`${RoutesUrl.promocode}/:id`}
            element={<PromoDetail />}
          />
          <Route path={RoutesUrl.notificationEmail} element={<ReportEmail />} />
          <Route
            path={RoutesUrl.notificationNotification}
            element={<Notification />}
          />
          <Route
            path={RoutesUrl.reportDeletedUsers}
            element={<DeletedUsers />}
          />
          <Route path={RoutesUrl.signIn} element={<SignIn />} />
          <Route
            path={RoutesUrl.staticDashboard}
            element={<StaticDashboard />}
          />
          <Route path={RoutesUrl.support} element={<SupportList />} />
          <Route path={RoutesUrl.transactions} element={<Transactions />} />
          <Route
            path={`${RoutesUrl.transactions}/:id`}
            element={<TransactionDetail />}
          />
          <Route path={RoutesUrl.users} element={<UsersList />} />
          <Route path={`${RoutesUrl.users}/:id`} element={<UserDetail />} />
          <Route path={`${RoutesUrl.withdraw}`} element={<WithdrawList />} />
          <Route
            path={`${RoutesUrl.withdraw}/:id`}
            element={<WithdrawDetail />}
          />
          <Route
            path="*"
            element={<Navigate replace to={RoutesUrl.pageNotFound} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default MainRoutes;

import { types } from "../../Actions/types";

const initialState = {
  setslist: [],
  queslist: [],
  popupmodel: "",
  quesSingle: "",
  error: null,
};

export default function Quiz (state = initialState, action) {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.SETS_LIST:
      return {
        ...state,
        setslist: payload,
        error,
      };
    case types.QUES_LIST:
      return {
        ...state,
        queslist: payload,
        error,
      };
    case types.QUES_SINGLE:
      return {
        ...state,
        quesSingle: payload,
        error,
      };
    case types.QUES_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
}

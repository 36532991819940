import React, { useState, useEffect } from "react";
import ButtonCustom from "../../../Common/Fields/ButtonCustom";
import { Constants } from "../../../Common/Constants";
import {
  bannersDetail,
  bannersFormInsert,
  bannersFormUpdate,
} from "../../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../../../Redux/Actions/types";
import { useParams } from "react-router-dom";
import ReactSelect from "../../../Common/Fields/ReactSelect";
import usePermissions from "../../../Common/Functions/usePermissions";

const FormBanner = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { bannersSingle } = useSelector((state) => state.Banners);
  const [formFields, setFormFields] = useState([]);
  const [error] = useState(Constants.maxFormFieldsLimit);
  const [fieldType, setFieldType] = useState(Constants.formBannerOptions[0]);
  const addPermission = usePermissions(Constants.permission.BANNER_ADD);
  const editPermission = usePermissions(Constants.permission.BANNER_EDIT);

  useEffect(() => {
    // Set form fields initially
    if (bannersSingle?.banner_forms?.request) {
      setFormFields(bannersSingle?.banner_forms?.request);
    }
  }, [bannersSingle]);

  const addFormField = () => {
    // Add form field, at max 20 fields
    if (formFields.length < 20) {
      const newField = {
        type: fieldType.value,
        label: "",
        options: [],
        isRequired: false,
      };
      setFormFields([...formFields, newField]);
    }
  };

  const handleLabelChange = (index, value) => {
    // Label change for a form field
    const updatedFormFields = [...formFields];
    updatedFormFields[index].label = value;
    setFormFields(updatedFormFields);
  };

  const handleRequiredChange = (index) => {
    // Whether a form field is required or not
    const updatedFormFields = [...formFields];
    updatedFormFields[index].isRequired = !updatedFormFields[index].isRequired;
    setFormFields(updatedFormFields);
  };

  const addOption = (index) => {
    // Add option for checkbox/radio
    const updatedFormFields = [...formFields];
    updatedFormFields[index].options.push("");
    setFormFields(updatedFormFields);
  };

  const handleOptionChange = (fieldIndex, optionIndex, value) => {
    // On field option addition
    const updatedFormFields = [...formFields];
    updatedFormFields[fieldIndex].options[optionIndex] = value;
    setFormFields(updatedFormFields);
  };

  const removeOption = (fieldIndex, optionIndex) => {
    // delete the field option
    const updatedFormFields = [...formFields];
    updatedFormFields[fieldIndex].options.splice(optionIndex, 1);
    setFormFields(updatedFormFields);
  };

  const removeFormField = (index) => {
    // Delete the form field
    const updatedFormFields = [...formFields];
    updatedFormFields.splice(index, 1);
    setFormFields(updatedFormFields);
  };

  const handleSubmit = () => {
    // Add or update banner form API
    const data = {
      banner_id: id,
      request: formFields,
    };
    let submitFunc = bannersFormInsert(data);
    if (bannersSingle?.banner_forms?._id) {
      const id = bannersSingle?.banner_forms?._id;
      submitFunc = bannersFormUpdate(id, data);
    }
    dispatch(submitFunc).then((response) => {
      if (response?.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response?.error,
        });
      } else {
        dispatch(bannersDetail(id));
        dispatch({
          type: types.SUCCESS_ALERT_VISIBLE,
          payload: response?.data?.message,
        });
      }
    });
  };

  const renderOptions = (field, fieldIndex) => {
    // Display the field options
    return field.options.map((option, optionIndex) => (
      <div className="row align-items-center" key={optionIndex}>
        <div className="col-lg-4">
          <input
            type="text"
            className="form-control"
            placeholder={Constants.enterOptionText}
            value={option}
            onChange={(e) =>
              handleOptionChange(fieldIndex, optionIndex, e.target.value)
            }
          />
        </div>
        <div className="col-lg-2">
          <button
            onClick={() => removeOption(fieldIndex, optionIndex)}
            className="my-3 btn-field trash-btn btn-option"
          >
            <i className="fa fa-trash delet-tr me-2" aria-hidden="true"></i>
            <span className="delet-t">{Constants.delete}</span>
          </button>
        </div>
      </div>
    ));
  };

  const renderForm = () => {
    // Display fields
    return formFields.map((field, index) => (
      <div key={index} className="my-4">
        <div className=" card-form form-field custom-form">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <label className="form-label">{`${Constants.field} ${
                Constants.formBannerOptions.find(
                  (elt) => elt.value === formFields?.[index]?.type
                ).label
              }`}</label>
              <input
                type="text"
                className="form-control"
                placeholder={Constants.enterLabelText}
                value={field.label}
                onChange={(e) => handleLabelChange(index, e.target.value)}
              />
            </div>
            <div className="col-lg-2 check-b">
              <label className="mr-2">
                <span className="bord-bb">
                  <input
                    type="checkbox"
                    checked={field.isRequired}
                    onChange={() => handleRequiredChange(index)}
                  />
                </span>
                <span className="ps-2">{Constants.requiredKey}:</span>
              </label>
            </div>
            <div className="col-lg-2">
              <span>
                <button
                  onClick={() => removeFormField(index)}
                  className=" btn-field trash-btn mt-25"
                >
                  <i className="fa fa-trash delet-tr" aria-hidden="true"></i>
                  <span className="delet-t">{Constants.delete}</span>
                </button>
              </span>
            </div>
          </div>
          {(field.type === "checkbox" || field.type === "radio") && (
            <div className="sep">
              <div className="card-mode sep-flex">
                <label className="me-2">{Constants.options}:</label>
                <button className="btn-field" onClick={() => addOption(index)}>
                  <span>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </span>
                  {Constants.addOption}
                </button>
              </div>
              {renderOptions(field, index)}
            </div>
          )}
        </div>
      </div>
    ));
  };

  return (
    <div className="container-xxl">
      <div className="row">
        <div className="col-lg-12 mx-auto">
          <div className=" card-form form-field card-mode">
            <div className="row align-items-center">
              <div className="col-lg-8 inline-flex">
                <div className="col-md-6 input-control">
                  <ReactSelect
                    label={`${Constants.selectFormFieldType}:`}
                    required={false}
                    options={Constants.formBannerOptions}
                    onChange={(e) => setFieldType(e)}
                    value={fieldType}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <button className=" btn-field f-right" onClick={addFormField}>
                  <span>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </span>
                  {Constants.addField}
                </button>
              </div>
              {formFields?.length > 19 && <p className="error-msg">{error}</p>}
            </div>
          </div>
          {renderForm()}
          {((addPermission &&
            !bannersSingle?.banner_forms?.request?.length > 0) ||
            (editPermission &&
              bannersSingle?.banner_forms?.request?.length > 0)) && (
            <ButtonCustom
              type="button"
              label={Constants.submit}
              onClick={() => handleSubmit()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FormBanner;

export const types = {
  ABANDONED_USERS_LIST: "ABANDONED_USERS_LIST",
  ADMIN_CONFIGURATION_ADD: "ADMIN_CONFIGURATION_ADD",
  ADMIN_CONFIGURATION_LIST: "ADMIN_CONFIGURATION_LIST",
  ADMIN_INVOICE_LIST: "ADMIN_INVOICE_LIST",
  AFFILIATE_USER_LIST: "AFFILIATE_USER_LIST",
  AFFILIATE_VIDEO_FORM_MODEL: "AFFILIATE_VIDEO_FORM_MODEL",
  AFFILIATE_VIDEO_LIST: "AFFILIATE_VIDEO_LIST",
  AFFILIATE_VIDEO_SINGLE: "AFFILIATE_VIDEO_SINGLE",
  AWS_ACL: "public-read",
  AWS_REGION: "ap-south-1",
  AWS_S3_BUCKET: "rahulmalodia-assets",
  AWS_S3URL: "https://rahulmalodia-assets.s3.ap-south-1.amazonaws.com/",
  AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  BANNERS_FORM_MODEL: "BANNERS_FORM_MODEL",
  BANNERS_LIST: "BANNERS_LIST",
  BANNERS_SINGLE: "BANNERS_SINGLE",
  BANNERS_USER_FORM: "BANNERS_USER_FORM",
  BANNERS_USERS_LIST: "BANNERS_USERS_LIST",
  CONFIG_FAQ_MODEL: "CONFIG_FAQ_MODEL",
  CONFIG_FAQ_LIST: "CONFIG_FAQ_LIST",
  CONFIG_FAQ_SINGLE: "CONFIG_FAQ_SINGLE",
  CATEGORY_FORM_MODEL: "CATEGORY_FORM_MODEL",
  CATEGORY_LIST: "CATEGORY_LIST",
  CATEGORY_SINGLE: "CATEGORY_SINGLE",
  COURSES_FORM_MODEL: "COURSES_FORM_MODEL",
  COURSES_CAT_LIST: "COURSES_CAT_LIST",
  COURSES_LIST: "COURSES_LIST",
  COURSES_SINGLE: "COURSES_SINGLE",
  COURSES_OPTIONS_SINGLE: "COURSES_OPTIONS_SINGLE",
  COURSES_FAQS_FORM_MODEL: "COURSES_FAQS_FORM_MODEL",
  COURSES_FAQS_LIST: "COURSES_FAQS_LIST",
  COURSES_FAQS_SINGLE: "COURSES_FAQS_SINGLE",
  DELETE_ALERT_VISIBLE: "DELETE_ALERT_VISIBLE",
  ENROLLED_USERS_LIST: "ENROLLED_USERS_LIST",
  ENROLLED_USERS_SINGLE: "ENROLLED_USERS_SINGLE",
  ERROR_ALERT_VISIBLE: "ERROR_ALERT_VISIBLE",
  EVENTS_FORM_MODEL: "EVENTS_FORM_MODEL",
  EVENTS_LIST: "EVENTS_LIST",
  EVENTS_SINGLE: "EVENTS_SINGLE",
  EVENTS_USERS_LIST: "EVENTS_USERS_LIST",
  FREE_VIDEOS_FORM_MODEL: "FREE_VIDEOS_FORM_MODEL",
  FREE_VIDEOS_LIST: "FREE_VIDEOS_LIST",
  FREE_VIDEOS_SINGLE: "FREE_VIDEOS_SINGLE",
  GOALS_FORM_MODEL: "GOALS_FORM_MODEL",
  GOALS_LIST: "GOALS_LIST",
  GOALS_SINGLE: "GOALS_SINGLE",
  LESSONS_FORM_MODEL: "LESSONS_FORM_MODEL",
  LESSONS_LIST: "LESSONS_LIST",
  LESSONS_OPTIONS_LIST: "LESSONS_OPTIONS_LIST",
  LESSONS_SINGLE: "LESSONS_SINGLE",
  LOADER_HIDE: "LOADER_HIDE",
  LOADER_VISIBLE: "LOADER_VISIBLE",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT: "LOGOUT",
  MODULES_FORM_MODEL: "MODULES_FORM_MODEL",
  MODULES_LIST: "MODULES_LIST",
  MODULES_OPTIONS_LIST: "MODULES_OPTIONS_LIST",
  MODULES_SINGLE: "MODULES_SINGLE",
  NEWS_FORM_MODEL: "NEWS_FORM_MODEL",
  NEWS_LIST: "NEWS_LIST",
  NEWS_SINGLE: "NEWS_SINGLE",
  PACKAGES_FORM_MODEL: "PACKAGES_FORM_MODEL",
  PACKAGES_LIST: "PACKAGES_LIST",
  PACKAGES_SINGLE: "PACKAGES_SINGLE",
  PACKAGE_COURSES_FORM_MODEL: "PACKAGE_COURSES_FORM_MODEL",
  PACKAGE_COURSES_LIST: "PACKAGE_COURSES_LIST",
  PACKAGE_COURSES_SINGLE: "PACKAGE_COURSES_SINGLE",
  PERMISSION_LIST: "PERMISSION_LIST",
  PERMISSION_OBJECT: "PERMISSION_OBJECT",
  PROMOCODES_FORM_MODEL: "PROMOCODES_FORM_MODEL",
  PROMOCODES_LIST: "PROMOCODES_LIST",
  PROMOCODES_SINGLE: "PROMOCODES_SINGLE",
  PROMOCODES_USERS_LIST: "PROMOCODES_USERS_LIST",
  QUES_FORM_MODEL: "QUES_FORM_MODEL",
  QUES_LIST: "QUES_LIST",
  QUES_SINGLE: "QUES_SINGLE",
  REPORTS_LIST: "REPORTS_LIST",
  REPORTS_LOGS_LIST: "REPORTS_LOGS_LIST",
  RESOURCES_FORM_MODEL: "RESOURCES_FORM_MODEL",
  RESOURCES_LIST: "RESOURCES_LIST",
  RESOURCES_SINGLE: "RESOURCES_SINGLE",
  REVIEWS_FORM_MODEL: "REVIEWS_FORM_MODEL",
  REVIEWS_LIST: "REVIEWS_LIST",
  REVIEWS_SINGLE: "REVIEWS_SINGLE",
  ROLES_LIST: "ROLES_LIST",
  ROLE_USERS_FORM_MODEL: "ROLE_USERS_FORM_MODEL",
  ROLE_USERS_LIST: "ROLE_USERS_LIST",
  ROLE_USERS_SINGLE: "ROLE_USERS_SINGLE",
  SETS_LIST: "SETS_LIST",
  SUB_CATEGORY_FORM_MODEL: "SUB_CATEGORY_FORM_MODEL",
  SUB_CATEGORY_LIST: "SUB_CATEGORY_LIST",
  SUB_CATEGORY_SINGLE: "SUB_CATEGORY_SINGLE",
  SUCCESS_ALERT_VISIBLE: "SUCCESS_ALERT_VISIBLE",
  SUPPORTS_LIST: "SUPPORTS_LIST",
  TRANSACTIONS_LIST: "TRANSACTIONS_LIST",
  TRANSACTIONS_SINGLE: "TRANSACTIONS_SINGLE",
  USERS_LIST: "USERS_LIST",
  USERS_SINGLE: "USERS_SINGLE",
  USER_COMMENTS_FORM_MODEL: "USER_COMMENTS_FORM_MODEL",
  USER_COMMENTS_LIST: "USER_COMMENTS_LIST",
  USER_COMMENTS_SINGLE: "USER_COMMENTS_SINGLE",
  WELCOME_EMAIL_LIST: "WELCOME_EMAIL_LIST",
  WITHDRAW_LIST: "WITHDRAW_LIST",
  WITHDRAW_SINGLE: "WITHDRAW_SINGLE",
  WKT_SCREEN_ADD: "WKT_SCREEN_ADD",
  WKT_SCREEN_FORM_MODEL: "WKT_SCREEN_FORM_MODEL",
  WKT_SCREEN_LIST: "WKT_SCREEN_LIST",
  WKT_SCREEN_SINGLE: "WKT_SCREEN_SINGLE",
};

import React from "react";
import PageHeader from "../../../Common/PageHeader";
import { Constants } from "../../../Common/Constants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { welcomeEmailList } from "../../../../Redux/Actions";
import WelcomeEmailForm from "./WelcomeEmailForm";
import { useParams } from "react-router-dom";

const WelcomeEmail = ({ type, addPermission, editPermission }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { welcomeEmaillist } = useSelector((state) => state.WelcomeEmail);

  // Based on type & id get welcome email data
  useEffect(() => {
    const data = {
      type,
      id,
    };
    dispatch(welcomeEmailList(data));
  }, [id]);

  return (
    <div className="mt-4 course-form card">
      <PageHeader pagetitle={Constants.welcomeEmailFormat} />
      <WelcomeEmailForm
        data={welcomeEmaillist?.data}
        type={type}
        addPermission={addPermission}
        editPermission={editPermission}
      />
    </div>
  );
};

export default WelcomeEmail;

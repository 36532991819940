import React from "react";
import { Constants } from "../Common/Constants";

const KycDetailCard = ({ user }) => {
  // aadhar number, front & back image of adhar, pan number & image, reason of rejection
  return (
    <div className="row">
      <div className="col-md-12 mx-auto d-block mt-4 card ">
        <p className="card-no">
          <span>{Constants.aadhaarNumber}:</span>
          {user?.aadhaar_card_number || Constants.na}
        </p>
        <div className="row">
          <div className="col-lg-4">
            <h3 className="front-side">{Constants.aadhaarFront}</h3>
            <div className="card-adhar">
              <a
                href={user?.aadhaar_card_front}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt={Constants.aadhaarFront}
                  src={user?.aadhaar_card_front}
                />
              </a>
            </div>
          </div>
          <div className="col-lg-4">
            <h3 className="front-side">{Constants.aadhaarBack}</h3>
            <div className="card-adhar">
              <a
                href={user?.aadhaar_card_back}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt={Constants.aadhaarBack}
                  src={user?.aadhaar_card_back}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mx-auto d-block my-4 card ">
        <p className="card-no">
          <span>{Constants.panNumber}:</span>
          {user?.pan_card_number || Constants.na}
        </p>
        {user?.kyc_status === Constants.kycRejectKey && (
          <p className="card-no">
            <span>{Constants.rejectionReason}:</span>
            {user?.reason || Constants.na}
          </p>
        )}
        <div className="row">
          <div className="col-lg-4">
            <h3 className="front-side">{Constants.panCard}</h3>
            <div className="card-adhar">
              <a
                href={user?.pan_card_image}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt={Constants.panCard} src={user?.pan_card_image} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KycDetailCard;

import { Form, Formik } from "formik";
import { Constants } from "../Common/Constants";
import TextField from "../Common/Fields/TextField";
import PageHeader from "../Common/PageHeader";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import * as Yup from "yup";
import {
  addAdminConfigurations,
  getAdminConfigurations,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import usePermissions from "../Common/Functions/usePermissions";

const section = { type: Constants.configRefundKey };

const Refund = () => {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState([]);
  const [initialValues, setInitialValues] = useState({
    refund_course_percent: "",
    refund_days: "",
  });
  // Permission
  const addPermission = usePermissions(Constants.permission.CONFG_ADD);
  const editPermission = usePermissions(Constants.permission.CONFG_EDIT);

  // Validation schema for refund form
  const validationSchema = Yup.object().shape({
    refund_course_percent: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidPercentRegex, Constants.invalidValue),
    refund_days: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidDaysRegex, Constants.invalidDays),
  });

  // Get admin confg data
  useEffect(() => {
    dispatch(getAdminConfigurations(section)).then((response) => {
      setSettings(response.data.data);
    });
  }, []);

  // pre fill the refund form values
  useEffect(() => {
    const listdata = settings;
    if (listdata && listdata.length > 0) {
      const editVal = {};
      for (const value of Object.values(listdata)) {
        editVal[value.name] = value.value;
        setInitialValues(editVal);
      }
    }
  }, [settings]);

  return (
    <div className="container-xxl">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const dataForm = {};
          dataForm.key_value = values;
          dataForm.type = section.type;
          // Add refund form data values API
          const submitFunc = addAdminConfigurations(dataForm);
          dispatch(submitFunc).then((response) => {
            if (response.error) {
              dispatch({
                type: types.ERROR_ALERT_VISIBLE,
                payload: response.error,
              });
            } else {
              dispatch({
                type: types.SUCCESS_ALERT_VISIBLE,
                payload: response.data.message,
              });
              dispatch(getAdminConfigurations(section));
            }
          });
        }}
      >
        {({ errors, touched }) => (
          <Form className="form-control mt-3 list-view card">
            <PageHeader pagetitle={Constants.configRefund} />
            <div className="row">
              <div className="col-lg-5">
                <TextField
                  type="text"
                  label={Constants.refundCoursePercent}
                  name="refund_course_percent"
                  errors={errors?.refund_course_percent}
                  touched={touched?.refund_course_percent}
                />
              </div>
              <div className="col-lg-5">
                <TextField
                  type="text"
                  label={Constants.refundDays}
                  name="refund_days"
                  errors={errors?.refund_days}
                  touched={touched?.refund_days}
                />
              </div>
              {(addPermission || editPermission) && (
                <div className="col-lg-2 btn-left">
                  <ButtonCustom
                    className="m-2 btn btn-primary text-uppercase px-4 fw-bold"
                    type="submit"
                    label={Constants.submit}
                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Refund;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../../Common/PageHeader";
import { Constants } from "../../../Common/Constants";
import { types } from "../../../../Redux/Actions/types";
import ModalPopup from "../../../Common/UiModel/ModalPopup";
import ResourceForm from "./ResourceForm";
import {
  resourcesDelete,
  resourcesList,
  resourcesUpdateStatus,
} from "../../../../Redux/Actions";
import responseMethod from "../../../Common/Functions/responseMethod";
import { deleteConfirmBox } from "../../../Common/Functions/deleteConfirmBox";
import TableList from "../../../Common/UiModel/TableList";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import EditListButton from "../../../Common/Fields/EditListButton";
import DeleteListButton from "../../../Common/Fields/DeleteListButton";
import ToggleListField from "../../../Common/Fields/ToggleListField";
import { Dropdown } from "react-bootstrap";
import ReactPagination from "../../../Common/Fields/ReactPagination";
import usePermissions from "../../../Common/Functions/usePermissions";

const Resources = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { resourceslist, popupmodel } = useSelector((state) => state.Resources);
  const [resourcesData, setResourcesData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  // Permission
  const addPermission = usePermissions(Constants.permission.COURSES_ADD);
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);
  const deletePermission = usePermissions(Constants.permission.COURSES_DELETE);

  // Get the resource list
  useEffect(() => {
    dispatch(resourcesList({ id }));
    dispatch({
      type: types.RESOURCES_SINGLE,
      payload: "",
    });
  }, []);

  // Set resource data to state
  useEffect(() => {
    setResourcesData(resourceslist.data);
  }, [resourceslist]);

  // Method to change the status of resource
  const triggerToggle = (rowData) => {
    const itemId = rowData._id;
    const data = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(resourcesUpdateStatus(itemId, data)).then((response) => {
      responseMethod(response, dispatch, resourcesList, id);
    });
  };

  // Method to delete the resource
  const onDelete = (delete_id) => {
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(resourcesDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(resourcesList({ id }));
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  // Function to handle the edit functionality
  const handleEdit = (row) => {
    dispatch({
      type: types.RESOURCES_FORM_MODEL,
      payload: {
        msg: Constants.editResource,
        show: true,
        add: 0,
      },
    });
    dispatch({
      type: types.RESOURCES_SINGLE,
      payload: row,
    });
  };

  // Columns for resource list
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link to="" onClick={() => handleEdit(row)}>
          {row?.name}
        </Link>
      ),
    },
    {
      name: Constants.statusKey,
      cell: (row) => row?.status,
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    // Dropdown to edit, delete or change the status of resource
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <EditListButton
                      onClick={() => {
                        handleEdit(row);
                      }}
                    />
                  </div>
                  {editPermission && (
                    <div className="list-group">
                      <ToggleListField
                        label={Constants.statusKey}
                        onChange={() => {
                          triggerToggle(row);
                        }}
                        checked={row.status === Constants.status.ACTIVE}
                      />
                    </div>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Pagination function
  const handleOffset = (newOff) => {
    const data = {
      id,
      skip: newOff,
      limit: Constants.limitCount,
    };
    dispatch(resourcesList(data));
  };

  return (
    <>
      <div className="mt-3">
        <PageHeader
          pagetitle={Constants.resourceList}
          buttonLink={addPermission}
          onClick={(e) => {
            e.preventDefault();
            dispatch({
              type: types.RESOURCES_FORM_MODEL,
              payload: { msg: Constants.addResource, show: true },
            });
          }}
        />
      </div>
      <TableList
        columns={columns}
        data={resourcesData}
        defaultSortFieldId
        highlightOnHover
      />
      {resourceslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={resourceslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.RESOURCES_SINGLE, payload: "" });
          dispatch({ type: types.RESOURCES_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-lg"}
        title={popupmodel.msg}
        component={<ResourceForm />}
      />
    </>
  );
};

export default Resources;

import { ApiUrl } from "../../Common/ApiUrl";
import { Constants } from "../../Common/Constants";

const { permissionApiRoute } = Constants;

// All API routes for main tabs and inside tabs
export const ApiRoutes = {
  dashboardView: [`${permissionApiRoute}${ApiUrl.DASHBOARD}`],
  categoryView: [`${permissionApiRoute}${ApiUrl.CATEGORY}`],
  categoryEdit: [
    `${permissionApiRoute}${ApiUrl.CATEGORY}`,
    `${permissionApiRoute}${ApiUrl.CATEGORY_STATUS_UPDATE}`,
  ],
  categoryAdd: [`${permissionApiRoute}${ApiUrl.CATEGORY}`],
  categoryDelete: [`${permissionApiRoute}${ApiUrl.CATEGORY}`],
  courseView: [
    `${permissionApiRoute}${ApiUrl.COURSES}`,
    `${permissionApiRoute}${ApiUrl.CATEGORY}`,
    `${permissionApiRoute}${ApiUrl.COURSES}`,
    `${permissionApiRoute}${ApiUrl.GOALS}`,
    `${permissionApiRoute}${ApiUrl.MODULES}`,
    `${permissionApiRoute}${ApiUrl.LESSONS}`,
    `${permissionApiRoute}${ApiUrl.LESSONS}`,
    `${permissionApiRoute}${ApiUrl.RESOURCES}`,
    `${permissionApiRoute}${ApiUrl.REVIEWS}`,
    `${permissionApiRoute}${ApiUrl.COURSES_FAQS}`,
    `${permissionApiRoute}${ApiUrl.WELCOME_EMAIL}`,
    `${permissionApiRoute}${ApiUrl.ENROLLED_USERS}`,
    `${permissionApiRoute}${ApiUrl.ENROLLED_USERS}`,
    `${permissionApiRoute}${ApiUrl.SETS}`,
    `${permissionApiRoute}${ApiUrl.DOWNLOAD_CERTIFICATE}`,
    `${permissionApiRoute}${ApiUrl.QUES}`,
  ],
  courseEdit: [
    `${permissionApiRoute}${ApiUrl.COURSES}`,
    `${permissionApiRoute}${ApiUrl.COURSES_STATUS_UPDATE}`,
    `${permissionApiRoute}${ApiUrl.COURSES_RECOMMENDED_UPDATE}`,
    `${permissionApiRoute}${ApiUrl.MODULES}`,
    `${permissionApiRoute}${ApiUrl.MODULES_STATUS_UPDATE}`,
    `${permissionApiRoute}${ApiUrl.LESSONS}`,
    `${permissionApiRoute}${ApiUrl.LESSONS_STATUS_UPDATE}`,
    `${permissionApiRoute}${ApiUrl.RESOURCES}`,
    `${permissionApiRoute}${ApiUrl.RESOURCES_STATUS_UPDATE}`,
    `${permissionApiRoute}${ApiUrl.REVIEWS}`,
    `${permissionApiRoute}${ApiUrl.REVIEWS_STATUS_UPDATE}`,
    `${permissionApiRoute}${ApiUrl.COURSES_FAQS}`,
    `${permissionApiRoute}${ApiUrl.COURSES_FAQS_STATUS_UPDATE}`,
    `${permissionApiRoute}${ApiUrl.WELCOME_EMAIL}`,
    `${permissionApiRoute}${ApiUrl.ENROLLED_USER_RESET_COURSE}`,
    `${permissionApiRoute}${ApiUrl.QUES}`,
    `${permissionApiRoute}${ApiUrl.QUES_STATUS_UPDATE}`,
  ],
  courseAdd: [
    `${permissionApiRoute}${ApiUrl.COURSES}`,
    `${permissionApiRoute}${ApiUrl.MODULES}`,
    `${permissionApiRoute}${ApiUrl.LESSONS}`,
    `${permissionApiRoute}${ApiUrl.RESOURCES}`,
    `${permissionApiRoute}${ApiUrl.REVIEWS}`,
    `${permissionApiRoute}${ApiUrl.COURSES_FAQS}`,
    `${permissionApiRoute}${ApiUrl.WELCOME_EMAIL}`,
    `${permissionApiRoute}${ApiUrl.QUES}`,
  ],
  courseDelete: [
    `${permissionApiRoute}${ApiUrl.COURSES}`,
    `${permissionApiRoute}${ApiUrl.MODULES}`,
    `${permissionApiRoute}${ApiUrl.LESSONS}`,
    `${permissionApiRoute}${ApiUrl.RESOURCES}`,
    `${permissionApiRoute}${ApiUrl.REVIEWS}`,
    `${permissionApiRoute}${ApiUrl.COURSES_FAQS}`,
    `${permissionApiRoute}${ApiUrl.WELCOME_EMAIL}`,
    `${permissionApiRoute}${ApiUrl.QUES}`,
  ],
  transactionView: [
    `${permissionApiRoute}${ApiUrl.TRANSACTIONS}`,
    `${permissionApiRoute}${ApiUrl.TRANSACTIONS}`,
    `${permissionApiRoute}${ApiUrl.USERS}`,
  ],
  transactionEdit: [
    `${permissionApiRoute}${ApiUrl.CALCULATE_REFUND_AMOUNT}`,
    `${permissionApiRoute}${ApiUrl.REFUND}`,
  ],
  packageView: [
    `${permissionApiRoute}${ApiUrl.PACKAGES}`,
    `${permissionApiRoute}${ApiUrl.PACKAGE_COURSES}`,
    `${permissionApiRoute}${ApiUrl.WELCOME_EMAIL}`,
    `${permissionApiRoute}${ApiUrl.ENROLLED_USERS}`,
    `${permissionApiRoute}${ApiUrl.COURSES}`,
    `${permissionApiRoute}${ApiUrl.CATEGORY}`,
    `${permissionApiRoute}${ApiUrl.USERS}`,
  ],
  packageEdit: [
    `${permissionApiRoute}${ApiUrl.PACKAGES}`,
    `${permissionApiRoute}${ApiUrl.PACKAGE_COURSES}`,
  ],
  packageAdd: [
    `${permissionApiRoute}${ApiUrl.PACKAGES}`,
    `${permissionApiRoute}${ApiUrl.PACKAGE_COURSES}`,
    `${permissionApiRoute}${ApiUrl.WELCOME_EMAIL}`,
  ],
  packageDelete: [
    `${permissionApiRoute}${ApiUrl.PACKAGE_COURSES}`,
    `${permissionApiRoute}${ApiUrl.WELCOME_EMAIL}`,
  ],
  freeVideoView: [
    `${permissionApiRoute}${ApiUrl.FREE_VIDEOS}`,
    `${permissionApiRoute}${ApiUrl.GOALS}`,
  ],
  freeVideoEdit: [
    `${permissionApiRoute}${ApiUrl.FREE_VIDEOS}`,
    `${permissionApiRoute}${ApiUrl.FREE_VIDEOS_STATUS_UPDATE}`,
  ],
  freeVideoAdd: [`${permissionApiRoute}${ApiUrl.FREE_VIDEOS}`],
  freeVideoDelete: [`${permissionApiRoute}${ApiUrl.FREE_VIDEOS}`],
  promoView: [
    `${permissionApiRoute}${ApiUrl.PROMOCODES}`,
    `${permissionApiRoute}${ApiUrl.PROMOCODES}`,
    `${permissionApiRoute}${ApiUrl.EVENTS}`,
    `${permissionApiRoute}${ApiUrl.PACKAGES}`,
    `${permissionApiRoute}${ApiUrl.COURSES}`,
    `${permissionApiRoute}${ApiUrl.PROMOCODES_USERS}`,
    `${permissionApiRoute}${ApiUrl.USERS}`,
  ],
  promoEdit: [
    `${permissionApiRoute}${ApiUrl.PROMOCODES}`,
    `${permissionApiRoute}${ApiUrl.PROMOCODES_STATUS_UPDATE}`,
  ],
  promoAdd: [`${permissionApiRoute}${ApiUrl.PROMOCODES}`],
  promoDelete: [`${permissionApiRoute}${ApiUrl.PROMOCODES}`],
  bannerView: [
    `${permissionApiRoute}${ApiUrl.BANNERS}`,
    `${permissionApiRoute}${ApiUrl.BANNERS}`,
    `${permissionApiRoute}${ApiUrl.EVENTS}`,
    `${permissionApiRoute}${ApiUrl.PACKAGES}`,
    `${permissionApiRoute}${ApiUrl.COURSES}`,
    `${permissionApiRoute}${ApiUrl.BANNERS_USERS}`,
    `${permissionApiRoute}${ApiUrl.USERS}`,
  ],
  bannerEdit: [
    `${permissionApiRoute}${ApiUrl.BANNERS}`,
    `${permissionApiRoute}${ApiUrl.BANNERS_STATUS_UPDATE}`,
    `${permissionApiRoute}${ApiUrl.BANNERS_FORM}`,
  ],
  bannerAdd: [
    `${permissionApiRoute}${ApiUrl.BANNERS}`,
    `${permissionApiRoute}${ApiUrl.BANNERS_FORM}`,
  ],
  bannerDelete: [`${permissionApiRoute}${ApiUrl.BANNERS}`],
  eventsView: [
    `${permissionApiRoute}${ApiUrl.EVENTS}`,
    `${permissionApiRoute}${ApiUrl.EVENTS}`,
    `${permissionApiRoute}${ApiUrl.WELCOME_EMAIL}`,
    `${permissionApiRoute}${ApiUrl.EVENTS_USERS}`,
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.TRANSACTIONS}`,
  ],
  eventsEdit: [
    `${permissionApiRoute}${ApiUrl.EVENTS}`,
    `${permissionApiRoute}${ApiUrl.EVENTS_STATUS_UPDATE}`,
  ],
  eventsAdd: [`${permissionApiRoute}${ApiUrl.EVENTS}`],
  eventsDelete: [`${permissionApiRoute}${ApiUrl.EVENTS}`],
  newsView: [`${permissionApiRoute}${ApiUrl.NEWS}`],
  newsEdit: [
    `${permissionApiRoute}${ApiUrl.NEWS}`,
    `${permissionApiRoute}${ApiUrl.NEWS_STATUS_UPDATE}`,
  ],
  newsAdd: [`${permissionApiRoute}${ApiUrl.NEWS}`],
  newsDelete: [`${permissionApiRoute}${ApiUrl.NEWS}`],
  usersView: [
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.GOALS}`,
    `${permissionApiRoute}${ApiUrl.COURSES}`,
    `${permissionApiRoute}${ApiUrl.PACKAGES}`,
    `${permissionApiRoute}${ApiUrl.USER_COMMENTS}`,
    `${permissionApiRoute}${ApiUrl.TRANSACTIONS}`,
    `${permissionApiRoute}${ApiUrl.RESEND_INVOICE}`,
  ],
  usersEdit: [
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.EVENTS}`,
    `${permissionApiRoute}${ApiUrl.EVENTS_STATUS_UPDATE}`,
    `${permissionApiRoute}${ApiUrl.COURSE_COMPLETION}`,
    `${permissionApiRoute}${ApiUrl.USER_COMMENTS}`,
    `${permissionApiRoute}${ApiUrl.UPDATE_CONTACT_STATUS}`,
    `${permissionApiRoute}${ApiUrl.USER_COMMENTS}`,
  ],
  kycUsersView: [
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.USERS}`,
  ],
  kycUsersEdit: [`${permissionApiRoute}${ApiUrl.KYC_APPROVAL}`],
  affiliateVideoView: [`${permissionApiRoute}${ApiUrl.AFFILIATE_VIDEO}`],
  affiliateVideoEdit: [
    `${permissionApiRoute}${ApiUrl.AFFILIATE_VIDEO}`,
    `${permissionApiRoute}${ApiUrl.AFFILIATE_VIDEO_STATUS_UPDATE}`,
  ],
  affiliateVideoAdd: [`${permissionApiRoute}${ApiUrl.AFFILIATE_VIDEO}`],
  affiliateVideoDelete: [`${permissionApiRoute}${ApiUrl.AFFILIATE_VIDEO}`],
  affiliateUsersView: [
    `${permissionApiRoute}${ApiUrl.AFFILIATE}`,
    `${permissionApiRoute}${ApiUrl.USERS}`,
  ],
  supportView: [
    `${permissionApiRoute}${ApiUrl.SUPPORT}`,
    `${permissionApiRoute}${ApiUrl.USERS}`,
  ],
  supportEdit: [`${permissionApiRoute}${ApiUrl.UPDATE_SUPPORT}`],
  reportView: [
    `${permissionApiRoute}${ApiUrl.REPORT}`,
    `${permissionApiRoute}${ApiUrl.REPORT_GET_EXPORTS}`,
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.ABANDONED_USERS}`,
  ],
  roleMgtView: [
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.ROLES}`,
    `${permissionApiRoute}${ApiUrl.PERMISSION}`,
  ],
  roleMgtEdit: [
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.ROLES}`,
  ],
  roleMgtAdd: [
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.ROLES}`,
    `${permissionApiRoute}${ApiUrl.PERMISSION}`,
  ],
  roleMgtDelete: [
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.ROLES}`,
  ],
  notifiView: [
    `${permissionApiRoute}${ApiUrl.COURSES}`,
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.NOTIFICATION_LIST}`,
  ],
  notifiAdd: [
    `${permissionApiRoute}${ApiUrl.EMAIL_SEND}`,
    `${permissionApiRoute}${ApiUrl.NOTIFICATION_SEND}`,
  ],
  configView: [
    `${permissionApiRoute}${ApiUrl.CONFIGURATION}`,
    `${permissionApiRoute}${ApiUrl.CONFIG_FAQ}`,
    `${permissionApiRoute}${ApiUrl.GOALS}`,
  ],
  configEdit: [
    `${permissionApiRoute}${ApiUrl.CONFIGURATION}`,
    `${permissionApiRoute}${ApiUrl.CONFIG_FAQ}`,
    `${permissionApiRoute}${ApiUrl.CONFIG_STATUS_FAQ}`,
    `${permissionApiRoute}${ApiUrl.GOALS_STATUS_UPDATE}`,
    `${permissionApiRoute}${ApiUrl.GOALS_ORDER_UPDATE}`,
    `${permissionApiRoute}${ApiUrl.GOALS}`,
  ],
  configAdd: [
    `${permissionApiRoute}${ApiUrl.CONFIGURATION}`,
    `${permissionApiRoute}${ApiUrl.CONFIG_FAQ}`,
    `${permissionApiRoute}${ApiUrl.GOALS}`,
  ],
  configDelete: [
    `${permissionApiRoute}${ApiUrl.CONFIGURATION}`,
    `${permissionApiRoute}${ApiUrl.CONFIG_FAQ}`,
    `${permissionApiRoute}${ApiUrl.GOALS}`,
  ],
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Constants } from "../Common/Constants";
import { Form, Formik } from "formik";
import { types } from "../../Redux/Actions/types";
import TextField from "../Common/Fields/TextField";
import RequiredIcon from "../Common/Fields/RequiredIcon";
import ViewImageFile from "../Common/Fields/ViewImageFile";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import TextArea from "../Common/Fields/TextArea";
import { newsInsert, newsList, newsUpdate } from "../../Redux/Actions";
import FileAwsUpload from "../Common/Fields/FileAwsUpload";
import ReactSelect from "../Common/Fields/ReactSelect";
import usePermissions from "../Common/Functions/usePermissions";

const NewsForm = () => {
  const dispatch = useDispatch();
  const today = new Date().toISOString().split("T")[0];
  const { newsSingle } = useSelector((state) => state.News);
  const [selectedSetRule, setSelectedSetRule] = useState(
    Constants.setRuleOptions?.[0]
  );
  const addPermission = usePermissions(Constants.permission.NEWS_ADD);
  const editPermission = usePermissions(Constants.permission.NEWS_EDIT);
  // Initial values for news form
  const [initialValues, setInitialValues] = useState({
    title: "",
    image: "",
    image_web: "",
    banner_image_web: "",
    description: "",
    link: "",
    set_rule: "",
    scheduled_date: "",
    scheduled_time: "",
  });

  // Validation for news form
  const validationSchema = Yup.object().shape({
    title: Yup.string().required(Constants.required),
    image: Yup.string().required(Constants.required),
    image_web: Yup.string().required(Constants.required),
    banner_image_web: Yup.string().required(Constants.required),
    description: Yup.string().required(Constants.required),
    link: Yup.string().optional(),
    scheduled_date:
      selectedSetRule?.value === "scheduled"
        ? Yup.string().required(Constants.required)
        : "",
    scheduled_time:
      selectedSetRule?.value === "scheduled"
        ? Yup.string().required(Constants.required)
        : "",
  });

  useEffect(() => {
    // Set initial values for pre-filled form
    if (newsSingle?._id) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        editValue[key] = newsSingle[key];
        if (key === "set_rule") {
          const arr = Constants?.setRuleOptions?.find(
            (item) => item.value === newsSingle[key]
          );
          setSelectedSetRule(arr);
        }
        setInitialValues(editValue);
      }
    }
  }, [newsSingle]);

  // To set AWS url of news image
  const handleFileChange = (file, setFieldValue, field) => {
    setFieldValue(field, file?.[0]);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update news API
        let submitFunc = newsInsert(values);
        if (newsSingle?._id) {
          const id = newsSingle?._id;
          submitFunc = newsUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(newsList());
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.NEWS_SINGLE, payload: "" });
            dispatch({ type: types.NEWS_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="title"
              className="form-control"
              errors={touched?.title ? errors?.title : undefined}
              touched={touched?.title}
              label={Constants.name}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="link"
              notrequired={true}
              className="form-control"
              errors={touched?.link ? errors?.link : undefined}
              touched={touched?.link}
              label={`${Constants.link} (https://www.yourlink.com | www.yourlink.com | http://www.yourlink.com)`}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextArea
              type="text"
              name="description"
              className="form-control"
              errors={touched?.description ? errors?.description : undefined}
              touched={touched?.description}
              label={Constants.description}
            />
          </div>
          {[
            {
              name: "image",
              type: Constants.imageApp,
              width: 332,
              height: 173,
            },
            {
              name: "image_web",
              type: Constants.imageWeb,
              width: 327,
              height: 192,
            },
          ].map((item) => (
            <div className="col-md-6 mb-2" key={item.name}>
              <label className="form-label">{`${Constants.image} ${item.type}`}</label>
              <RequiredIcon />
              <div className="out-cr">
                <div className="width-outer-up">
                  {values && !values[item.name] && (
                    <FileAwsUpload
                      fileType={[".jpeg", ".png", ".jpg"]}
                      width={item.width}
                      height={item.height}
                      errors={
                        touched[item.name] ? errors[item.name] : undefined
                      }
                      touched={touched[item.name]}
                      label={`${Constants.upload} ${Constants.image}`}
                      handleFile={(files) =>
                        handleFileChange(files, setFieldValue, item.name)
                      }
                      maxFiles={1}
                    />
                  )}
                  {values && values[item.name] && (
                    <ViewImageFile
                      file={values[item.name]}
                      onClick={() => setFieldValue(item.name, "")}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          {[
            {
              name: "banner_image_web",
              type: Constants.imageWeb,
              width: 1506,
              height: 416,
            },
          ].map((item) => (
            <div className="col-md-6 mb-2" key={item.name}>
              <label className="form-label">{`${Constants.bannerImage} ${item.type}`}</label>
              <RequiredIcon />
              <div className="out-cr">
                <div className="width-outer-up">
                  {values && !values[item.name] && (
                    <FileAwsUpload
                      fileType={[".jpeg", ".png", ".jpg"]}
                      width={item.width}
                      height={item.height}
                      errors={
                        touched[item.name] ? errors[item.name] : undefined
                      }
                      touched={touched[item.name]}
                      label={`${Constants.upload} ${Constants.bannerImage}`}
                      handleFile={(files) =>
                        handleFileChange(files, setFieldValue, item.name)
                      }
                      maxFiles={1}
                    />
                  )}
                </div>
                <div className="after-up">
                  {values && values[item.name] && (
                    <ViewImageFile
                      file={values[item.name]}
                      onClick={() => setFieldValue(item.name, "")}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          <div
            className={`${
              selectedSetRule?.value === "scheduled" ? "col-md-4" : "col-md-6"
            } mb-4`}
          >
            <ReactSelect
              label={Constants.setRule}
              required={true}
              options={Constants.setRuleOptions}
              value={selectedSetRule}
              onChange={(selected) => {
                setFieldValue("set_rule", selected.value);
                setSelectedSetRule(selected);
              }}
            />
          </div>
          {selectedSetRule?.value === "scheduled" && (
            <>
              <div className="col-md-4 mb-4">
                <TextField
                  type="date"
                  name="scheduled_date"
                  className="form-control"
                  min={today}
                  errors={
                    touched?.scheduled_date ? errors?.scheduled_date : undefined
                  }
                  touched={touched?.scheduled_date}
                  label={Constants.selectDate}
                />
              </div>
              <div className="col-md-4 mb-4">
                <TextField
                  type="time"
                  name="scheduled_time"
                  className="form-control"
                  errors={
                    touched?.scheduled_time ? errors?.scheduled_time : undefined
                  }
                  touched={touched?.scheduled_time}
                  label={`${Constants.select}${Constants.time}`}
                />
              </div>
            </>
          )}
          {((addPermission && !newsSingle?._id) ||
            (editPermission && newsSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default NewsForm;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../../../Redux/Actions/types";
import { Constants } from "../../../Common/Constants";
import { deleteConfirmBox } from "../../../Common/Functions/deleteConfirmBox";
import { Dropdown } from "react-bootstrap";
import {
  quesDelete,
  quesList,
  quesUpdateStatus,
} from "../../../../Redux/Actions";
import PageHeader from "../../../Common/PageHeader";
import TableList from "../../../Common/UiModel/TableList";
import ModalPopup from "../../../Common/UiModel/ModalPopup";
import QuesForm from "./QuesForm";
import responseMethod from "../../../Common/Functions/responseMethod";
import dayjs from "dayjs";
import EditListButton from "../../../Common/Fields/EditListButton";
import DeleteListButton from "../../../Common/Fields/DeleteListButton";
import ToggleListField from "../../../Common/Fields/ToggleListField";
import ReactPagination from "../../../Common/Fields/ReactPagination";
import { Link } from "react-router-dom";
import usePermissions from "../../../Common/Functions/usePermissions";

const SetList = ({ data }) => {
  const dispatch = useDispatch();
  const { queslist, popupmodel } = useSelector((state) => state.Quiz);
  const [pageIndex, setPageIndex] = useState(1);
  const addPermission = usePermissions(Constants.permission.COURSES_ADD);
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);
  const deletePermission = usePermissions(Constants.permission.COURSES_DELETE);

  // Get the question list based on set id
  useEffect(() => {
    dispatch(quesList({ id: data?._id }));
    dispatch({
      type: types.QUES_SINGLE,
      payload: "",
    });
  }, []);

  // Function to handle the change of status of ques
  const triggerToggle = (rowData) => {
    const itemId = rowData._id;
    const itemData = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(quesUpdateStatus(itemId, itemData)).then((response) => {
      responseMethod(response, dispatch, quesList, data?._id);
    });
  };

  // Function to handle delete of ques
  const onDelete = (delete_id) => {
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(quesDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(quesList({ id: data?._id }));
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  // Method to handle the edit functionality
  const handleEdit = (row) => {
    dispatch({
      type: types.QUES_FORM_MODEL,
      payload: {
        msg: Constants.editQues,
        show: true,
        add: 0,
      },
    });
    dispatch({
      type: types.QUES_SINGLE,
      payload: row,
    });
  };

  // Columns for queslist for set
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.ques,
      cell: (row) => (
        <Link to="" onClick={() => handleEdit(row)}>
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.question}
            onClick={() => navigator.clipboard.writeText(row?.question)}
          >
            {row?.question?.slice(0, 20) + "..."}
          </span>
        </Link>
      ),
    },
    {
      name: Constants.statusKey,
      cell: (row) => row?.status,
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    {
      name: Constants.ansChoiceType,
      selector: (row) =>
        row?.question_type === 0
          ? Constants.ansChoiceOptions?.[0]?.value
          : Constants.ansChoiceOptions?.[1]?.value,
    },
    // Dropdown to delete, edit, change the status of question
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <EditListButton
                      onClick={() => {
                        handleEdit(row);
                      }}
                    />
                  </div>
                  {editPermission && (
                    <div className="list-group">
                      <ToggleListField
                        label={Constants.statusKey}
                        onChange={() => {
                          triggerToggle(row);
                        }}
                        checked={row.status === Constants.status.ACTIVE}
                      />
                    </div>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Pagination function
  const handleOffset = (newOff) => {
    const dataObj = {
      id: data?._id,
      skip: newOff,
      limit: Constants.limitCount,
    };
    dispatch(quesList(dataObj));
  };

  return (
    <>
      <div className="mt-3">
        <PageHeader
          pagetitle={Constants.quesList}
          buttonLink={addPermission}
          onClick={(e) => {
            e.preventDefault();
            dispatch({
              type: types.QUES_FORM_MODEL,
              payload: { msg: Constants.addQues, show: true },
            });
          }}
        />
      </div>
      <TableList
        columns={columns}
        data={queslist?.data}
        defaultSortFieldId
        highlightOnHover
      />
      {queslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={queslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.QUES_SINGLE, payload: "" });
          dispatch({ type: types.QUES_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-lg"}
        title={popupmodel.msg}
        component={<QuesForm set={data?._id} />}
      />
    </>
  );
};

export default SetList;

import { types } from "../Actions/types";

const initialState = {
  freevideoslist: [],
  freeVideosSingle: "",
  popupmodel: "",
};

export default function FreeVideos (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.FREE_VIDEOS_LIST:
      return {
        ...state,
        freevideoslist: payload,
      };
    case types.FREE_VIDEOS_SINGLE:
      return {
        ...state,
        freeVideosSingle: payload,
      };
    case types.FREE_VIDEOS_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
}

import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../Common/Constants";
import { transactionDetail } from "../../Redux/Actions/Transactions";
import dayjs from "dayjs";
import CardBlock from "../Users/CardBlock";
import PageHeader from "../Common/PageHeader";
import { RoutesUrl } from "../Common/RoutesUrl";
import EnrolledItems from "./EnrolledItems";
import ModalPopup from "../Common/UiModel/ModalPopup";
import Refund from "./Refund";
import usePermissions from "../Common/Functions/usePermissions";

const TransactionDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [refundModal, setRefundModal] = useState(false);
  const { transactionsSingle } = useSelector((state) => state.Transactions);
  const transactionTime = dayjs(
    transactionsSingle?.transaction?.transaction_date
  ).format(Constants.timeFormat);
  const transactionDate = dayjs(
    transactionsSingle?.transaction?.transaction_date
  ).format(Constants.dateFormat);
  const editPermission = usePermissions(Constants.permission.TRANSACTION_EDIT);

  useEffect(() => {
    // Transaction detail API
    dispatch(transactionDetail(id));
  }, []);

  return (
    <div className="container-xxl">
      <PageHeader
        list={RoutesUrl.transactions}
        buttonName={Constants.configRefund}
        onClick={() => setRefundModal(true)}
        buttonLink={
          editPermission &&
          transactionsSingle?.coursePackageTransaction?.length > 0 &&
          ["refund_requested", "captured", "authorized"].includes(
            transactionsSingle?.transaction?.payment_status
          )
        }
        pagetitle={`${Constants.transactionId} : ${transactionsSingle?.transaction?.transaction_id}`}
      />
      {/* Personal Details */}
      <CardBlock user={transactionsSingle?.transaction?.user_id} />
      <div className="">
        <div className="row">
          {/* Transaction details */}
          <div className="col-md-6  d-flex">
            <div className="card mt-4 border-shadow w-100 p-4 card-user-details">
              <div className="h4 fw-bold ">{Constants.transactionDetails}</div>
              <div className="h5">
                <p>{`${Constants.time} : ${
                  transactionTime || Constants.na
                }`}</p>
                <p>{`${Constants.date} : ${
                  transactionDate || Constants.na
                }`}</p>
                <p>{`${Constants.usedLoyalty} : ${
                  transactionsSingle?.transaction?.loyalty_points_used || 0
                }`}</p>
              </div>
            </div>
          </div>
          {/* Amount Detail */}
          <div className="col-md-6 d-flex">
            <div className="card mt-4 border-shadow w-100 p-4 card-user-details">
              <div className="h4 fw-bold ">{`${Constants.amountKey} ${Constants.detail}`}</div>
              <div className="h5">
                <p>{`${Constants.subTotal} : ${Constants.currency}${
                  transactionsSingle?.transaction?.sub_total || 0
                }`}</p>
                <p>{`${Constants.discountKey} : ${Constants.currency}${
                  transactionsSingle?.transaction?.total_promo_discount || 0
                }`}</p>
                <p>{`${Constants.taxKey} : ${Constants.currency}${
                  transactionsSingle?.transaction?.total_tax_amount?.toFixed(
                    2
                  ) || 0
                }`}</p>
                <p>{`${Constants.total} : ${Constants.currency}${
                  transactionsSingle?.transaction?.total_payable_amount || 0
                }`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Refund modal */}
      {refundModal && (
        <ModalPopup
          show={refundModal}
          onHide={() => {
            setRefundModal(false);
          }}
          dialogClassName={"modal-md"}
          title={Constants.configRefund}
          component={
            <Refund
              data={transactionsSingle}
              hide={() => setRefundModal(false)}
            />
          }
        />
      )}
      <EnrolledItems data={transactionsSingle} />
    </div>
  );
};

export default TransactionDetail;

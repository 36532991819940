import { types } from "../../Actions/types";

const initialState = {
  packageslist: [],
  packagesSingle: "",
  popupmodel: "",
};

export default function Packages (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.PACKAGES_LIST:
      return {
        ...state,
        packageslist: payload,
      };
    case types.PACKAGES_SINGLE:
      return {
        ...state,
        packagesSingle: payload,
      };
    case types.PACKAGES_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
}

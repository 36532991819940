import { affiliateUsers } from "../../services/affiliate.service";
import { types } from "./types";

export const affiliateUsersList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await affiliateUsers(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.AFFILIATE_USER_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.AFFILIATE_USER_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

import axios from "axios";
import { Constants } from "../components/Common/Constants";
import Cookies from "js-cookie";

class HttpService {
  defaultOptions = () => {
    return {
      baseURL: `${process.env.REACT_APP_API_STAGING_URL}`,
      headers: {
        token: Cookies.get(Constants.accessToken),
      },
    };
  };

  defaultOptionsFormdata = () => {
    return {
      baseURL: `${process.env.REACT_APP_API_STAGING_URL}`,
      headers: {
        token: Cookies.get(Constants.accessToken),
        "Content-Type": "multipart/form-data",
      },
    };
  };

  successResult = (res) => {
    if (res.data && res.data.success === true && res.data.statusCode === 200) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: Constants.somethingWentWrong };
    }
  };

  failureResult = (err) => {
    if (err?.response?.status === 401) {
      localStorage.clear();
      window.location = "/sign-in";
      // const refreshToken = Cookies.get(Constants.refreshToken);
      // try {
      //   axios
      //     .post(
      //       `${process.env.REACT_APP_API_STAGING_URL}${ApiUrl.REFRESH_ACCESS_TOKEN}`,
      //       // No data payload for the request body
      //       null,
      //       {
      //         headers: {
      //           "Content-Type": "multipart/form-data",
      //           // Set the refresh token in the Authorization header
      //           refreshToken,
      //         },
      //       }
      //     )
      //     .then((res) => {
      //       Cookies.remove(Constants.accessToken);
      //       Cookies.set(Constants.accessToken, res.data.data.jwt.accessToken, {
      //         secure: true,
      //       });
      //       window.location.reload();
      //     })
      //     .catch((response) => {
      //       if (response.status === 401) {
      //         localStorage.clear();
      //         Object.keys(Cookies.get()).forEach(function (cookieName) {
      //           Cookies.remove(cookieName);
      //         });
      //         window.location = RoutesUrl.signIn;
      //       }
      //     });
      // } catch (error) {
      //   // Handle error here
      //   console.error("Error occurred while refreshing access token:", error);
      // }
    }
    // if (err?.response?.status === 403) {
    //   window.location = RoutesUrl.forbidden;
    // }
    const data = { status: false };
    try {
      data.error = err.message;
      if (
        err.response &&
        err.response.data &&
        err.response.data.success === false
      ) {
        data.error = err.response.data.message;
      }
      return data;
    } catch (error) {
      data.error = error.message;
      return data;
    }
  };

  get = async (url, options = {}) => {
    try {
      const res = await axios.get(url, {
        ...this.defaultOptions(),
        ...options,
      });
      return this.successResult(res);
    } catch (err) {
      return this.failureResult(err);
    }
  };

  post = async (url, data, options = {}) => {
    try {
      const res = await axios.post(url, data, {
        ...this.defaultOptions(),
        ...options,
      });
      return this.successResult(res);
    } catch (err) {
      return this.failureResult(err);
    }
  };

  postForm = async (url, data, options = {}) => {
    try {
      const res = await axios.post(url, data, {
        ...this.defaultOptionsFormdata(),
        ...options,
      });
      return this.successResult(res);
    } catch (err) {
      return this.failureResult(err);
    }
  };

  put = async (url, data, options = {}) => {
    try {
      const res = await axios.put(url, data, {
        ...this.defaultOptions(),
        ...options,
      });
      return this.successResult(res);
    } catch (err) {
      return this.failureResult(err);
    }
  };

  delete = async (url, data, options = {}) => {
    try {
      const res = await axios.delete(url, {
        ...this.defaultOptions(),
        data,
        ...options,
      });
      return this.successResult(res);
    } catch (err) {
      return this.failureResult(err);
    }
  };
}

const httpServiceInstance = new HttpService();

export default httpServiceInstance;

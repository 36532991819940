import { types } from "../../Actions/types";

const initialState = {
  goalslist: [],
  error: null,
  goalSingle: "",
  show: false,
  popupmodel: "",
};

export default function Goals (state = initialState, action) {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.GOALS_LIST:
      return {
        ...state,
        goalslist: payload,
        error,
      };
    case types.GOALS_SINGLE:
      return {
        ...state,
        goalSingle: payload,
        error,
      };
    case types.GOALS_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../Redux/Actions/types";
import { Constants } from "../Common/Constants";
import responseMethod from "../Common/Functions/responseMethod";
import { deleteConfirmBox } from "../Common/Functions/deleteConfirmBox";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import ModalPopup from "../Common/UiModel/ModalPopup";
import { newsDelete, newsList, newsUpdateStatus } from "../../Redux/Actions";
import NewsForm from "./NewsForm";
import ReactPagination from "../Common/Fields/ReactPagination";
import dayjs from "dayjs";
import EditListButton from "../Common/Fields/EditListButton";
import DeleteListButton from "../Common/Fields/DeleteListButton";
import ToggleListField from "../Common/Fields/ToggleListField";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import usePermissions from "../Common/Functions/usePermissions";

const News = () => {
  const dispatch = useDispatch();
  const { newslist, popupmodel } = useSelector((state) => state.News);
  const [newsData, setNewsData] = useState([]);
  const [isCopied, setIsCopied] = useState({ show: false, id: "" });
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const addPermission = usePermissions(Constants.permission.NEWS_ADD);
  const editPermission = usePermissions(Constants.permission.NEWS_EDIT);
  const deletePermission = usePermissions(Constants.permission.NEWS_DELETE);

  useEffect(() => {
    // News list api
    dispatch(newsList());
    dispatch({
      type: types.NEWS_SINGLE,
      payload: [],
    });
  }, []);

  useEffect(() => {
    // Set data for news from news store
    setNewsData(newslist.data);
  }, [newslist]);

  // Debounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  // Function to hit News list API
  const getData = (searchKey) => {
    const data = { search: searchKey || "" };
    dispatch(newsList(data));
  };

  const triggerToggle = (rowData) => {
    // Trigger status toggle for news
    const itemId = rowData._id;
    const data = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(newsUpdateStatus(itemId, data)).then((response) => {
      responseMethod(response, dispatch, newsList);
    });
  };

  // Copy link
  const handleCopyClick = (row) => {
    navigator.clipboard
      .writeText(row?.course_link || Constants.na)
      .then(() => {
        setIsCopied({ show: true, id: row?._id });
        setTimeout(() => {
          setIsCopied(false);
        }, 500);
      })
      .catch((error) => {
        console.error("Error copying to clipboard: ", error);
      });
  };

  const onDelete = (delete_id) => {
    // Delete news
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(newsDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(newsList());
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  // Function to edit news
  const handleClick = (row) => {
    dispatch({
      type: types.NEWS_FORM_MODEL,
      payload: {
        msg: Constants.editNews,
        show: true,
        add: 0,
      },
    });
    dispatch({
      type: types.NEWS_SINGLE,
      payload: row,
    });
  };

  // Columns to news Table List
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.image,
      width: "10%",
      selector: (row) => (
        <div
          className={`img-out ${
            row.status === Constants.status.ACTIVE
              ? "active-status"
              : "inactive-status"
          }`}
        >
          <img
            src={row?.image}
            alt={row?.name}
            className="m-2"
            style={{
              maxWidth: "75px",
              maxHeight: "75px",
              objectFit: "contain",
              borderRadius: "7%",
            }}
          />
        </div>
      ),
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link to="" onClick={() => handleClick(row)}>
          {row?.title}
        </Link>
      ),
    },
    {
      name: Constants.link,
      selector: (row) => (
        <div style={{ cursor: "pointer" }}>
          <div onClick={() => handleCopyClick(row)}>
            <span
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={row?.link}
            >
              {row?.link?.slice(0, 15) + "..."}
            </span>
          </div>
          {isCopied?.show && row?._id === isCopied.id && (
            <div style={{ color: "green" }}>{Constants.copied}</div>
          )}
        </div>
      ),
    },
    {
      name: Constants.setRule,
      cell: (row) =>
        Constants.setRuleOptions?.find((item) => item.value === row?.set_rule)
          ?.label || Constants.na,
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => {
        return dayjs(row?.createdAt).format(Constants.timeFormat);
      },
    },
    // Dropdown to edit, delete or change the status of news
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  {editPermission && (
                    <>
                      <div className="list-group">
                        <EditListButton
                          onClick={() => {
                            handleClick(row);
                          }}
                        />
                      </div>
                      <div className="list-group">
                        <ToggleListField
                          label={Constants.statusKey}
                          onChange={() => {
                            triggerToggle(row);
                          }}
                          checked={row.status === Constants.status.ACTIVE}
                        />
                      </div>
                    </>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Delete the action if user does not have permission
  if (!editPermission && !deletePermission) {
    const index = columns.findIndex((elt) => elt.name === Constants.actions);
    if (index !== -1) columns.splice(index, 1);
  }

  // Pagination function
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      search,
    };
    dispatch(newsList(data));
  };

  return (
    <div className="container-xxl mt-3">
      <PageHeader
        pagetitle={Constants.newsList}
        buttonLink={addPermission}
        onClick={(e) => {
          e.preventDefault();
          dispatch({
            type: types.NEWS_FORM_MODEL,
            payload: { msg: Constants.addNews, show: true },
          });
        }}
      />
      <div className="row mb-4 align-items-end position-relative justify-content-end">
        {/* Search filter */}
        <div className="col-md-4">
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByName}
            onChange={(event) => {
              setSearch(event.target.value);
              if (event.target.value === "") getData();
            }}
          ></input>
        </div>
      </div>
      <TableList
        columns={columns}
        data={newsData}
        defaultSortFieldId
        highlightOnHover
      />
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.NEWS_SINGLE, payload: "" });
          dispatch({ type: types.NEWS_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-lg"}
        title={popupmodel.msg}
        component={<NewsForm />}
      />
      {newslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={newslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
    </div>
  );
};

export default News;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../Common/Constants";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import { affiliateUsersList } from "../../Redux/Actions";
import { Link, useNavigate } from "react-router-dom";
import { RoutesUrl } from "../Common/RoutesUrl";
import ReactPagination from "../Common/Fields/ReactPagination";
import { types } from "../../Redux/Actions/types";
import ReactSelect from "react-select";
import ViewListButton from "../Common/Fields/ViewListButton";
import { Dropdown } from "react-bootstrap";
import saveExportedFile from "../Common/Functions/saveExportedFile";
import usePermissions from "../Common/Functions/usePermissions";

const AffiliateUsersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { affiliateuserlist } = useSelector((state) => state.Affiliate);
  const [affiliateUsersData, setAffiliateUsersData] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState({ value: null, label: "All" });
  const [pageIndex, setPageIndex] = useState(1);
  const userViewPermission = usePermissions(Constants.permission.USER_VIEW);

  useEffect(() => {
    // get affiliate users list from api
    dispatch(affiliateUsersList());
  }, []);

  useEffect(() => {
    // Set data into state for affiliate users from affiliate users store
    setAffiliateUsersData(affiliateuserlist.data);
  }, [affiliateuserlist]);

  // Debounce search of affiliate users
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  // To get affiliate users list from api on basis of query keys
  const getData = (searchKey) => {
    const data = {
      search: searchKey || "",
      limit: Constants.limitCount,
      status: status.value,
    };
    dispatch(affiliateUsersList(data));
  };

  // Columns to affiliate users Table List
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link
          to={
            userViewPermission ? `${RoutesUrl.users}/${row?.user_id?._id}` : ""
          }
        >
          {row?.user_id?.name || Constants.na}
        </Link>
      ),
    },
    {
      name: Constants.email,
      cell: (row) => row?.user_id?.email || Constants.na,
    },
    // {
    //   name: Constants.dateOfBirth,
    //   cell: (row) => row?.date_of_birth || Constants.na,
    // },
    {
      name: Constants.educationalQualification,
      cell: (row) => {
        const type = Constants.educationalQualificationOptions.find(
          (elt) => elt.value === row?.educational_qualification
        );
        return type?.label || Constants.na;
      },
    },
    {
      name: Constants.howYouGetToKnowUs,
      cell: (row) => {
        const type = Constants.referenceOptions.find(
          (elt) => elt.value === row?.reference_type
        );
        return type?.label || Constants.na;
      },
    },
    // Dropdown to view detaill of affiliate users
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <ViewListButton
                      onClick={() => {
                        navigate(`${RoutesUrl.users}/${row?.user_id?._id}`);
                      }}
                    />
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Get affiliate user according to pagination
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      status: status.value,
      search,
    };
    dispatch(affiliateUsersList(data));
  };

  // Function to export users list to CSV format
  const exportAffiliateUserList = () => {
    const data = {
      skipPagination: true,
      search,
      status: status.value,
    };
    dispatch(affiliateUsersList(data)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        handleDownloadClick(response?.data?.data);
      }
    });
  };

  // Function to handle the download click for exported CSV data
  const handleDownloadClick = (transactionList) => {
    const data = transactionList?.map((element) => {
      // Data for CSV
      return {
        [Constants.name]: element?.user_id?.name || Constants.na,
        [Constants.email]: element?.user_id?.email || Constants.na,
        // [Constants.dateOfBirth]: element?.date_of_birth || Constants.na,
        [Constants.educationalQualification]:
          Constants.educationalQualificationOptions.find(
            (elt) => elt.value === element?.educational_qualification
          )?.label || Constants.na,
        [Constants.howYouGetToKnowUs]:
          Constants.referenceOptions.find(
            (elt) => elt.value === element?.reference_type
          )?.label || Constants.na,
      };
    });
    const csvData = saveExportedFile(Constants.affiliateUsers, dispatch, data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", Constants.csvAffiliateUsers);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Filter affiliate users based on reference options
  const onChangeStatus = (e) => {
    setStatus(e);
    dispatch(
      affiliateUsersList({
        skip: 0,
        limit: Constants.limitCount,
        status: e.value,
        search,
      })
    );
  };

  // Reset all filters
  const onResetFilter = () => {
    setSearch("");
    setStatus({
      value: null,
      label: "All",
    });
    dispatch(
      affiliateUsersList({
        skip: 0,
        limit: Constants.limitCount,
        status: null,
      })
    );
  };

  return (
    <div className="container-xxl">
      <div className="mt-3">
        <PageHeader
          pagetitle={Constants.affiliateUsersList}
          buttonLink={affiliateUsersData?.length > 0}
          buttonName={Constants.export}
          onClick={() => exportAffiliateUserList()}
        />
      </div>
      <div className="row mb-4 align-items-end  position-relative justify-content-end">
        {/* Payment Status filter */}
        <div className="col-md-3 mb-2">
          <label className="form-label">{`${Constants.select} ${Constants.reference}`}</label>
          <ReactSelect
            value={status}
            placeholder="Status"
            isSearchable={false}
            onChange={(e) => onChangeStatus(e)}
            options={Constants.referenceOptions}
          />
        </div>
        {/* Search filter */}
        <div className="col-md-3 mb-2">
          <label className="form-label">{`${Constants.search}`}</label>
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByNameEmail}
            onChange={(event) => {
              setSearch(event.target.value);
              if (event.target.value === "") getData();
            }}
          ></input>
        </div>
        {/* Reset */}
        <div className="col-md-1 mb-2">
          <button
            className="btn btn-primary text-uppercase px-4 reset-btn cross-reset p-right fw-bold mob-m-top"
            onClick={() => onResetFilter()}
          >
            {Constants.reset}
          </button>
        </div>
      </div>
      <TableList
        columns={columns}
        data={affiliateUsersData}
        defaultSortFieldId
        highlightOnHover
      />
      {affiliateuserlist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={affiliateuserlist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
    </div>
  );
};

export default AffiliateUsersList;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Constants } from "../Common/Constants";
import { Form, Formik } from "formik";
import TextField from "../Common/Fields/TextField";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import {
  getRefundAmount,
  refundTransaction,
  transactionDetail,
} from "../../Redux/Actions/Transactions";
import { types } from "../../Redux/Actions/types";
import { useParams } from "react-router-dom";
import RequiredIcon from "../Common/Fields/RequiredIcon";
import MultiSelect from "../Common/Fields/MultiSelect";

const Refund = ({ data, hide }) => {
  const initialRefundData = {
    courses: [],
    packages: [],
    transaction_id: data?.transaction?._id,
  };
  const dispatch = useDispatch();
  const { id } = useParams();
  const [amount, setAmount] = useState();
  const [amountToRefund, setAmountToRefund] = useState(0);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [refundData, setRefundData] = useState(initialRefundData);

  // Initial values for refund form
  const [initialValues] = useState({
    amount: "",
    type: [],
  });

  // option of course or package that is to be refunded
  useEffect(() => {
    if (data?.coursePackageTransaction?.length > 0) {
      const optionsArr = data?.coursePackageTransaction?.map((elt) => ({
        label: elt?.course
          ? `${elt.course?.name} (${Constants.course})`
          : `${elt.package?.name} (${Constants.package})`,
        value: elt?.course?._id || elt?.package?._id,
      }));
      setOptions(optionsArr);
    }
  }, [data]);

  // Validations for refund form
  const validationSchema = Yup.object().shape({
    amount: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidPriceRegex, Constants.invalidPrice)
      .test(
        "is-less-than-paid-amount",
        `${Constants.enteredAmtLessThanPaidAmt} (${amountToRefund})`,
        function (value) {
          return value <= amountToRefund;
        }
      ),
    type: Yup.array().min(1, Constants.required),
  });

  // handle the selection of package or course, and based on that selection get the refund amount
  const handleSelectedItems = (selected, setFieldValue) => {
    setFieldValue(
      "type",
      selected.map((elt) => elt.value)
    );
    setSelectedOption(selected);
    selected.forEach((element) => {
      if (element.label.includes(`(${Constants.course})`))
        initialRefundData.courses.push(element.value);
      else initialRefundData.packages.push(element.value);
    });
    setRefundData(initialRefundData);
    dispatch(getRefundAmount(initialRefundData)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        const amount = response.data.data.amount;
        setAmountToRefund(amount);
        setFieldValue("amount", amount);
        setAmount(amount);
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Refund API
        dispatch(
          refundTransaction({ ...refundData, amount: values.amount * 100 })
        ).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            hide();
            dispatch(transactionDetail(id));
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
          }
        });
      }}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <label className="form-label">{`${Constants.select}`}</label>
            <RequiredIcon />
            <MultiSelect
              key="multi_select"
              options={options}
              onChange={(selected) =>
                handleSelectedItems(selected, setFieldValue)
              }
              value={selectedOption}
              errors={touched?.type ? errors?.type : undefined}
              touched={touched?.type}
              isSelectAll={true}
              menuPlacement={"bottom"}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="amount"
              className="form-control"
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
                setFieldValue("amount", e.target.value);
              }}
              errors={touched?.amount ? errors?.amount : undefined}
              touched={touched?.amount}
              label={Constants.amount}
            />
          </div>
          <ButtonCustom label={Constants.submit} type="submit" />
        </Form>
      )}
    </Formik>
  );
};

export default Refund;

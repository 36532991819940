import React, { useEffect, useState } from "react";
import ButtonCustom from "../../Common/Fields/ButtonCustom";
import { Constants } from "../../Common/Constants";
import { Form, Formik } from "formik";
import TextField from "../../Common/Fields/TextField";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  rolesInsert,
  rolesList,
  rolesUpdate,
} from "../../../Redux/Actions/Roles";
import { types } from "../../../Redux/Actions/types";
import usePermissions from "../../Common/Functions/usePermissions";

const RoleForm = ({ data, onClose }) => {
  const dispatch = useDispatch();
  // Initial values for role form
  const [initialValues, setInitialValues] = useState({
    name: "",
  });
  const addPermission = usePermissions(Constants.permission.ROLE_MGT_ADD);
  const editPermission = usePermissions(Constants.permission.ROLE_MGT_EDIT);

  // Validations for role form
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidTextRegex, Constants.invalid)
      .matches(/^.{1,100}$/, Constants.invalid),
  });

  useEffect(() => {
    // Set initial valuea to pre fill the Category form
    if (data) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (data[key]) {
          editValue[key] = data[key];
        }
        setInitialValues(editValue);
      }
    } else {
      setInitialValues({ name: "" });
    }
  }, [data]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update role
        let submitFunc = rolesInsert(values);
        if (data?._id) {
          const id = data?._id;
          submitFunc = rolesUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            onClose();
            dispatch(rolesList());
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
          }
        });
      }}
    >
      {({ errors, touched }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          {((addPermission && !data?._id) || (editPermission && data?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default RoleForm;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ModalPopup from "../../Common/UiModel/ModalPopup";
import { Constants } from "../../Common/Constants";
import RoleForm from "./RoleForm";
import TableList from "../../Common/UiModel/TableList";
import { Dropdown } from "react-bootstrap";
import EditListButton from "../../Common/Fields/EditListButton";
import DeleteListButton from "../../Common/Fields/DeleteListButton";
import { useDispatch, useSelector } from "react-redux";
import { rolesDelete, rolesList } from "../../../Redux/Actions";
import { deleteConfirmBox } from "../../Common/Functions/deleteConfirmBox";
import { types } from "../../../Redux/Actions/types";
import usePermissions from "../../Common/Functions/usePermissions";

const RoleList = ({ onChange }) => {
  const [createRole, setCreateRole] = useState(false);
  const [defaultRole, setDefaultRole] = useState(false);
  const dispatch = useDispatch();
  const { roleslist } = useSelector((state) => state.Roles);
  const [data, setData] = useState([]);
  const addPermission = usePermissions(Constants.permission.ROLE_MGT_ADD);
  const editPermission = usePermissions(Constants.permission.ROLE_MGT_EDIT);
  const deletePermission = usePermissions(Constants.permission.ROLE_MGT_DELETE);

  useEffect(() => {
    // Roles list API
    dispatch(rolesList());
  }, []);

  // to set the defaultr role to view its permissions
  useEffect(() => {
    if (roleslist?.data?.length > 0) {
      setDefaultRole(roleslist?.data?.[0]);
    }
  }, [roleslist]);

  // default role change reflection in parent component
  useEffect(() => {
    onChange(defaultRole);
  }, [defaultRole]);

  const onDelete = (delete_id) => {
    // Delete role
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(rolesDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(rolesList());
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  // Columns to free Role Table List
  const columns = [
    {
      name: Constants.role,
      width: "70%",
      cell: (row) => (
        <Link onClick={() => setDefaultRole(row)}>
          <div
            className={`${defaultRole?._id === row?._id ? "default-role" : ""}`}
          >
            {row?.name}
          </div>
        </Link>
      ),
    },
    // Dropdown to edit or delete the permission role
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  {editPermission && (
                    <div className="list-group">
                      <EditListButton
                        onClick={() => {
                          setData(row);
                          setCreateRole(true);
                        }}
                      />
                    </div>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // remove action column if no edit & delete permission
  if (!editPermission && !deletePermission) {
    const index = columns.findIndex((elt) => elt.name === Constants.actions);
    if (index !== -1) columns.splice(index, 1);
  }

  return (
    <div className="card-role">
      {addPermission && (
        <Link
          className="btn waves-effect waves-light text-sm-center btn-role add-role permission-role"
          onClick={() => {
            setCreateRole(true);
          }}
        >
          <i className="fa fa-plus-circle me-2"></i>
          {Constants.createRole}
        </Link>
      )}
      <TableList
        columns={columns}
        data={roleslist?.data}
        defaultSortFieldId
        highlightOnHover
      />
      <ModalPopup
        show={createRole}
        onHide={() => {
          setCreateRole(false);
          setData("");
        }}
        dialogClassName={"modal-xs"}
        title={Constants.role}
        component={
          <RoleForm
            onClose={() => {
              setCreateRole(false);
              setData("");
            }}
            data={data}
          />
        }
      />
    </div>
  );
};

export default RoleList;

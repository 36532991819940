import React from "react";

const ToggleField = (props) => (
  <div
    className={`switch-tn ${props?.className} cursor-pointer`}
    onClick={(e) => {
      props?.onChange(e);
    }}
  >
    <label className="my-2 switch-label">
      <input type="checkbox" checked={props.checked}></input>
      <span className="slider round"></span>
    </label>
  </div>
);

export default ToggleField;

import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addAdminConfigurations,
  getAdminConfigurations,
} from "../../../Redux/Actions/Configuration/Configuration";
import { types } from "../../../Redux/Actions/types";
import ButtonCustom from "../../Common/Fields/ButtonCustom";
import { Constants } from "../../Common/Constants";
import * as Yup from "yup";
import TextField from "../../Common/Fields/TextField";
import usePermissions from "../../Common/Functions/usePermissions";

const GoalsSelectionLimit = ({ onHide }) => {
  const dispatch = useDispatch();
  const [section] = useState({ type: Constants.configKeyGoalsSelectionLimit });
  const [totalGoals, setTotalGoals] = useState(0);
  const [settings, setSettings] = useState([]);
  // Initial values for Goal selction limit form
  const [initialValues, setInitialValues] = useState({
    min_goals: "",
    max_goals: "",
  });
  const addPermission = usePermissions(Constants.permission.CONFG_ADD);
  const editPermission = usePermissions(Constants.permission.CONFG_EDIT);

  useEffect(() => {
    // Total number of goals, goals selection API
    dispatch(
      getAdminConfigurations({ type: Constants.configKeyTotalGoals })
    ).then((response) => {
      setTotalGoals(response?.data?.data?.total_goals);
    });
    dispatch(getAdminConfigurations(section)).then((response) => {
      setSettings(response.data.data);
    });
  }, []);

  // Validations for goals selection form
  const validationSchema = Yup.object().shape({
    min_goals: Yup.string()
      .required(Constants.required)
      // Validation - Minimum number of goals should be less than total number of goals
      .test(
        "is-less-than-total-goals",
        `${Constants.minGoalsError} (${totalGoals})`,
        function (value) {
          return value <= totalGoals;
        }
      ),
    max_goals: Yup.string()
      .required(Constants.required)
      // Validation - Maximum number of goals should be more than minimum number of goals
      .test(
        "is-more-than-min-goals",
        Constants.maxGoalsError,
        function (value) {
          const minGoals = this.parent.min_goals;
          return value >= minGoals;
        }
      )
      // Validation - Maximum number of goals should be less than equal to total number of goals
      .test(
        "is-less-than-total-goals",
        `${Constants.maxTotalGoalsError} (${totalGoals})`,
        function (value) {
          return value <= totalGoals;
        }
      ),
  });

  useEffect(() => {
    // Set initial values to pre fill the form
    const listdata = settings;
    if (listdata && listdata.length > 0) {
      const editVal = {};
      for (const value of Object.values(listdata)) {
        editVal[value.name] = value.value;
        setInitialValues(editVal);
      }
    }
  }, [settings]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update configuration API for goal selection limit key
        const dataForm = {};
        dataForm.key_value = values;
        dataForm.type = section.type;
        const submitFunc = addAdminConfigurations(dataForm);
        dispatch(submitFunc).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            onHide();
            dispatch(getAdminConfigurations(section));
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response.data.message,
            });
          }
        });
      }}
    >
      {({ errors, touched }) => (
        <div className="container-xxl">
          <Form className="r-m-0 mt-16-web">
            <div className="mob-t4 head-0">
              <div className="row mt-2">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <TextField
                    type="text"
                    name="min_goals"
                    className="form-control"
                    errors={touched?.min_goals ? errors?.min_goals : undefined}
                    touched={touched?.min_goals}
                    label={Constants.min}
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <TextField
                    type="text"
                    name="max_goals"
                    className="form-control"
                    errors={touched?.max_goals ? errors?.max_goals : undefined}
                    touched={touched?.max_goals}
                    label={Constants.max}
                  />
                </div>
              </div>
              {(editPermission || addPermission) && (
                <ButtonCustom label={Constants.submit} type="submit" />
              )}
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default GoalsSelectionLimit;

import React from "react";
import forbidden from "../../../assets/images/404.png";

const Forbidden = () => {
  return (
    <div className="container-xxl mt-3">
      <div className="card">
        <img
          src={forbidden}
          alt="403-image"
          className="img-fluid w-75  mx-auto my-4"
        />
      </div>
    </div>
  );
};

export default Forbidden;

import Swal from "sweetalert2";
import { types } from "../Actions/types";
import { Constants } from "../../components/Common/Constants";

const initialState = { alert: "", deletedAlert: "", function: "" };

const error = (msg) => {
  Swal.fire({
    title: Constants.oops,
    icon: "error",
    text: msg,
    timer: 2500,
  });
};

const success = (msg) => {
  Swal.fire({
    title: Constants.success,
    text: msg,
    icon: "success",
    timer: 2000,
  });
};

const onDelete = () => {
  Swal.fire({
    title: Constants.deleted,
    text: Constants.itemDeleted,
    icon: "success",
    timer: 1000,
  });
};

export default function AlertMessage (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SUCCESS_ALERT_VISIBLE:
      return {
        alert: success(payload),
      };
    case types.ERROR_ALERT_VISIBLE:
      return {
        alert: error(payload),
      };
    case types.DELETE_ALERT_VISIBLE:
      return {
        alert: onDelete(payload),
      };
    default:
      return state;
  }
}

import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import RequiredIcon from "./RequiredIcon";

const PhoneField = (props) => {
  return (
    <div className="form-group">
      <div className="mb-2">
        <label className="form-label">{props.label} </label>
        {props?.notrequired ? "" : <RequiredIcon />}
        <PhoneInput country="in" {...props} />
        {props.errors && props.touched && (
          <p className="error-msg">{props.errors}</p>
        )}
      </div>
    </div>
  );
};

export default PhoneField;

import React from "react";

const ViewVideoFile = ({ file, onClick }) => (
  <div className="out-cr ">
    <div className="width-outer-up ">
      <div className="after-up">
        <a href={file} target="_blank" rel="noreferrer">
          <video width="160" height="140" controls>
            <source src={file} type="video/mp4" />
          </video>
        </a>
        <div className="files-list-item-remove" onClick={() => onClick()} />
      </div>
    </div>
  </div>
);

export default ViewVideoFile;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  coursesList,
  eventsList,
  packagesList,
  promocodesDelete,
  promocodesList,
  promocodesUpdateStatus,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import { deleteConfirmBox } from "../Common/Functions/deleteConfirmBox";
import { Constants } from "../Common/Constants";
import responseMethod from "../Common/Functions/responseMethod";
import dayjs from "dayjs";
import { Button, Dropdown } from "react-bootstrap";
import DeleteListButton from "../Common/Fields/DeleteListButton";
import ToggleListField from "../Common/Fields/ToggleListField";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import ReactPagination from "../Common/Fields/ReactPagination";
import ModalPopup from "../Common/UiModel/ModalPopup";
import PromoCodeForm from "./PromoCodeForm";
import ReactSelect from "../Common/Fields/ReactSelect";
import DateRangePicker from "../Common/Fields/DateRangePicker";
import ExcelIcon from "../../assets/images/excel.png";
import saveExportedFile from "../Common/Functions/saveExportedFile";
import { Link, useNavigate } from "react-router-dom";
import { RoutesUrl } from "../Common/RoutesUrl";
import ResponseListButton from "../Common/Fields/ResponseListButton";
import usePermissions from "../Common/Functions/usePermissions";
import ViewListButton from "../Common/Fields/ViewListButton";
const initDate = {
  startDate: new Date(),
  endDate: new Date(),
  key: "selection",
};

const PromoCodeList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { promocodeslist, popupmodel } = useSelector(
    (state) => state.Promocodes
  );
  const [promocodesData, setPromoCodesData] = useState([]);
  const [month, setMonth] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [search, setSearch] = useState("");
  const [showCalender, setShowCalender] = useState(false);
  const [initialDate, setInitialDate] = useState(initDate);
  const [range, setRange] = useState({});
  const addPermission = usePermissions(Constants.permission.PROMO_ADD);
  const editPermission = usePermissions(Constants.permission.PROMO_EDIT);
  const deletePermission = usePermissions(Constants.permission.PROMO_DELETE);

  useEffect(() => {
    // Promo Code list API
    dispatch(promocodesList());
    dispatch(coursesList({ skipPagination: true }));
    dispatch(packagesList({ skipPagination: true }));
    dispatch(eventsList({ skipPagination: true }));
    dispatch({
      type: types.PROMOCODES_SINGLE,
      payload: "",
    });
  }, []);

  // De-bounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  // Method to hit promo list API
  const getData = (searchKey) => {
    const data = {
      skip: 0,
      limit: Constants.limitCount,
      search: searchKey || "",
      start_date: range?.start_date,
      end_date: range?.end_date,
      month: month?.value,
    };
    dispatch(promocodesList(data));
  };

  // Set promo code data
  useEffect(() => {
    setPromoCodesData(promocodeslist?.data?.data);
  }, [promocodeslist]);

  // Delete promo code
  const onDelete = (delete_id) => {
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(promocodesDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(promocodesList());
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  // Update status toggle (used in App) for promo code
  const triggerToggle = (rowData) => {
    const itemId = rowData._id;
    const data = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(promocodesUpdateStatus(itemId, data)).then((response) => {
      const tempList = promocodesData;
      const index = tempList.findIndex((item) => item._id === itemId);
      tempList[index].status = data.status;
      setPromoCodesData(tempList);
      responseMethod(response, dispatch, promocodesList);
    });
  };

  // Fucntion to handle edit functionality
  const handleEdit = (row) => {
    dispatch({
      type: types.PROMOCODES_FORM_MODEL,
      payload: {
        msg: Constants.editPromoCode,
        show: true,
        add: 0,
      },
    });
    dispatch({
      type: types.PROMOCODES_SINGLE,
      payload: row,
    });
  };

  // Columns to promo code Table List
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.code,
      cell: (row) => (
        <Link to={`${RoutesUrl.promocode}/${row?._id}`}>{row?.code}</Link>
      ),
    },
    {
      name: Constants.statusKey,
      cell: (row) => (
        <div
          className={`payment-status-badges ${
            row?.status === Constants.statusActiveKey
              ? "status-active"
              : "status-inactive"
          }`}
        >
          {row.status}
        </div>
      ),
    },
    {
      name: Constants.discount,
      cell: (row) => row?.discount,
    },
    {
      name: Constants.maxDiscount,
      cell: (row) => row?.max_discount,
    },
    {
      name: Constants.maxUseCount,
      cell: (row) => row?.max_use_count,
    },
    {
      name: Constants.usedCount,
      cell: (row) => row?.used_promo_count,
    },
    {
      name: Constants.validFrom,
      cell: (row) => dayjs(row?.valid_from).format(Constants.dateFormat),
    },
    {
      name: Constants.validTill,
      cell: (row) => dayjs(row?.valid_till).format(Constants.dateFormat),
    },
    {
      name: Constants.amount,
      cell: (row) => row?.total_discount_amount || 0,
    },
    // Dropdown to edit, view, delete or change the status of promo code, view response list
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <ViewListButton
                      onClick={() => {
                        handleEdit(row);
                      }}
                    />
                  </div>
                  {editPermission && (
                    <div className="list-group">
                      <ToggleListField
                        label={Constants.statusKey}
                        onChange={() => {
                          triggerToggle(row);
                        }}
                        checked={row.status === Constants.status.ACTIVE}
                      />
                    </div>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                  <div className="list-group">
                    <ResponseListButton
                      onClick={() => {
                        navigate(
                          `${RoutesUrl.promocode}/${row?._id}?activeTab=response`
                        );
                      }}
                    />
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Pagination function
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      search,
      start_date: range?.start_date,
      end_date: range?.end_date,
      month: month?.value,
    };
    dispatch(promocodesList(data));
  };

  // On range change
  const onRangeChange = (range) => {
    setInitialDate({
      startDate: range?.[0]?.startDate,
      endDate: range?.[0]?.endDate,
      key: "selection",
    });
    setRange({
      start_date: dayjs(range?.[0]?.startDate).format("YYYY-MM-DD"),
      end_date: dayjs(range?.[0]?.endDate).format("YYYY-MM-DD"),
    });
  };

  // Date range filter
  const onDateFilter = () => {
    setMonth("");
    setShowCalender(false);
    const data = {
      skip: 0,
      limit: Constants.limitCount,
      search,
      start_date: range?.start_date,
      end_date: range?.end_date,
    };
    dispatch(promocodesList(data));
  };

  // To reset all the applied filters
  const onResetFilter = () => {
    setShowCalender(false);
    setInitialDate(initDate);
    setRange({});
    setSearch("");
    setMonth("");
    dispatch(promocodesList());
  };

  // TO handle the month change as a filter
  const handleMonthChange = (selected) => {
    setMonth(selected);
    setRange({});
    const data = {
      skip: 0,
      limit: Constants.limitCount,
      search,
      month: selected?.value,
    };
    dispatch(promocodesList(data));
  };

  // Function to export the promo code list
  const handlePromoExport = () => {
    const data = {
      skipPagination: true,
      search,
      start_date: range?.start_date,
      end_date: range?.end_date,
      month: month.value,
    };
    dispatch(promocodesList(data)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        handleDownloadClick(response?.data?.data?.data);
      }
    });
  };

  // Function to handle the download click for exported CSV data
  const handleDownloadClick = (promoList) => {
    const data = promoList?.map((element) => {
      return {
        [Constants.code]: element?.code || Constants.na,
        [Constants.statusKey]: element?.status || Constants.na,
        [Constants.discount]: element?.discount || 0,
        [Constants.maxDiscount]: element?.max_discount || 0,
        [Constants.maxUseCount]: element?.max_use_count || 0,
        [Constants.validFrom]: dayjs(element?.valid_from).format(
          Constants.dateFormat
        ),
        [Constants.validTill]: dayjs(element?.valid_till).format(
          Constants.dateFormat
        ),
        [Constants.amount]: element?.total_discount_amount || 0,
      };
    });
    const csvData = saveExportedFile(Constants.promoCode, dispatch, data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", Constants.csvPromoCodes);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="container-xxl mt-3">
      <div className="flex-dv">
        <div className="wid-text-side">
          <PageHeader
            pagetitle={Constants.promocodesList}
            buttonLink={addPermission}
            onClick={(e) => {
              e.preventDefault();
              dispatch({
                type: types.PROMOCODES_FORM_MODEL,
                payload: { msg: Constants.addPromoCode, show: true },
              });
            }}
          />
        </div>
        <div className="wid-btnn-side">
          <div className="btn-hover pull-right">
            <button
              type="button"
              disabled={!(promocodesData?.length > 0)}
              className="btn btn-primary"
              onClick={() => handlePromoExport()}
            >
              <img src={ExcelIcon} alt="excel-icon" className="img-excel" />
              {Constants.export}
            </button>
          </div>
        </div>
      </div>
      <div className="row mb-4 align-items-end position-relative justify-content-end">
        {/* Search filter */}
        <div
          className={`calender-outer position-initial ${
            range?.start_date ? "calender-active" : ""
          }`}
        >
          <i
            style={{
              cursor: "pointer",
              fontSize: "25px",
            }}
            className="fa fa-calendar"
            aria-hidden="true"
            onClick={() => {
              setShowCalender((prevState) => !prevState);
            }}
          ></i>
          {showCalender && (
            <div className="date-pos">
              <div>
                <DateRangePicker
                  initialDate={initialDate}
                  rangeSelected={(range) => {
                    onRangeChange(range);
                  }}
                />
              </div>
              <div className="appy-btn">
                <Button
                  className="text-uppercase fw-bold text-center mx-auto d-block"
                  onClick={() => {
                    onDateFilter();
                  }}
                >
                  {Constants.apply}
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-2">
          <ReactSelect
            options={Constants.yearOptions}
            label={`${Constants.month}`}
            value={month}
            onChange={(e) => {
              handleMonthChange(e);
            }}
          />
        </div>
        <div className="col-md-4">
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByCode}
            onChange={(event) => {
              setSearch(event.target.value);
              if (event.target.value === "") getData();
            }}
          ></input>
        </div>
        <div className="col-md-1 reset-btn">
          <button
            className="btn btn-primary text-uppercase px-4  cross-reset p-right fw-bold"
            onClick={() => onResetFilter()}
          >
            {Constants.reset}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3 ">
          <div className="card card-m list-view">
            <div className="card-body row d-flex flex-wrap align-items-center justify-content-between add_food_item">
              <div className="left-info col-md-10 col-8">
                {Constants.totalAmount}
                <div>
                  <span className="fs-6 fw-bold me-2">
                    {promocodeslist?.data?.totalData?.total_amount}
                  </span>
                </div>
              </div>
              <div className="right-icon col-md-2 col-4">
                <i className="icofont-rupee fs-3 color-lightblue"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3 ">
          <div className="card card-m list-view">
            <div className="card-body row d-flex flex-wrap align-items-center justify-content-between add_food_item">
              <div className="left-info col-md-10 col-8">
                {Constants.totalPromoCode}
                <div>
                  <span className="fs-6 fw-bold me-2">
                    {promocodeslist?.data?.totalData?.total_promo}
                  </span>
                </div>
              </div>
              <div className="right-icon col-md-2 col-4">
                <i className="icofont-numbered fs-3 color-light-orange"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3 ">
          <div className="card card-m list-view">
            <div className="card-body row d-flex flex-wrap align-items-center justify-content-between add_food_item">
              <div className="left-info col-md-10 col-8">
                {Constants.activePromoCode}
                <div>
                  <span className="fs-6 fw-bold me-2">
                    {promocodeslist?.data?.totalData?.total_active}
                  </span>
                </div>
              </div>
              <div className="right-icon col-md-2 col-4">
                <i className="icofont-sale-discount fs-3 color-light-success"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3 ">
          <div className="card card-m list-view">
            <div className="card-body row d-flex flex-wrap align-items-center justify-content-between add_food_item">
              <div className="left-info col-md-10 col-8">
                {Constants.inactivePromoCode}
                <div>
                  <span className="fs-6 fw-bold me-2">
                    {promocodeslist?.data?.totalData?.total_inactive}
                  </span>
                </div>
              </div>
              <div className="right-icon col-md-2 col-4">
                <i className="icofont-sale-discount fs-3 color-light-grey"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableList
        columns={columns}
        data={promocodesData}
        defaultSortFieldId
        highlightOnHover
      />
      {promocodeslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={promocodeslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.PROMOCODES_SINGLE, payload: "" });
          dispatch({ type: types.PROMOCODES_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-lg"}
        title={popupmodel.msg}
        component={<PromoCodeForm />}
      />
    </div>
  );
};

export default PromoCodeList;

import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import TextField from "../Common/Fields/TextField";
import { Constants } from "../Common/Constants";
import RequiredIcon from "../Common/Fields/RequiredIcon";
import MultiSelect from "../Common/Fields/MultiSelect";
import {
  categoryList,
  coursesCategoryList,
  coursesInsert,
  coursesList,
  coursesUpdate,
  goalsList,
} from "../../Redux/Actions";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import TextArea from "../Common/Fields/TextArea";
import getOptionsFromData from "../Common/Functions/getOptionsFromData";
import ReactSelect from "../Common/Fields/ReactSelect";
import { types } from "../../Redux/Actions/types";
import ViewImageFile from "../Common/Fields/ViewImageFile";
import ToggleField from "../Common/Fields/ToggleField";
import {
  CourseFormValidation,
  initialCourseFormValues,
} from "./CourseFormValidation";
import { deleteConfirmBox } from "../Common/Functions/deleteConfirmBox";
import FileAwsUpload from "../Common/Fields/FileAwsUpload";
import VideoTusUpload from "../Common/Fields/VideoTusUpload";
import VideoDuration from "../Common/Fields/VideoDuration";
import ViewVideoFile from "../Common/Fields/ViewVideoFile";
import usePermissions from "../Common/Functions/usePermissions";

const CoursesForm = () => {
  const dispatch = useDispatch();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  const [moduleLessons, setModuleLessons] = useState(false);
  const [goalOptions, setGoalOptions] = useState([]);
  const [goalSelected, setGoalSelected] = useState([]);
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [coursesSelected, setCoursesSelected] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [initialValues, setInitialValues] = useState(initialCourseFormValues);
  const { categorylist } = useSelector((state) => state.Category);
  const { goalslist } = useSelector((state) => state.Goals);
  const { coursesSingle, coursescategorylist } = useSelector(
    (state) => state.Courses
  );
  // Course permission
  const addPermission = usePermissions(Constants.permission.COURSES_ADD);
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);

  // validation schema for course form
  const validationSchema = CourseFormValidation;

  // Get the goals list & category list
  useEffect(() => {
    dispatch(categoryList());
    dispatch(goalsList());
  }, []);

  // Convert category list API data to array of objects (value & label)
  useEffect(() => {
    setCategoryOptions(getOptionsFromData(categorylist?.data));
  }, [categorylist?.data]);

  // Convert goals list API data to array of objects (value & label)
  useEffect(() => {
    setGoalOptions(getOptionsFromData(goalslist?.data));
  }, [goalslist?.data]);

  useEffect(() => {
    // Convert courses list API data to array of objects (value & label)
    setCoursesOptions(getOptionsFromData(coursescategorylist?.data));
  }, [coursescategorylist?.data]);

  // Set initial data to pre-fill the form
  useEffect(() => {
    if (coursesSingle) {
      const editValue = {};
      if (coursesSingle?.category_id?._id) {
        setCategorySelected({
          label: coursesSingle?.category_id?.name,
          value: coursesSingle?.category_id?._id,
        });
      }
      for (const [key] of Object.entries(initialValues)) {
        editValue[key] = coursesSingle[key];
        if (key === "category_id") {
          editValue[key] = coursesSingle[key]?._id;
          setCategorySelected({
            value: coursesSingle[key]?._id,
            label: coursesSingle[key]?.name,
          });
          const data = { category_id: coursesSingle[key]?.id };
          dispatch(coursesCategoryList(data));
        }
        if (key === "module_lessons") {
          setModuleLessons(coursesSingle[key]);
        }
        if (key === "courses_link_reference") {
          const val = coursesSingle[key];
          editValue[key] = val?._id;
          if (coursesSingle[key]?.length > 0)
            setCoursesSelected(
              coursesSingle[key].map((elt) => ({
                value: elt?._id,
                label: elt?.name,
              }))
            );
        }
        setGoalSelected(
          coursesSingle.goals.map((elt) => ({
            label: elt?.name,
            value: elt?._id,
          }))
        );
        setInitialValues(editValue);
      }
    }
  }, [coursesSingle]);

  // handle multiple selection for goals, courses
  const handleOptionsChange = (e, setFieldValue, field, setSelected) => {
    setSelected(e);
    const ids = e.map((elt) => elt.value);
    setFieldValue(field, ids);
  };

  // Method to handle the course change
  const handleOptionChange = (e, setFieldValue, field, setSelected) => {
    let data;
    if (field === "category_id") {
      data = { category_id: e.value };
    } else {
      data = {
        category_id: categorySelected.value,
        subcategory_id: e.value,
      };
    }
    dispatch(coursesCategoryList(data));
    setCoursesSelected([]);
    setSelected(e);
    setFieldValue(field, e.value);
  };

  // Set banner image, image & teacher photo AWS url
  const handleFileChange = (file, setFieldValue, field) => {
    setFieldValue(field, file?.[0]);
  };

  // Handle module lesson change, if module is on & we are disabling module status then all the previoud modules will be deleted
  const handleModuleLessonChange = (e, setFieldValue) => {
    const checked = e.target.checked;
    if (coursesSingle?._id) {
      deleteConfirmBox({
        text: Constants.allModulesLessonsWillGetDeleted,
      }).then((result) => {
        if (result.isConfirmed) {
          setFieldValue("module_lessons", checked);
          setModuleLessons(checked);
        } else {
          setFieldValue("module_lessons", !checked);
          setModuleLessons(!checked);
        }
      });
    } else {
      setFieldValue("module_lessons", checked);
      setModuleLessons(checked);
    }
  };

  // Set tus video change AWS url
  const handleVideoChange = (file, setFieldValue, field) => {
    setFieldValue(field, file);
    setShowProgress(true);
    setVideoUrl(file);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // course add or edit API
        let submitFunc = coursesInsert(values);
        if (coursesSingle?._id) {
          const id = coursesSingle?._id;
          submitFunc = coursesUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(coursesList());
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.COURSES_SINGLE, payload: "" });
            dispatch({ type: types.COURSES_FORM_MODEL, payload: "" });
            window.location.reload();
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={`${Constants.course} ${Constants.name}`}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="teacher_name"
              className="form-control"
              errors={touched?.teacher_name ? errors?.teacher_name : undefined}
              touched={touched?.teacher_name}
              label={Constants.teacherName}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextArea
              type="text"
              name="description"
              className="form-control"
              errors={touched?.description ? errors?.description : undefined}
              touched={touched?.description}
              label={`${Constants.course} ${Constants.description}`}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextArea
              type="text"
              name="teacher_detail"
              className="form-control"
              errors={
                touched?.teacher_detail ? errors?.teacher_detail : undefined
              }
              touched={touched?.teacher_detail}
              label={`${Constants.teacherDetail}`}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="strike_out_price"
              className="form-control"
              errors={
                touched?.strike_out_price ? errors?.strike_out_price : undefined
              }
              touched={touched?.strike_out_price}
              label={Constants.strikeOutPrice}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="original_price"
              className="form-control"
              errors={
                touched?.original_price ? errors?.original_price : undefined
              }
              touched={touched?.original_price}
              label={Constants.originalPrice}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="tax_percentage"
              className="form-control"
              errors={
                touched?.tax_percentage ? errors?.tax_percentage : undefined
              }
              touched={touched?.tax_percentage}
              label={Constants.taxPercent}
            />
          </div>
          {[
            {
              name: "image",
              type: Constants.imageApp,
              width: 335,
              height: 165,
            },
            {
              name: "image_web",
              type: Constants.imageWeb,
              width: 359,
              height: 192,
            },
          ].map((item) => (
            <div className="col-md-6 mb-2" key={item.name}>
              <label className="form-label">{`${Constants.image} ${item?.type}`}</label>
              <RequiredIcon />
              <div className="out-cr">
                <div className="width-outer-up">
                  {values && !values?.[item.name] && (
                    <FileAwsUpload
                      fileType={[".jpeg", ".png", ".jpg"]}
                      width={item.width}
                      height={item.height}
                      errors={touched?.image ? errors?.image : undefined}
                      touched={touched?.image}
                      label={`${Constants.upload} ${Constants.image}`}
                      handleFile={(image) =>
                        handleFileChange(image, setFieldValue, item?.name)
                      }
                      maxFiles={1}
                    />
                  )}
                  {values && values[item.name] && (
                    <ViewImageFile
                      file={values[item.name]}
                      onClick={() => setFieldValue(item.name, "")}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          {[
            {
              name: "banner_image",
              type: Constants.imageApp,
              width: 376,
              height: 376,
            },
            {
              name: "banner_image_web",
              type: Constants.imageWeb,
              width: 1506,
              height: 367,
            },
          ].map((item) => (
            <div className="col-md-6 mb-2" key={item.name}>
              <label className="form-label">{`${Constants.bannerImage} ${item.type}`}</label>
              <RequiredIcon />
              <div className="out-cr">
                <div className="width-outer-up">
                  {values && !values[item.name] && (
                    <FileAwsUpload
                      fileType={[".jpeg", ".png", ".jpg"]}
                      width={item.width}
                      height={item.height}
                      errors={
                        touched[item.name] ? errors[item.name] : undefined
                      }
                      touched={touched[item.name]}
                      label={`${Constants.upload} ${Constants.bannerImage}`}
                      handleFile={(files) =>
                        handleFileChange(files, setFieldValue, item.name)
                      }
                      maxFiles={1}
                    />
                  )}
                </div>
                <div className="after-up">
                  {values && values[item.name] && (
                    <ViewImageFile
                      file={values[item.name]}
                      onClick={() => setFieldValue(item.name, "")}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="col-md-6 mb-4">
            <label className="form-label">{`${Constants.teacherPhoto}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up">
                {values && !values?.teacher_photo ? (
                  <FileAwsUpload
                    fileType={[".jpeg", ".png", ".jpg"]}
                    width="73"
                    height="73"
                    errors={
                      touched?.teacher_photo ? errors?.teacher_photo : undefined
                    }
                    touched={touched?.teacher_photo}
                    label={`${Constants.upload} ${Constants.teacherPhoto}`}
                    handleFile={(teacher_photo) =>
                      handleFileChange(
                        teacher_photo,
                        setFieldValue,
                        "teacher_photo"
                      )
                    }
                    maxFiles={1}
                  />
                ) : (
                  <ViewImageFile
                    file={values?.teacher_photo}
                    onClick={() => setFieldValue("teacher_photo", "")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">{`${Constants.video}`}</label>
            <div>
              {values && !values?.video && (
                <VideoTusUpload
                  errors={touched?.video ? errors?.video : undefined}
                  touched={touched?.video}
                  label={`${Constants.upload} ${Constants.video}`}
                  handleFile={(video) =>
                    handleVideoChange(video, setFieldValue, "video")
                  }
                />
              )}
              {showProgress && videoUrl && (
                <VideoDuration
                  url={videoUrl}
                  fetchDuration={(duration) => {
                    setFieldValue("duration", duration);
                    setShowProgress(false);
                  }}
                />
              )}
              {values && !showProgress && values?.video && (
                <ViewVideoFile
                  file={values?.video}
                  onClick={() => {
                    setFieldValue("video", "");
                    setFieldValue("duration", "");
                  }}
                />
              )}
            </div>
          </div>
          <div className={"col-md-12 mb-2"}>
            <TextArea
              type="text"
              name="invoice_description"
              className="form-control"
              errors={
                touched?.invoice_description
                  ? errors?.invoice_description
                  : undefined
              }
              touched={touched?.invoice_description}
              label={`${Constants.invoiceDescription}`}
            />
          </div>
          <div className="col-md-4 col-sm-6 mb-2">
            <div className="flex-toggle">
              <ToggleField
                className="me-"
                onChange={(e) => {
                  setFieldValue("tax_included", e.target.checked);
                }}
                checked={values.tax_included}
              />
              <label className="form-label">{Constants.taxIncluded}</label>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-2">
            <div className="flex-toggle">
              <ToggleField
                className="me-"
                onChange={(e) => {
                  setFieldValue("sent_user_certificate", e.target.checked);
                }}
                checked={values.sent_user_certificate}
              />
              <label className="form-label">{Constants.userCertificate}</label>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-2">
            <div className="flex-toggle">
              <ToggleField
                className="me-"
                onChange={(e) => {
                  setFieldValue("sequencial_learning", e.target.checked);
                }}
                checked={values.sequencial_learning}
              />
              <label className="form-label">
                {Constants.sequentialLearning}
              </label>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-2">
            <div className="flex-toggle">
              <ToggleField
                className="me-"
                onChange={(e) => {
                  setFieldValue("notify_learners", e.target.checked);
                }}
                checked={values.notify_learners}
              />
              <label className="form-label">{Constants.notifyLearners}</label>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-2">
            <div className="flex-toggle">
              <ToggleField
                className="me-"
                onChange={(e) => handleModuleLessonChange(e, setFieldValue)}
                checked={moduleLessons}
              />
              <label className="form-label">{Constants.moduleLessons}</label>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-2">
            <div className="flex-toggle">
              <ToggleField
                className="me-"
                onChange={(e) => {
                  setFieldValue("expiry_duration", e.target.checked);
                }}
                checked={values.expiry_duration}
              />
              <label className="form-label">{Constants.expiryDuration}</label>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <ReactSelect
              label={Constants.selectCategory}
              required={true}
              options={categoryOptions}
              errors={touched?.category_id ? errors?.category_id : undefined}
              touched={touched?.category_id}
              onChange={(selected) =>
                handleOptionChange(
                  selected,
                  setFieldValue,
                  "category_id",
                  setCategorySelected
                )
              }
              value={categorySelected}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">{`${Constants.select} ${Constants.configGoals}`}</label>
            <RequiredIcon />
            <MultiSelect
              key="multi_select"
              options={goalOptions}
              onChange={(selected) =>
                handleOptionsChange(
                  selected,
                  setFieldValue,
                  "goals",
                  setGoalSelected
                )
              }
              value={goalSelected}
              errors={touched?.goals ? errors?.goals : undefined}
              touched={touched?.goals}
              isSelectAll={true}
              menuPlacement={"bottom"}
            />
          </div>
          {values?.expiry_duration && (
            <div className="col-md-6 mb-2">
              <TextField
                type="text"
                name="expiry_duration_month"
                className="form-control"
                errors={
                  touched?.expiry_duration_month
                    ? errors?.expiry_duration_month
                    : undefined
                }
                touched={touched?.expiry_duration_month}
                label={`${Constants.expiryDurationMonths}`}
              />
            </div>
          )}
          <div className={"col-md-6"}>
            <label className="form-label">{`${Constants.select} ${Constants.courseLink}`}</label>
            <MultiSelect
              key="multi_select"
              options={coursesOptions}
              onChange={(selected) =>
                handleOptionsChange(
                  selected,
                  setFieldValue,
                  "courses_link_reference",
                  setCoursesSelected
                )
              }
              value={coursesSelected}
              isSelectAll={true}
              menuPlacement={"bottom"}
            />
          </div>
          <div className="col-md-2 col-6">
            <label className="form-label">{Constants.quiz}</label>
            <div className="flex-toggle">
              <ToggleField
                onChange={(e) => {
                  setFieldValue("quiz", e.target.checked);
                }}
                checked={values.quiz}
              />
            </div>
          </div>
          {values?.quiz && (
            <>
              <div className="col-md-6 mb-2">
                <TextField
                  type="text"
                  name="quiz_sets_attempt"
                  className="form-control"
                  errors={
                    touched?.quiz_sets_attempt
                      ? errors?.quiz_sets_attempt
                      : undefined
                  }
                  touched={touched?.quiz_sets_attempt}
                  label={`${Constants.numberOfAttempts}`}
                />
              </div>
              <div className="col-md-6 mb-2">
                <TextField
                  type="text"
                  name="quiz_passing_percentage"
                  className="form-control"
                  errors={
                    touched?.quiz_passing_percentage
                      ? errors?.quiz_passing_percentage
                      : undefined
                  }
                  touched={touched?.quiz_passing_percentage}
                  label={`${Constants.quizPassingPercent}`}
                />
              </div>
            </>
          )}
          <div className="col-md-3 col-6">
            <label className="form-label">{Constants.freeFirstLesson}</label>
            <div className="flex-toggle">
              <ToggleField
                onChange={(e) => {
                  setFieldValue("free_first_lesson", e.target.checked);
                }}
                checked={values.free_first_lesson}
              />
            </div>
          </div>
          {((addPermission && !coursesSingle?._id) ||
            (editPermission && coursesSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default CoursesForm;

import { Constants } from "./Constants";

// Sidebar menu when user is logged in
export const Menu = [
  {
    name: Constants.dashboard,
    routerLink: [Constants.dashboardKey],
    iconClass: "icofont-home fs-5",
    children: [],
  },
  {
    name: Constants.affiliateVideo,
    routerLink: [Constants.affiliateVideoKey],
    iconClass: "icofont-file-video fs-5",
    children: [],
  },
  {
    name: Constants.affiliateUsers,
    routerLink: [Constants.affiliateUsersKey],
    iconClass: "icofont-users-alt-1 fs-5",
    children: [],
  },
  {
    name: Constants.banner,
    routerLink: [Constants.bannerKey],
    iconClass: "fa fa-columns fs-5",
    children: [],
  },
  {
    name: Constants.category,
    routerLink: [Constants.catalogKey],
    iconClass: "icofont-chart-flow fs-5",
    children: [],
  },
  {
    name: Constants.cms,
    routerLink: [""],
    iconClass: "icofont-ui-settings fs-5",
    children: [
      {
        name: Constants.configBasic,
        routerLink: [Constants.configBasicKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.configContactUs,
        routerLink: [Constants.configContactUsKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.configFaq,
        routerLink: [Constants.configFaqKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.configGoals,
        routerLink: [Constants.configGoalsKey],
        iconClass: "",
        children: [],
      },
      // {
      //   name: Constants.configLoyalty,
      //   routerLink: [Constants.configLoyaltyKey],
      //   iconClass: "",
      //   children: [],
      // },
      {
        name: Constants.configInvoice,
        routerLink: [Constants.configInvoiceKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.configPricing,
        routerLink: [Constants.configPricingKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.configPrivacyPolicy,
        routerLink: [Constants.configPrivacyPolicyKey],
        iconClass: "",
        children: [],
      },
      // {
      //   name: Constants.configRefer,
      //   routerLink: [Constants.configReferKey],
      //   iconClass: "",
      //   children: [],
      // },
      // {
      //   name: Constants.configRefund,
      //   routerLink: [Constants.configRefundKey],
      //   iconClass: "",
      //   children: [],
      // },
      {
        name: Constants.configSales,
        routerLink: [Constants.configSalesKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.configTermsConditions,
        routerLink: [Constants.configTermsConditionsKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.configWalkthrough,
        routerLink: [Constants.configWalkthroughKey],
        iconClass: "",
        children: [],
      },
    ],
  },
  {
    name: Constants.course,
    routerLink: [Constants.coursesKey],
    iconClass: "icofont-read-book fs-5",
    children: [],
  },
  {
    name: Constants.customer,
    routerLink: [Constants.usersKey],
    iconClass: "fa fa-users fs-5",
    children: [],
  },
  {
    name: Constants.event,
    routerLink: [Constants.eventKey],
    iconClass: "fa fa-calendar fs-5",
    children: [],
  },
  {
    name: Constants.freeVideo,
    routerLink: [Constants.freeVideoKey],
    iconClass: "fa fa-video-camera fs-5",
    children: [],
  },
  {
    name: Constants.kycRequest,
    routerLink: [Constants.kycKey],
    iconClass: "icofont-id-card fs-5",
    children: [],
  },
  {
    name: Constants.news,
    routerLink: [Constants.newsKey],
    iconClass: "icofont-paper fs-5",
    children: [],
  },
  {
    name: Constants.notification,
    routerLink: [""],
    iconClass: "icofont-bell-alt fs-5",
    children: [
      {
        name: Constants.email,
        routerLink: [Constants.notificationEmail],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.broadcastNotification,
        routerLink: [Constants.notificationNotification],
        iconClass: "",
        children: [],
      },
    ],
  },
  {
    name: Constants.package,
    routerLink: [Constants.packageKey],
    iconClass: "fa fa-folder-open-o fs-5",
    children: [],
  },
  {
    name: Constants.promoCode,
    routerLink: [Constants.promoCodeKey],
    iconClass: "icofont-star-alt-1 fs-5",
    children: [],
  },
  {
    name: Constants.report,
    routerLink: [""],
    iconClass: "icofont-file-alt fs-5",
    children: [
      {
        name: Constants.overview,
        routerLink: [Constants.overviewKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.logs,
        routerLink: [Constants.logsKey],
        iconClass: "",
        children: [],
      },
      // {
      //   name: Constants.promoCode,
      //   routerLink: [Constants.promoCodeKey],
      //   iconClass: "",
      //   children: [],
      // },
      {
        name: Constants.deletedUsers,
        routerLink: [Constants.deletedUsersKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.abandonedTrack,
        routerLink: [Constants.abandonedCoursesKey],
        iconClass: "",
        children: [],
      },
    ],
  },
  {
    name: Constants.roleMgt,
    routerLink: [""],
    iconClass: "icofont-businessman fs-5",
    children: [
      {
        name: Constants.manageUsers,
        routerLink: [Constants.manageUsersKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.manageRoles,
        routerLink: [Constants.manageRolesKey],
        iconClass: "",
        children: [],
      },
    ],
  },
  {
    name: Constants.support,
    routerLink: [Constants.supportKey],
    iconClass: "icofont-support fs-5",
    children: [],
  },
  {
    name: Constants.transactions,
    routerLink: [Constants.transactions_key],
    iconClass: "icofont-exchange fs-5",
    children: [],
  },

  // {
  //   name: Constants.withdraw,
  //   routerLink: [Constants.withdrawKey],
  //   iconClass: "icofont-pay fs-5",
  //   children: [],
  // },
  {
    name: Constants.signout,
    routerLink: [Constants.signout],
    iconClass: "icofont-sign-out fs-5",
    children: [],
  },
];

import { types } from "../../Actions/types";

const initialState = {
  wktscreenlist: [],
  error: null,
  wktscreenSingle: "",
  wktscreen: "",
  show: false,
  popupmodel: "",
};

export default function Walkthrough (state = initialState, action) {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.WKT_SCREEN_LIST:
      return {
        ...state,
        wktscreenlist: payload,
        error,
      };
    case types.WKT_SCREEN_SINGLE:
      return {
        ...state,
        wktscreenSingle: payload,
        error,
      };
    case types.WKT_SCREEN_ADD:
      return {
        ...state,
        wktscreen: payload,
        error,
      };
    case types.WKT_SCREEN_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
}

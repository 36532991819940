import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  bannersDelete,
  bannersList,
  bannersUpdateStatus,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import { Constants } from "../Common/Constants";
import responseMethod from "../Common/Functions/responseMethod";
import { deleteConfirmBox } from "../Common/Functions/deleteConfirmBox";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import ModalPopup from "../Common/UiModel/ModalPopup";
import BannerForm from "./BannerForm";
import { RoutesUrl } from "../Common/RoutesUrl";
import dayjs from "dayjs";
import ToggleListField from "../Common/Fields/ToggleListField";
import DeleteListButton from "../Common/Fields/DeleteListButton";
import ViewListButton from "../Common/Fields/ViewListButton";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import ResponseListButton from "../Common/Fields/ResponseListButton";
import ReactPagination from "../Common/Fields/ReactPagination";
import usePermissions from "../Common/Functions/usePermissions";

const Banner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bannerslist, popupmodel } = useSelector((state) => state.Banners);
  const [bannersData, setBannersData] = useState([]);
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [isCopied, setIsCopied] = useState({ show: false, id: "" });
  const addPermission = usePermissions(Constants.permission.BANNER_ADD);
  const editPermission = usePermissions(Constants.permission.BANNER_EDIT);
  const deletePermission = usePermissions(Constants.permission.BANNER_DELETE);

  useEffect(() => {
    // Banner list api
    dispatch(bannersList());
    dispatch({
      type: types.BANNERS_SINGLE,
      payload: [],
    });
  }, []);

  useEffect(() => {
    // Set data for banners from banner store
    setBannersData(bannerslist.data);
  }, [bannerslist]);

  // Debounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  // Banner list data API
  const getData = (searchKey) => {
    const data = { search: searchKey || "" };
    dispatch(bannersList(data));
  };

  const triggerToggle = (rowData) => {
    // Trigger status toggle for banner
    const itemId = rowData._id;
    const data = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(bannersUpdateStatus(itemId, data)).then((response) => {
      const tempList = bannersData;
      const index = tempList.findIndex((item) => item._id === itemId);
      tempList[index].status = data.status;
      setBannersData(tempList);
      responseMethod(response, dispatch, bannersList);
    });
  };

  const onDelete = (deleteId) => {
    // Delete banner
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(bannersDelete(deleteId)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(bannersList());
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  // Copy the event SKU from banner list table
  const handleSkuCopyClick = (row) => {
    navigator.clipboard
      .writeText(row?.sku || Constants.na)
      .then(() => {
        setIsCopied({ show: true, id: row?._id });
        setTimeout(() => {
          setIsCopied(false);
        }, 500);
      })
      .catch((error) => {
        console.error("Error copying to clipboard: ", error);
      });
  };

  // Columns to banner Table List
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.image,
      width: "10%",
      selector: (row) => (
        <div
          className={`img-out ${
            row.status === Constants.status.ACTIVE
              ? "active-status"
              : "inactive-status"
          }`}
        >
          <img
            src={row?.image}
            alt={row?.name}
            className="m-2"
            style={{
              maxWidth: "100px",
              maxHeight: "100px",
              objectFit: "contain",
              borderRadius: "7%",
            }}
          />
        </div>
      ),
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link to={`${RoutesUrl.banner}/${row?._id}`}>{row?.name}</Link>
      ),
    },
    {
      name: Constants.sku,
      cell: (row) => (
        <div
          style={{ cursor: "pointer" }}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.sku}
        >
          <div onClick={() => handleSkuCopyClick(row)}>{Constants.sku}</div>
          {isCopied?.show && row?._id === isCopied.id && (
            <div style={{ color: "green" }}>{Constants.copied}</div>
          )}
        </div>
      ),
    },
    {
      name: Constants.redirectionType,
      cell: (row) =>
        Constants.redirectionTypeOptions.find(
          (elt) => elt.key === row?.redirection_type
        )?.value,
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    // Dropdown for view, edit status, delete banner & view th eresponse list of banner
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <ViewListButton
                      onClick={() => {
                        navigate(`${RoutesUrl.banner}/${row?._id}`);
                      }}
                    />
                  </div>
                  {editPermission && (
                    <div className="list-group">
                      <ToggleListField
                        label={Constants.statusKey}
                        onChange={() => {
                          triggerToggle(row);
                        }}
                        checked={row.status === Constants.status.ACTIVE}
                      />
                    </div>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                  {row?.redirection_type !== Constants.imageKey && (
                    <div className="list-group">
                      <ResponseListButton
                        onClick={() => {
                          navigate(
                            `${RoutesUrl.banner}/${row?._id}?activeTab=users`
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // pagination function
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      search,
    };
    dispatch(bannersList(data));
  };

  return (
    <div className="container-xxl mt-3">
      <PageHeader
        pagetitle={Constants.bannerList}
        buttonLink={addPermission}
        onClick={(e) => {
          e.preventDefault();
          dispatch({
            type: types.BANNERS_FORM_MODEL,
            payload: { msg: Constants.addBanner, show: true },
          });
        }}
      />
      <div className="row mb-4 align-items-end position-relative justify-content-end">
        {/* Search filter */}
        <div className="col-md-4">
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByName}
            onChange={(event) => {
              setSearch(event.target.value);
              if (event.target.value === "") getData();
            }}
          ></input>
        </div>
      </div>
      <TableList
        columns={columns}
        data={bannersData}
        defaultSortFieldId
        highlightOnHover
      />
      {bannerslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={bannerslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.BANNERS_SINGLE, payload: "" });
          dispatch({ type: types.BANNERS_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-md"}
        title={popupmodel.msg}
        component={<BannerForm />}
      />
    </div>
  );
};

export default Banner;

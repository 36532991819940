import React, { useEffect, useState } from "react";
import { Constants } from "../Common/Constants";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import dayjs from "dayjs";

const EnrolledItems = ({ data }) => {
  const [list, setList] = useState();

  // to list the enrolled items
  useEffect(() => {
    const tempList = [
      ...(data?.coursePackageTransaction?.length > 0
        ? data?.coursePackageTransaction
        : []),
      ...(data?.eventRes?.length > 0 ? data?.eventRes : []),
    ];
    if (data?.bannerVideo) tempList.push(data?.bannerVideo);
    setList(tempList);
  }, [data]);

  // Columns for enrolled list items
  const columns = [
    {
      name: Constants.id,
      cell: (_row, i) => i + 1,
    },
    {
      name: Constants.type,
      cell: (row) =>
        row?.course
          ? Constants.course
          : row?.event_id
          ? Constants.event
          : row?.package
          ? Constants.package
          : Constants.bannerVideo,
    },
    {
      name: Constants.name,
      selector: (row) =>
        row?.course?.name ||
        row?.package?.name ||
        row?.event_id?.name ||
        row?.banner_id?.name,
    },
    {
      name: Constants.validtill,
      selector: (row) => (
        <div
          className={`payment-status-badges ${row?.expiration ? "failed" : ""}`}
        >
          {row?.expiration
            ? dayjs(row?.enrollment_expired_date).format(Constants.dateFormat)
            : Constants.lifetime}
        </div>
      ),
    },
    {
      name: Constants.promoCode,
      selector: (row) => row?.code || Constants.na,
    },
    {
      name: Constants.amount,
      selector: (row) =>
        row?.course?.original_price ||
        row?.package?.original_price ||
        row?.event_id?.price ||
        row?.banner_video_id?.price ||
        0,
    },
    {
      name: Constants.discountInr,
      selector: (row) => row?.discount || 0,
    },
    {
      name: Constants.tax,
      selector: (row) => row?.tax_amount?.toFixed(2) || 0,
    },
    {
      name: Constants.paidAmount,
      selector: (row) => row?.amount,
    },
    {
      name: Constants.refundedAmount,
      selector: (row) => row?.refunded_amount / 100 || 0,
    },
    {
      name: Constants.statusKey,
      cell: (row) => {
        const status =
          Constants.transactionFilterStatus.find(
            (elt) => elt.value === row?.payment_status
          ) || Constants.transactionFilterStatus[1];
        const val = status?.value;
        return (
          <div
            className={`payment-status-badges ${
              val === "captured" || val === "authorized"
                ? "paid"
                : val === "failed"
                ? "failed"
                : val === "refunded"
                ? "refunded"
                : val === "processed"
                ? "refund-int"
                : val === "refund_requested"
                ? "refund-request"
                : val === "Imported"
                ? "imported"
                : ""
            }`}
          >
            {status?.label}
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-4">
      <div className="mt-4">
        <PageHeader pagetitle={Constants.enrolledItems} />
      </div>
      <TableList
        columns={columns}
        data={list}
        defaultSortFieldId
        highlightOnHover
      />
    </div>
  );
};

export default EnrolledItems;

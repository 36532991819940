import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

// Area Graph
const AreaGraphMonthly = ({ data, title, format }) => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: title,
        data: [],
      },
    ],
    options: {
      chart: {
        height: 250,
        type: "area",
        zoom: {
          enabled: true,
          // Enable x-axis zooming
          type: "x",
          autoScaleYaxis: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: title,
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        labels: { show: false },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: function (val) {
            return format + val;
          },
        },
      },
    },
  });

  // set the values for area graph based on props
  useEffect(() => {
    if (data?.data && (data?.dates || data?.courseNames)) {
      setChartData((prevGraphData) => ({
        ...prevGraphData,
        series: [
          {
            ...prevGraphData.series[0],
            data: data?.data,
          },
        ],
        options: {
          ...prevGraphData.options,
          xaxis: {
            ...prevGraphData.options.xaxis,
            categories: data?.dates || data?.courseNames,
          },
        },
      }));
    }
  }, [data]);

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="area"
      height={310}
    />
  );
};

export default AreaGraphMonthly;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Constants } from "../../Common/Constants";
import NotificationForm from "./NotificationForm";
import NotificationList from "./NotificationList";
import TabsBootstrap from "../../Common/UiModel/TabsBootstrap";
import PageHeader from "../../Common/PageHeader";

const Notification = () => {
  // Tabs list
  const tabsList = [
    {
      eventKey: Constants.notificationKey,
      title: Constants.notification,
      component: (
        <div className="mt-3">
          <PageHeader pagetitle={Constants.detail} />
          <NotificationForm />
        </div>
      ),
    },
    {
      eventKey: Constants.historyKey,
      title: Constants.history,
      component: <NotificationList />,
    },
  ];
  const [activetab, setActiveTab] = useState(tabsList?.[0]?.eventKey);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Set active tab as per URL
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get(Constants.activeTab);
    if (tabParam && tabsList.map((elt) => elt.eventKey).includes(tabParam)) {
      setActiveTab(tabParam);
    }
  }, [location]);

  const handleTabSelect = (key) => {
    // Set URL in correspondance with active tab
    setActiveTab(key);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(Constants.activeTab, key);
    navigate(`?${queryParams.toString()}`);
  };

  return (
    <div className="container-xxl common-space ">
      <div className="row catalog_box al_catalog_box">
        <div className="col-md-12 mb-4 order-list-view">
          {tabsList.length > 0 && (
            <TabsBootstrap
              defaultActiveKey={activetab}
              onSelect={(index) => {
                setActiveTab(index);
              }}
              activeKey={activetab}
              handleTabSelect={handleTabSelect}
              options={tabsList}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;

import {
  dateChangeEnrolledUser,
  detailEnrolledUser,
  editUser,
  enrolledUsers,
  importUsers,
} from "../../../services/Courses/enrolledUsers.service";
import { types } from "../types";

export const enrolledUsersList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await enrolledUsers(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.ENROLLED_USERS_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.ENROLLED_USERS_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const editUserProfile = (data, id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await editUser(data, id);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const enrolledUsersDetail = (courseId, userId) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await detailEnrolledUser(courseId, userId);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.ENROLLED_USERS_SINGLE, payload: result.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.ENROLLED_USERS_SINGLE,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const enrolledUsersDateChange =
  (data, id, userId) => async (dispatch) => {
    dispatch({ type: types.LOADER_VISIBLE });
    const response = await dateChangeEnrolledUser(data, id, userId);
    if (response.status === true) {
      const result = response.data;
      dispatch({ type: types.LOADER_HIDE });
      return { data: result };
    } else {
      dispatch({ type: types.LOADER_HIDE });
      return { error: response.error };
    }
  };

export const importCourseUsers = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await importUsers(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

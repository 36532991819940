import { ApiUrl } from "../../components/Common/ApiUrl";
import HttpService from "../../helper/HttpService";

export const getConfigurations = async (data) => {
  const url = ApiUrl.CONFIGURATION + "?type=" + data.type;
  const res = await HttpService.get(url);
  return res;
};

export const addConfigurations = async (data) => {
  const url = ApiUrl.CONFIGURATION;
  const res = await HttpService.post(url, data);
  return res;
};

export const editConfigurations = async (data) => {
  const url = ApiUrl.CONFIGURATION;
  const res = await HttpService.put(url, data);
  return res;
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../Common/PageHeader";
import { Constants } from "../Common/Constants";
import { types } from "../../Redux/Actions/types";
import ModalPopup from "../Common/UiModel/ModalPopup";

import PackageForm from "./PackageForm";
import TableList from "../Common/UiModel/TableList";
import { useState } from "react";
import { useEffect } from "react";
import {
  packagesDelete,
  packagesList,
  packagesUpdateStatus,
} from "../../Redux/Actions";
import responseMethod from "../Common/Functions/responseMethod";
import { deleteConfirmBox } from "../Common/Functions/deleteConfirmBox";
import { RoutesUrl } from "../Common/RoutesUrl";
import dayjs from "dayjs";
import ToggleListField from "../Common/Fields/ToggleListField";
import ViewListButton from "../Common/Fields/ViewListButton";
import { Link, useNavigate } from "react-router-dom";
import DeleteListButton from "../Common/Fields/DeleteListButton";
import { Dropdown } from "react-bootstrap";
import ReactPagination from "../Common/Fields/ReactPagination";
import usePermissions from "../Common/Functions/usePermissions";

const Package = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { packageslist, popupmodel } = useSelector((state) => state.Packages);
  const [packagesData, setPackagesData] = useState([]);
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [isCopied, setIsCopied] = useState({ show: false, id: "" });
  const addPermission = usePermissions(Constants.permission.PACKAGE_ADD);
  const editPermission = usePermissions(Constants.permission.PACKAGE_EDIT);
  const deletePermission = usePermissions(Constants.permission.PACKAGE_DELETE);

  useEffect(() => {
    // Package list API
    dispatch(packagesList());
    dispatch({
      type: types.PACKAGES_SINGLE,
      payload: [],
    });
  }, []);

  useEffect(() => {
    // Set package data from store
    setPackagesData(packageslist.data);
  }, [packageslist]);

  // Debounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  // Method to hit package list API
  const getData = (searchKey) => {
    const data = { search: searchKey || "" };
    dispatch(packagesList(data));
  };

  const triggerToggle = (rowData) => {
    // Update status for package
    const itemId = rowData._id;
    const data = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(packagesUpdateStatus(itemId, data)).then((response) => {
      responseMethod(response, dispatch, packagesList);
    });
  };

  const onDelete = (delete_id) => {
    // Delete package
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(packagesDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(packagesList());
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  // Columns for package list
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.image,
      width: "10%",
      selector: (row) => (
        <div
          className={`img-out ${
            row.status === Constants.status.ACTIVE
              ? "active-status"
              : "inactive-status"
          }`}
        >
          <img
            src={row?.image}
            alt={row?.name}
            style={{
              maxHeight: "100px",
              maxWidth: "100px",
            }}
          />
        </div>
      ),
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link to={`${RoutesUrl.package}/${row?._id}`}>{row?.name}</Link>
      ),
    },
    {
      name: Constants.sku,
      cell: (row) => (
        <div
          style={{ cursor: "pointer" }}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.sku}
        >
          <div onClick={() => handleSkuCopyClick(row)}>{Constants.sku}</div>
          {isCopied?.show && row?._id === isCopied.id && (
            <div style={{ color: "green" }}>{Constants.copied}</div>
          )}
        </div>
      ),
    },
    {
      name: Constants.originalPrice,
      cell: (row) => row?.original_price,
    },
    {
      name: Constants.strikeOutPrice,
      cell: (row) => row?.strike_out_price,
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    // Dropdown to edit, delet, change the status of package
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <ViewListButton
                      onClick={() => {
                        navigate(`${RoutesUrl.package}/${row?._id}`);
                      }}
                    />
                  </div>
                  {editPermission && (
                    <div className="list-group">
                      <ToggleListField
                        label={Constants.statusKey}
                        onChange={() => {
                          triggerToggle(row);
                        }}
                        checked={row.status === Constants.status.ACTIVE}
                      />
                    </div>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Pagination
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      search,
    };
    dispatch(packagesList(data));
  };

  // Copy the event SKU from package list table
  const handleSkuCopyClick = (row) => {
    navigator.clipboard
      .writeText(row?.sku || Constants.na)
      .then(() => {
        setIsCopied({ show: true, id: row?._id });
        setTimeout(() => {
          setIsCopied(false);
        }, 500);
      })
      .catch((error) => {
        console.error("Error copying to clipboard: ", error);
      });
  };

  return (
    <div className="container-xxl mt-3">
      <PageHeader
        pagetitle={Constants.packageList}
        buttonLink={addPermission}
        buttonName={Constants.create}
        onClick={(e) => {
          e.preventDefault();
          dispatch({
            type: types.PACKAGES_FORM_MODEL,
            payload: { msg: Constants.addPackage, show: true },
          });
        }}
      />
      <div className="row mb-4 align-items-end position-relative justify-content-end">
        {/* Search filter */}
        <div className="col-md-4">
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByName}
            onChange={(event) => {
              setSearch(event.target.value);
              if (event.target.value === "") getData();
            }}
          ></input>
        </div>
      </div>
      <TableList
        columns={columns}
        data={packagesData}
        defaultSortFieldId
        highlightOnHover
      />
      {packageslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={packageslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.PACKAGES_SINGLE, payload: "" });
          dispatch({ type: types.PACKAGES_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-lg"}
        title={popupmodel.msg}
        component={<PackageForm />}
      />
    </div>
  );
};

export default Package;

import React from "react";
import { Constants } from "../../../Common/Constants";
import PageHeader from "../../../Common/PageHeader";
import BannerForm from "../../BannerForm";

const BannerDetailPage = () => {
  return (
    <div className="mt-4 course-form card ">
      <PageHeader pagetitle={Constants.detail} />
      <BannerForm />
    </div>
  );
};

export default BannerDetailPage;

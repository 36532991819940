import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  coursesDelete,
  coursesList,
  coursesUpdateRecommended,
  coursesUpdateStatus,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import { deleteConfirmBox } from "../Common/Functions/deleteConfirmBox";
import { Constants } from "../Common/Constants";
import PageHeader from "../Common/PageHeader";
import ModalPopup from "../Common/UiModel/ModalPopup";
import CoursesForm from "./CoursesForm";
import TableList from "../Common/UiModel/TableList";
import responseMethod from "../Common/Functions/responseMethod";
import { RoutesUrl } from "../Common/RoutesUrl";
import { Link, useNavigate } from "react-router-dom";
import secondsToHour from "../Common/Functions/secondsToHour";
import { Dropdown } from "react-bootstrap";
import DeleteListButton from "../Common/Fields/DeleteListButton";
import ToggleListField from "../Common/Fields/ToggleListField";
import ViewListButton from "../Common/Fields/ViewListButton";
import ReactPagination from "../Common/Fields/ReactPagination";
import usePermissions from "../Common/Functions/usePermissions";

const Courses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseslist, popupmodel } = useSelector((state) => state.Courses);
  const [coursesData, setCoursesData] = useState([]);
  const [isCopied, setIsCopied] = useState({ show: false, id: "" });
  const [isIdCopied, setIsIdCopied] = useState({ show: false, id: "" });
  const [isSkuCopied, setIsSkuCopied] = useState({ show: false, id: "" });
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const addPermission = usePermissions(Constants.permission.COURSES_ADD);
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);
  const deletePermission = usePermissions(Constants.permission.COURSES_DELETE);

  // get course list from API
  useEffect(() => {
    dispatch(coursesList());
    dispatch({
      type: types.COURSES_SINGLE,
      payload: "",
    });
  }, []);

  // Debounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  // set course API data to state
  useEffect(() => {
    setCoursesData(courseslist.data);
  }, [courseslist]);

  // function to hit course list API
  const getData = (searchKey) => {
    const data = { search: searchKey || "" };
    dispatch(coursesList(data));
  };

  // Method to change the status of course
  const triggerToggle = (rowData) => {
    const itemId = rowData._id;
    const data = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(coursesUpdateStatus(itemId, data)).then((response) => {
      const tempList = coursesData;
      const index = tempList.findIndex((item) => item._id === itemId);
      tempList[index].status = data.status;
      setCoursesData(tempList);
      responseMethod(response, dispatch, coursesList);
    });
  };

  // Method to change the recommended status of the course
  const triggerRecommendedToggle = (rowData) => {
    const itemId = rowData._id;
    const data = {
      user_recomended: !rowData?.user_recomended,
    };
    dispatch(coursesUpdateRecommended(itemId, data)).then((response) => {
      const tempList = coursesData;
      const index = tempList.findIndex((item) => item._id === itemId);
      tempList[index].user_recomended = data.user_recomended;
      setCoursesData(tempList);
      responseMethod(response, dispatch, coursesList);
    });
  };

  // Method to delete the course
  const onDelete = (delete_id) => {
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(coursesDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(coursesList());
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  // Copy the course link from course list table
  const handleLinkCopyClick = (row) => {
    navigator.clipboard
      .writeText(row?.course_link || Constants.na)
      .then(() => {
        setIsCopied({ show: true, id: row?._id });
        setTimeout(() => {
          setIsCopied(false);
        }, 500);
      })
      .catch((error) => {
        console.error("Error copying to clipboard: ", error);
      });
  };

  // Copy the course id from course list table
  const handleIDCopyClick = (row) => {
    navigator.clipboard
      .writeText(row?._id || Constants.na)
      .then(() => {
        setIsIdCopied({ show: true, id: row?._id });
        setTimeout(() => {
          setIsIdCopied(false);
        }, 500);
      })
      .catch((error) => {
        console.error("Error copying to clipboard: ", error);
      });
  };

  // Copy the event SKU from course list table
  const handleSkuCopyClick = (row) => {
    navigator.clipboard
      .writeText(row?.sku || Constants.na)
      .then(() => {
        setIsSkuCopied({ show: true, id: row?._id });
        setTimeout(() => {
          setIsSkuCopied(false);
        }, 500);
      })
      .catch((error) => {
        console.error("Error copying to clipboard: ", error);
      });
  };

  // Redirect to detailed view page of course
  const handleView = (row) => {
    navigate(`${RoutesUrl.courses}/${row?._id}`);
    window.location.reload();
  };

  // Coulumns for courses list
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.image,
      width: "10%",
      cell: (row) => (
        <div
          className={`img-out ${
            row.status === Constants.status.ACTIVE
              ? "active-status"
              : "inactive-status"
          }`}
        >
          <img
            src={row?.image}
            alt={row?.name}
            className="m-2"
            style={{
              width: "75px",
              height: "75px",
              objectFit: "contain",
              borderRadius: "7%",
            }}
          />
        </div>
      ),
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link to={`${RoutesUrl.courses}/${row?._id}`}>
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.name}
          >
            {row?.name?.slice(0, 10) + "..."}
          </span>
        </Link>
      ),
    },
    {
      name: Constants.sku,
      cell: (row) => (
        <div
          style={{ cursor: "pointer" }}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.sku}
        >
          <div onClick={() => handleSkuCopyClick(row)}>{Constants.sku}</div>
          {isSkuCopied?.show && row?._id === isSkuCopied.id && (
            <div style={{ color: "green" }}>{Constants.copied}</div>
          )}
        </div>
      ),
    },
    {
      name: Constants.lessons,
      width: "8%",
      cell: (row) => row?.total_lessons || 0,
    },
    {
      name: Constants.link,
      cell: (row) => (
        <div
          style={{ cursor: "pointer" }}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.course_link}
        >
          <div onClick={() => handleLinkCopyClick(row)}>URL</div>
          {isCopied?.show && row?._id === isCopied.id && (
            <div style={{ color: "green" }}>{Constants.copied}</div>
          )}
        </div>
      ),
    },
    {
      name: Constants.id,
      cell: (row) => (
        <div
          style={{ cursor: "pointer" }}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?._id}
        >
          <div onClick={() => handleIDCopyClick(row)}>ID</div>
          {isIdCopied?.show && row?._id === isIdCopied.id && (
            <div style={{ color: "green" }}>{Constants.copied}</div>
          )}
        </div>
      ),
    },
    {
      name: Constants.duration,
      cell: (row) =>
        row?.total_duration ? secondsToHour(row?.total_duration) : 0,
    },
    {
      name: Constants.enrolledLearners,
      cell: (row) => row?.enrolled_learners || 0,
    },
    {
      name: Constants.watchTime,
      cell: (row) =>
        row?.average_watch_time ? secondsToHour(row?.average_watch_time) : 0,
    },
    {
      name: Constants.updatedBy,
      cell: (row) => row?.updated_by?.name || Constants.admin,
    },
    // Dropdown to view, delete, change status of course, change recommended status of course
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <ViewListButton
                      onClick={() => {
                        handleView(row);
                      }}
                    />
                  </div>
                  {editPermission && (
                    <>
                      <div className="list-group">
                        <ToggleListField
                          label={Constants.recommended}
                          onChange={() => {
                            triggerRecommendedToggle(row);
                          }}
                          checked={row.user_recomended}
                        />
                      </div>
                      <div className="list-group">
                        <ToggleListField
                          label={Constants.publish}
                          onChange={() => {
                            triggerToggle(row);
                          }}
                          checked={row.status === Constants.status.ACTIVE}
                        />
                      </div>
                    </>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Pagination function
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      search,
    };
    dispatch(coursesList(data));
  };

  return (
    <div className="container-xxl mt-3">
      <PageHeader
        pagetitle={Constants.coursesList}
        buttonLink={addPermission}
        onClick={(e) => {
          e.preventDefault();
          dispatch({
            type: types.COURSES_FORM_MODEL,
            payload: { msg: Constants.addCourses, show: true },
          });
        }}
      />
      <div className="row mb-4 align-items-end position-relative justify-content-end">
        {/* Search filter */}
        <div className="col-md-4">
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByName}
            onChange={(event) => {
              setSearch(event.target.value);
              if (event.target.value === "") getData();
            }}
          ></input>
        </div>
      </div>
      <TableList
        columns={columns}
        data={coursesData}
        defaultSortFieldId
        highlightOnHover
      />
      {courseslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={courseslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.COURSES_SINGLE, payload: "" });
          dispatch({ type: types.COURSES_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-xl"}
        title={popupmodel.msg}
        component={<CoursesForm />}
      />
    </div>
  );
};

export default Courses;

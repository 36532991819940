import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Constants } from "../../../Common/Constants";
import { Form, Formik } from "formik";
import {
  modulesInsert,
  modulesList,
  modulesUpdate,
} from "../../../../Redux/Actions";
import TextField from "../../../Common/Fields/TextField";
import ButtonCustom from "../../../Common/Fields/ButtonCustom";
import { types } from "../../../../Redux/Actions/types";
import usePermissions from "../../../Common/Functions/usePermissions";

const ModuleForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: "",
    course: id,
  });
  const { modulesSingle } = useSelector((state) => state.Modules);
  // Permisssion
  const addPermission = usePermissions(Constants.permission.COURSES_ADD);
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);

  // Validation schema for module form
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
  });

  // pre fill the module form
  useEffect(() => {
    if (modulesSingle) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (modulesSingle[key]) {
          editValue[key] = modulesSingle[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [modulesSingle]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // module add or edit
        let submitFunc = modulesInsert(values);
        if (modulesSingle?._id) {
          const id = modulesSingle?._id;
          submitFunc = modulesUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(modulesList({ id }));
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.MODULES_SINGLE, payload: "" });
            dispatch({ type: types.MODULES_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          {((addPermission && !modulesSingle?._id) ||
            (editPermission && modulesSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ModuleForm;

import React from "react";
import { mdiAccountStarOutline } from "@mdi/js";
import { Constants } from "../Constants";

const ResponseListButton = (props) => {
  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        props?.onClick();
      }}
    >
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d={mdiAccountStarOutline} />
      </svg>
      <span className="ps-2 name-f">{Constants.responseList}</span>
    </div>
  );
};

export default ResponseListButton;

import React from "react";
import { Field } from "formik";
import RequiredIcon from "./RequiredIcon";

const TextField = (props) => {
  let className = props.className;
  if (!className) {
    className = "form-control";
  }

  return (
    <div className="form-group">
      <div className="mb-2">
        <label className="form-label">{props.label} </label>
        {props?.notrequired ? "" : <RequiredIcon />}
        <Field {...props} className={className} />
        {(props.errors || props.touched) && (
          <p className="error-msg">{props.errors}</p>
        )}
      </div>
    </div>
  );
};

export default TextField;

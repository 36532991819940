import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import usePermissions from "../Common/Functions/usePermissions";
import { Constants } from "../Common/Constants";
import { types } from "../../Redux/Actions/types";
import responseMethod from "../Common/Functions/responseMethod";
import { deleteConfirmBox } from "../Common/Functions/deleteConfirmBox";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { Dropdown } from "bootstrap";
import EditListButton from "../Common/Fields/EditListButton";
import ToggleListField from "../Common/Fields/ToggleListField";
import DeleteListButton from "../Common/Fields/DeleteListButton";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import ReactPagination from "../Common/Fields/ReactPagination";
import ModalPopup from "../Common/UiModel/ModalPopup";
import AffiliateVideoForm from "./AffiliateVideoForm";
import {
  affiliateVideoDelete,
  affiliateVideoList,
  affiliateVideoUpdateStatus,
} from "../../Redux/Actions";
import ReactSelect from "../Common/Fields/ReactSelect";

const AffiliateVideoList = () => {
  const dispatch = useDispatch();
  const { affiliatevideolist, popupmodel } = useSelector(
    (state) => state.AffiliateVideo
  );
  const [affiliateLessonData, setAffiliateLessonData] = useState([]);
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [videoType, setVideoType] = useState(
    Constants.affiliateTagsFilters?.[0]
  );
  const addPermission = usePermissions(
    Constants.permission.AFFILIATE_VIDEO_ADD
  );
  const editPermission = usePermissions(
    Constants.permission.AFFILIATE_VIDEO_EDIT
  );
  const deletePermission = usePermissions(
    Constants.permission.AFFILIATE_VIDEO_DELETE
  );

  useEffect(() => {
    // affiliate Video list api
    dispatch(affiliateVideoList());
    dispatch({
      type: types.AFFILIATE_VIDEO_SINGLE,
      payload: [],
    });
  }, []);

  useEffect(() => {
    // Set data for affiliate Videos from affiliate Video store
    setAffiliateLessonData(affiliatevideolist.data);
  }, [affiliatevideolist]);

  // Debounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  // Function to hit affiliate videos list
  const getData = (searchKey) => {
    const data = { search: searchKey || "" };
    dispatch(affiliateVideoList(data));
  };

  const triggerToggle = (rowData) => {
    // Trigger status toggle for affiliate Video
    const itemId = rowData._id;
    const data = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(affiliateVideoUpdateStatus(itemId, data)).then((response) => {
      responseMethod(response, dispatch, affiliateVideoList);
    });
  };

  const onDelete = (delete_id) => {
    // Delete affiliate Video
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(affiliateVideoDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(affiliateVideoList());
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  // Function to handle edit functionality for affiliate video
  const handleEdit = (row) => {
    dispatch({
      type: types.AFFILIATE_VIDEO_FORM_MODEL,
      payload: {
        msg: Constants.editAffiliateVideo,
        show: true,
        add: 0,
      },
    });
    dispatch({
      type: types.AFFILIATE_VIDEO_SINGLE,
      payload: row,
    });
  };

  // Columns to affiliate Video Table List
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.image,
      width: "10%",
      selector: (row) => (
        <div
          className={`img-out ${
            row.status === Constants.status.ACTIVE
              ? "active-status"
              : "inactive-status"
          }`}
        >
          <img
            src={row?.thumbnail_image}
            alt={row?.name}
            className="m-2"
            style={{
              maxWidth: "100px",
              maxHeight: "100px",
              objectFit: "contain",
              borderRadius: "7%",
            }}
          />
        </div>
      ),
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link to="" onClick={() => handleEdit(row)}>
          {row?.name}
        </Link>
      ),
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    // Dropdown to edit, delete or change the status of affiliate video
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  {editPermission && (
                    <>
                      <div className="list-group">
                        <EditListButton
                          onClick={() => {
                            handleEdit(row);
                          }}
                        />
                      </div>
                      <div className="list-group">
                        <ToggleListField
                          label={Constants.statusKey}
                          onChange={() => {
                            triggerToggle(row);
                          }}
                          checked={row.status === Constants.status.ACTIVE}
                        />
                      </div>
                    </>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Delete the action column if there is no permission to edit and delete the affiliate video
  if (!editPermission && !deletePermission) {
    const index = columns.findIndex((elt) => elt.name === Constants.actions);
    if (index !== -1) columns.splice(index, 1);
  }

  // Pagination function
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      search,
    };
    dispatch(affiliateVideoList(data));
  };

  // Filter affiliate videos based on video type
  const onChangeVideoType = (e) => {
    setVideoType(e);
    dispatch(
      affiliateVideoList({
        skip: 0,
        limit: Constants.limitCount,
        tags: e.value,
        search,
      })
    );
  };

  return (
    <div className="container-xxl">
      <div className="mt-3">
        <PageHeader
          pagetitle={Constants.affiliateVideoList}
          buttonLink={addPermission}
          onClick={(e) => {
            e.preventDefault();
            dispatch({
              type: types.AFFILIATE_VIDEO_FORM_MODEL,
              payload: { msg: Constants.addAffiliateVideo, show: true },
            });
          }}
        />
      </div>
      <div className="row mb-4 align-items-end position-relative justify-content-end">
        {/* Video Type filter */}
        <div className="col-md-3 mb-2">
          <label className="form-label">{`${Constants.select} ${Constants.videoType}`}</label>
          <ReactSelect
            value={videoType}
            placeholder={Constants.videoType}
            isSearchable={false}
            onChange={(e) => onChangeVideoType(e)}
            options={Constants.affiliateTagsFilters}
          />
        </div>
        {/* Search filter */}
        <div className="col-md-4 mb-2">
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByName}
            onChange={(event) => {
              setSearch(event.target.value);
              if (event.target.value === "") getData();
            }}
          ></input>
        </div>
      </div>
      <TableList
        columns={columns}
        data={affiliateLessonData}
        defaultSortFieldId
        highlightOnHover
      />
      {affiliatevideolist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={affiliatevideolist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.AFFILIATE_VIDEO_SINGLE, payload: "" });
          dispatch({ type: types.AFFILIATE_VIDEO_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-lg"}
        title={popupmodel.msg}
        component={<AffiliateVideoForm />}
      />
    </div>
  );
};

export default AffiliateVideoList;

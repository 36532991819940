import React, { useEffect, useState } from "react";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import { Constants } from "../Common/Constants";
import TextField from "../Common/Fields/TextField";
import {
  promocodesDetail,
  promocodesInsert,
  promocodesList,
  promocodesUpdate,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import ToggleField from "../Common/Fields/ToggleField";
import MultiSelect from "../Common/Fields/MultiSelect";
import getOptionsFromData from "../Common/Functions/getOptionsFromData";
import usePermissions from "../Common/Functions/usePermissions";

const PromoCodeForm = () => {
  const dispatch = useDispatch();
  const { promocodesSingle } = useSelector((state) => state.Promocodes);
  const { courseslist } = useSelector((state) => state.Courses);
  const { packageslist } = useSelector((state) => state.Packages);
  const { eventslist } = useSelector((state) => state.Events);
  const [coursesSelected, setCoursesSelected] = useState([]);
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [packagesSelected, setPackagesSelected] = useState([]);
  const [packagesOptions, setPackagesOptions] = useState([]);
  const [eventsSelected, setEventsSelected] = useState([]);
  const [eventsOptions, setEventsOptions] = useState([]);
  // Initial values for promocode form
  const [initialValues, setInitialValues] = useState({
    code: "",
    discount: "",
    max_discount: "",
    max_use_count: "",
    valid_from: "",
    valid_till: "",
    courses: [],
    on_first_purchase_only: false,
    packages: [],
    events: [],
  });
  const today = new Date().toISOString().split("T")[0];
  const addPermission = usePermissions(Constants.permission.PROMO_ADD);
  const editPermission = usePermissions(Constants.permission.PROMO_EDIT);

  // Validations for promocode form
  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .required(Constants.required)
      .matches(/^.{1,50}$/, Constants.invalid)
      .matches(Constants.invalidTextRegex, Constants.invalid),
    discount: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidTextRegex, Constants.invalid)
      .matches(Constants.invalidPercentRegex, Constants.invalidValue),
    max_discount: Yup.string()
      .required(Constants.required)
      .matches(/^.{1,10}$/, Constants.invalid),
    max_use_count: Yup.string()
      .required(Constants.required)
      .matches(/^.{1,10}$/, Constants.invalid),
    valid_from: Yup.string().required(Constants.required),
    valid_till: Yup.string().required(Constants.required),
    courses:
      packagesSelected?.length === 0 && eventsSelected?.length === 0
        ? Yup.array().min(1, Constants.required)
        : "",
    packages:
      coursesSelected?.length === 0 && eventsSelected?.length === 0
        ? Yup.array().min(1, Constants.required)
        : "",
    events:
      coursesSelected?.length === 0 && packagesSelected?.length === 0
        ? Yup.array().min(1, Constants.required)
        : "",
  });

  useEffect(() => {
    // Set initial valuea to pre fill the Category form
    if (promocodesSingle) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        editValue[key] = promocodesSingle[key];
        if (key === "courses") {
          editValue[key] = promocodesSingle[key].map((elt) => elt?._id);
          setCoursesSelected(
            promocodesSingle[key].map((elt) => ({
              value: elt?._id,
              label: elt?.name,
            }))
          );
        }
        if (key === "packages") {
          editValue[key] = promocodesSingle[key].map((elt) => elt?._id);
          setPackagesSelected(
            promocodesSingle[key].map((elt) => ({
              value: elt?._id,
              label: elt?.name,
            }))
          );
        }
        if (key === "events") {
          editValue[key] = promocodesSingle[key].map((elt) => elt?._id);
          setEventsSelected(
            promocodesSingle[key].map((elt) => ({
              value: elt?._id,
              label: elt?.name,
            }))
          );
        }
        setInitialValues(editValue);
      }
    }
  }, [promocodesSingle]);

  // On selection of course/package/event
  const handleOptionsChange = (e, setFieldValue, field, setSelected) => {
    setSelected(e);
    const ids = e.map((elt) => elt.value);
    setFieldValue(field, ids);
  };

  // Convert course list API data to array of objects (value & label)
  useEffect(() => {
    setCoursesOptions(getOptionsFromData(courseslist?.data));
  }, [courseslist?.data]);

  // Convert package list API data to array of objects (value & label)
  useEffect(() => {
    setPackagesOptions(getOptionsFromData(packageslist?.data));
  }, [packageslist?.data]);

  // Convert event list API data to array of objects (value & label)
  useEffect(() => {
    setEventsOptions(getOptionsFromData(eventslist?.data));
  }, [eventslist?.data]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (coursesSelected?.length > 0 && packagesSelected?.length > 0)
          values.type = 2;
        else if (coursesSelected?.length > 0) values.type = 0;
        else values.type = 1;
        // Add or update promocode API
        let submitFunc = promocodesInsert(values);
        if (promocodesSingle?._id) {
          const id = promocodesSingle?._id;
          submitFunc = promocodesUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            if (promocodesSingle?._id)
              dispatch(promocodesDetail(promocodesSingle?._id));
            else dispatch(promocodesList());
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.PROMOCODES_SINGLE, payload: "" });
            dispatch({ type: types.PROMOCODES_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form className="row">
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="code"
              className="form-control"
              errors={touched?.code ? errors?.code : undefined}
              touched={touched?.code}
              label={Constants.code}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="discount"
              className="form-control"
              errors={touched?.discount ? errors?.discount : undefined}
              touched={touched?.discount}
              label={Constants.discount}
            />
          </div>
          <div className="col-md-4 mb-2">
            <TextField
              type="text"
              name="max_discount"
              className="form-control"
              errors={touched?.max_discount ? errors?.max_discount : undefined}
              touched={touched?.max_discount}
              label={Constants.maxDiscount}
            />
          </div>
          <div className="col-md-4 mb-2">
            <TextField
              type="text"
              name="max_use_count"
              className="form-control"
              errors={
                touched?.max_use_count ? errors?.max_use_count : undefined
              }
              touched={touched?.max_use_count}
              label={Constants.maxUseCount}
            />
          </div>
          <div className="col-md-4 mb-2">
            <label className="form-label">{Constants.onFirstPurchase}</label>
            <div className="flex-toggle">
              <ToggleField
                onChange={(e) => {
                  setFieldValue("on_first_purchase_only", e.target.checked);
                }}
                checked={values.on_first_purchase_only}
              />
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="date"
              name="valid_from"
              min={promocodesSingle?._id ? false : today}
              className="form-control"
              errors={touched?.valid_from ? errors?.valid_from : undefined}
              touched={touched?.valid_from}
              label={Constants.validFrom}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="date"
              name="valid_till"
              min={values.valid_from}
              className="form-control"
              errors={touched?.valid_till ? errors?.valid_till : undefined}
              touched={touched?.valid_till}
              label={Constants.validTill}
            />
          </div>
          <div className="col-md-12 mb-2">
            <label className="form-label">{`${Constants.select} ${Constants.course}`}</label>
            <MultiSelect
              key="multi_select"
              options={coursesOptions}
              onChange={(selected) =>
                handleOptionsChange(
                  selected,
                  setFieldValue,
                  "courses",
                  setCoursesSelected
                )
              }
              value={coursesSelected}
              errors={touched?.courses ? errors?.courses : undefined}
              touched={touched?.courses}
              isSelectAll={true}
              menuPlacement={"bottom"}
            />
          </div>
          <div className="col-md-12 mb-2">
            <label className="form-label">{`${Constants.select} ${Constants.package}`}</label>
            <MultiSelect
              key="multi_select"
              options={packagesOptions}
              onChange={(selected) =>
                handleOptionsChange(
                  selected,
                  setFieldValue,
                  "packages",
                  setPackagesSelected
                )
              }
              value={packagesSelected}
              errors={touched?.packages ? errors?.packages : undefined}
              touched={touched?.packages}
              isSelectAll={true}
              menuPlacement={"bottom"}
            />
          </div>
          <div className="col-md-12 mb-2">
            <label className="form-label">{`${Constants.select} ${Constants.event}`}</label>
            <MultiSelect
              key="multi_select"
              options={eventsOptions}
              onChange={(selected) =>
                handleOptionsChange(
                  selected,
                  setFieldValue,
                  "events",
                  setEventsSelected
                )
              }
              value={eventsSelected}
              errors={touched?.events ? errors?.events : undefined}
              touched={touched?.events}
              isSelectAll={true}
              menuPlacement={"bottom"}
            />
          </div>
          {((addPermission && !promocodesSingle?._id) ||
            (editPermission && promocodesSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default PromoCodeForm;

import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Constants } from "../Common/Constants";
import TextArea from "../Common/Fields/TextArea";

const SupportForm = ({ data }) => {
  const [initialValues, setInitialValues] = useState({
    description: "",
    query: "",
  });

  // To add pre-filled values to support form for detailed view
  useEffect(() => {
    if (data) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (data[key]) {
          editValue[key] = data[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [data]);

  return (
    <Formik initialValues={initialValues} enableReinitialize={true}>
      {() => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextArea
              type="text"
              name="query"
              className="form-control"
              label={Constants.query}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextArea
              type="text"
              name="description"
              className="form-control"
              label={Constants.description}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SupportForm;

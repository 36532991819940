import { types } from "../../Actions/types";

const initialState = {
  reviewslist: [],
  error: null,
  reviewsSingle: "",
  show: false,
  popupmodel: "",
};

export default function Reviews (state = initialState, action) {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.REVIEWS_LIST:
      return {
        ...state,
        reviewslist: payload,
        error,
      };
    case types.REVIEWS_SINGLE:
      return {
        ...state,
        reviewsSingle: payload,
        error,
      };
    case types.REVIEWS_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
}

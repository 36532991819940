import { Form, Formik } from "formik";
import { Constants } from "../Common/Constants";
import PageHeader from "../Common/PageHeader";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import * as Yup from "yup";
import {
  addAdminConfigurations,
  getAdminConfigurations,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

import TextEditor from "../Common/Fields/TextEditor";
import usePermissions from "../Common/Functions/usePermissions";

// Configuration section for admin settings
const section = { type: Constants.configTermsConditionsKey };

const TermCondition = () => {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState([]);
  const addPermission = usePermissions(Constants.permission.CONFG_ADD);
  const editPermission = usePermissions(Constants.permission.CONFG_EDIT);
  const [initialValues, setInitialValues] = useState({
    terms: "",
  });
  const validationSchema = Yup.object().shape({
    terms: Yup.string().required(Constants.required),
  });

  // Fetch admin configurations on component mount
  useEffect(() => {
    dispatch(getAdminConfigurations(section)).then((response) => {
      setSettings(response.data.data);
    });
  }, []);

  // Set initial form values when admin configurations are fetched
  useEffect(() => {
    const listdata = settings;
    if (listdata && listdata.length > 0) {
      const editVal = {};
      for (const value of Object.values(listdata)) {
        editVal[value.name] = value.value;
        setInitialValues(editVal);
      }
    }
  }, [settings]);

  // Callback function for handling text editor changes
  const onEditorChange = (editor, value, setFieldValue) => {
    const data = editor.getData();
    setFieldValue(value, data);
  };

  return (
    <>
      <div className="container-xxl">
        <PageHeader pagetitle={Constants.configTermsConditions} />
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const dataForm = {};
            dataForm.key_value = values;
            dataForm.type = section.type;
            // Action for adding admin configurations
            const submitFunc = addAdminConfigurations(dataForm);
            // Dispatch action and handle response
            dispatch(submitFunc).then((response) => {
              if (response.error) {
                dispatch({
                  type: types.ERROR_ALERT_VISIBLE,
                  payload: response.error,
                });
              } else {
                dispatch({
                  type: types.SUCCESS_ALERT_VISIBLE,
                  payload: response.data.message,
                });
                // Refresh admin configurations after successful submission
                dispatch(getAdminConfigurations(section));
              }
            });
          }}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form className="list-view rounded-3 card">
              <div className="mx-4">
                <TextEditor
                  hideRequiredIcon="true"
                  onChange={(_event, editor) =>
                    onEditorChange(editor, "terms", setFieldValue)
                  }
                  errors={errors?.terms ? errors?.terms : undefined}
                  touched={touched?.terms}
                  data={`${values?.terms || ""}`}
                />
              </div>
              {(editPermission || addPermission) && (
                <ButtonCustom label={Constants.submit} type="submit" />
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default TermCondition;

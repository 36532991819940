import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const affiliateVideoAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.AFFILIATE_VIDEO, data);
  return response;
};

export const affiliateVideoEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.AFFILIATE_VIDEO + "?id=" + id,
    data
  );
  return response;
};

export const affiliateVideoRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.AFFILIATE_VIDEO + "?id=" + id
  );
  return response;
};

export const affiliateVideoStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.AFFILIATE_VIDEO_STATUS_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const affiliateVideo = async (data) => {
  const url = `${ApiUrl.AFFILIATE_VIDEO}?skip=${data?.skip || 0}&limit=${
    data?.limit || Constants.limitCount
  }${data && data?.search ? `&search=${data?.search}` : ""}${
    data && data?.tags ? `&tags=${data?.tags}` : ""
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};

import React from "react";
import { Modal } from "react-bootstrap";

const ModalPopup = (props) => {
  return (
    <Modal
      show={props.show}
      className={`${props.dialogClassName} modal-popup modal-lg`}
      onHide={props.onHide}
      backdrop="static"
    >
      <Modal.Header className="modal-header modal-pad" closeButton>
        <h5 className="modal-title fw-bold" id="expeditLabel">
          {props.title}
        </h5>
      </Modal.Header>
      <Modal.Body className="modal-body modal-pad">
        <div className="deadline-form">{props.component}</div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPopup;

import { types } from "../Actions/types";

const initialState = {
  reportlogslist: [],
  abandoneduserslist: [],
  reportslist: [],
};

export default function Reports (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.REPORTS_LOGS_LIST:
      return {
        ...state,
        reportlogslist: payload,
      };
    case types.ABANDONED_USERS_LIST:
      return {
        ...state,
        abandoneduserslist: payload,
      };
    case types.REPORTS_LIST:
      return {
        ...state,
        reportslist: payload,
      };

    default:
      return state;
  }
}

import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import TextField from "../../../Common/Fields/TextField";
import * as Yup from "yup";
import ButtonCustom from "../../../Common/Fields/ButtonCustom";
import { useParams } from "react-router-dom";
import { Constants } from "../../../Common/Constants";
import TextEditor from "../../../Common/Fields/TextEditor";
import {
  coursesFaqsInsert,
  coursesFaqsList,
  coursesFaqsUpdate,
} from "../../../../Redux/Actions";
import { types } from "../../../../Redux/Actions/types";
import usePermissions from "../../../Common/Functions/usePermissions";

const CoursesFaqsForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    question: "",
    answer: "",
    course: id,
  });
  const { coursesFaqsSingle } = useSelector((state) => state.CoursesFaqs);
  // Permission
  const addPermission = usePermissions(Constants.permission.COURSES_ADD);
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);

  const validationSchema = Yup.object().shape({
    question: Yup.string()
      .required(Constants.required)
      .matches(Constants.invalidFaqQuesLimitRegex, Constants.invalidWordLimit),
    answer: Yup.string().required(Constants.required),
  });

  // set initial values to pre fill the form
  useEffect(() => {
    if (coursesFaqsSingle) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (coursesFaqsSingle[key]) {
          editValue[key] = coursesFaqsSingle[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [coursesFaqsSingle]);

  // On change of editor
  const onEditorChange = function (editor, value, setFieldValue) {
    const data = editor.getData();
    setFieldValue(value, data);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Course fq add or edit API
        let submitFunc = coursesFaqsInsert(values);
        if (coursesFaqsSingle?._id) {
          const id = coursesFaqsSingle?._id;
          submitFunc = coursesFaqsUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(coursesFaqsList({ id }));
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.COURSES_FAQS_SINGLE, payload: "" });
            dispatch({ type: types.COURSES_FAQS_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="question"
              className="form-control"
              errors={touched?.question ? errors?.question : undefined}
              touched={touched?.question}
              label={Constants.ques}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextEditor
              label={Constants.ans}
              onChange={(_event, editor) =>
                onEditorChange(editor, "answer", setFieldValue)
              }
              data={`${values?.answer || ""}`}
              errors={touched?.answer ? errors?.answer : undefined}
              touched={touched?.answer}
            />
          </div>
          {((addPermission && !coursesFaqsSingle?._id) ||
            (editPermission && coursesFaqsSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default CoursesFaqsForm;

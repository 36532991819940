import { types } from "../../Actions/types";

const initialState = {
  bannerslist: [],
  bannersuserslist: [],
  banneruserform: [],
  bannersSingle: "",
  popupmodel: "",
};

export default function Banners (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.BANNERS_LIST:
      return {
        ...state,
        bannerslist: payload,
      };
    case types.BANNERS_USERS_LIST:
      return {
        ...state,
        bannersuserslist: payload,
      };
    case types.BANNERS_USER_FORM:
      return {
        ...state,
        banneruserform: payload,
      };
    case types.BANNERS_SINGLE:
      return {
        ...state,
        bannersSingle: payload,
      };
    case types.BANNERS_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
}

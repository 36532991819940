import { types } from "../Actions/types";

const initialState = {
  affiliatevideolist: [],
  affiliatevideoSingle: "",
  popupmodel: "",
};

const AffiliateVideo = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.AFFILIATE_VIDEO_LIST:
      return {
        ...state,
        affiliatevideolist: payload,
      };
    case types.AFFILIATE_VIDEO_SINGLE:
      return {
        ...state,
        affiliatevideoSingle: payload,
      };
    case types.AFFILIATE_VIDEO_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
};

export default AffiliateVideo;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  packageCoursesDelete,
  packageCoursesList,
} from "../../../../Redux/Actions";
import { types } from "../../../../Redux/Actions/types";
import { deleteConfirmBox } from "../../../Common/Functions/deleteConfirmBox";
import { Constants } from "../../../Common/Constants";
import PageHeader from "../../../Common/PageHeader";
import TableList from "../../../Common/UiModel/TableList";
import ModalPopup from "../../../Common/UiModel/ModalPopup";
import PackageCourseForm from "./PackageCourseForm";
import { Link, useParams } from "react-router-dom";
import EditListButton from "../../../Common/Fields/EditListButton";
import DeleteListButton from "../../../Common/Fields/DeleteListButton";
import dayjs from "dayjs";
import { Dropdown } from "react-bootstrap";
import usePermissions from "../../../Common/Functions/usePermissions";

const PackageCourse = () => {
  const dispatch = useDispatch();
  const { packageCourseslist, popupmodel } = useSelector(
    (state) => state.PackageCourses
  );
  const [packageCoursesData, setPackageCoursesData] = useState([]);
  const { id } = useParams();
  const addPermission = usePermissions(Constants.permission.PACKAGE_ADD);
  const editPermission = usePermissions(Constants.permission.PACKAGE_EDIT);
  const deletePermission = usePermissions(Constants.permission.PACKAGE_DELETE);

  useEffect(() => {
    // Package courses list API
    dispatch(packageCoursesList(id));
    dispatch({
      type: types.PACKAGE_COURSES_LIST,
      payload: "",
    });
  }, []);

  useEffect(() => {
    // set package course data
    setPackageCoursesData(packageCourseslist.data);
  }, [packageCourseslist]);

  const onDelete = (delete_id) => {
    // Delete package course
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(packageCoursesDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(packageCoursesList(id));
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  // Function to handle edit functionality
  const handleEdit = (row) => {
    dispatch({
      type: types.PACKAGE_COURSES_FORM_MODEL,
      payload: {
        msg: Constants.editCourses,
        show: true,
        add: 0,
      },
    });
    dispatch({
      type: types.PACKAGE_COURSES_SINGLE,
      payload: row,
    });
  };

  // Columns for package course list
  const columns = [
    {
      name: Constants.name,
      cell: (row) => (
        <Link to="" onClick={() => handleEdit(row)}>
          {row?.course?.name}
        </Link>
      ),
    },
    {
      name: Constants.category,
      cell: (row) => row?.category_id?.name,
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    // Dropdown to edit & delete package course
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  {editPermission && (
                    <div className="list-group">
                      <EditListButton
                        onClick={() => {
                          handleEdit(row);
                        }}
                      />
                    </div>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Delete action dropdown if there is no permission
  if (!editPermission && !deletePermission) {
    const index = columns.findIndex((elt) => elt.name === Constants.actions);
    if (index !== -1) columns.splice(index, 1);
  }

  return (
    <>
      <div className="mt-3">
        <PageHeader
          pagetitle={Constants.coursesList}
          buttonLink={addPermission}
          onClick={(e) => {
            e.preventDefault();
            dispatch({
              type: types.PACKAGE_COURSES_FORM_MODEL,
              payload: { msg: Constants.addCourses, show: true },
            });
          }}
        />
      </div>
      <TableList
        columns={columns}
        data={packageCoursesData}
        defaultSortFieldId
        highlightOnHover
      />
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.PACKAGE_COURSES_SINGLE, payload: "" });
          dispatch({ type: types.PACKAGE_COURSES_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-md"}
        title={popupmodel.msg}
        component={<PackageCourseForm />}
      />
    </>
  );
};

export default PackageCourse;

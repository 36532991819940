import { useDispatch } from "react-redux";
import { Constants } from "../../../Common/Constants";
import PageHeader from "../../../Common/PageHeader";
import PackageForm from "../../PackageForm";
import React, { useEffect } from "react";
import { packagesDetail } from "../../../../Redux/Actions";
import { useParams } from "react-router-dom";

const PackageDetailPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    // Package detail API
    dispatch(packagesDetail(id));
  }, []);

  return (
    <div className="mt-4 course-form card">
      <PageHeader pagetitle={Constants.detail} />
      <PackageForm />
    </div>
  );
};

export default PackageDetailPage;

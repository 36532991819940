import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const welcomeEmailAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.WELCOME_EMAIL, data);
  return response;
};

export const welcomeEmail = async (data) => {
  const url = `${ApiUrl.WELCOME_EMAIL}?${
    data?.type === Constants.package
      ? "package_id"
      : data?.type === Constants.event
      ? "event_id"
      : "course_id"
  }=${data?.id}`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const getImageUrl = async (formData) => {
  const url = ApiUrl.IMAGE_URL;
  const response = await httpServiceInstance.postForm(url, formData);
  return response;
};

import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import TextField from "../../../Common/Fields/TextField";
import { Constants } from "../../../Common/Constants";
import RequiredIcon from "../../../Common/Fields/RequiredIcon";
import * as Yup from "yup";
import ButtonCustom from "../../../Common/Fields/ButtonCustom";
import TextArea from "../../../Common/Fields/TextArea";

import { types } from "../../../../Redux/Actions/types";
import { useParams } from "react-router-dom";
import ViewFile from "../../../Common/Fields/ViewFile";
import {
  resourcesInsert,
  resourcesList,
  resourcesUpdate,
} from "../../../../Redux/Actions/Courses/Resources";
import { lessonsList, modulesList } from "../../../../Redux/Actions";
import getOptionsFromData from "../../../Common/Functions/getOptionsFromData";
import ReactSelect from "../../../Common/Fields/ReactSelect";
import MultiSelect from "../../../Common/Fields/MultiSelect";
import FileAwsUpload from "../../../Common/Fields/FileAwsUpload";
import usePermissions from "../../../Common/Functions/usePermissions";

const ResourceForm = () => {
  const dispatch = useDispatch();
  const [lessonsOptions, setLessonsOptions] = useState([]);
  const [lessonsSelected, setLessonsSelected] = useState([]);
  const [modulesOptions, setModulesOptions] = useState([]);
  const [modulesSelected, setModulesSelected] = useState([]);
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    document: "",
    course: id,
    module_id: "",
    lesson_id: [],
  });
  const { resourcesSingle } = useSelector((state) => state.Resources);
  const { coursesSingle } = useSelector((state) => state.Courses);
  const { lessonslist } = useSelector((state) => state.Lessons);
  const { moduleslist } = useSelector((state) => state.Modules);
  // Permission
  const addPermission = usePermissions(Constants.permission.COURSES_ADD);
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);

  // Validation for resource form
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
    description: Yup.string().required(Constants.required),
    document: Yup.string().required(Constants.required),
    module_id: coursesSingle?.module_lessons
      ? Yup.string().required(Constants.required)
      : Yup.string(),
    lesson_id: Yup.array().min(1, Constants.required),
  });

  // Set the initial values to pre fill the resource form
  useEffect(() => {
    if (resourcesSingle) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (resourcesSingle[key]) {
          editValue[key] = resourcesSingle[key];
          if (key === "module_id") {
            const val = resourcesSingle[key];
            if (val?._id) {
              setModulesSelected({ value: val?._id, label: val?.name });
              dispatch(lessonsList({ id, moduleId: val?._id }));
              editValue[key] = val?._id;
            }
          }
          if (key === "lesson_id") {
            const val = resourcesSingle[key];
            setLessonsSelected(
              val?.map((elt) => {
                return { value: elt?._id, label: elt?.name };
              })
            );
            editValue[key] = val?.map((elt) => elt?._id);
          }
        }
        setInitialValues(editValue);
      }
    }
    return () => {
      dispatch({ type: types.MODULES_LIST, payload: [] });
      dispatch({ type: types.LESSONS_LIST, payload: [] });
    };
  }, [resourcesSingle]);

  // If course's module toggle is on get the  module list, else get the lesson list
  useEffect(() => {
    if (coursesSingle?.module_lessons) {
      dispatch(modulesList({ id, skipPagination: true }));
      setLessonsOptions([]);
    } else {
      dispatch(lessonsList({ id }));
    }
  }, [coursesSingle]);

  // Convert lesson list API data to array of objects (value & label)
  useEffect(() => {
    setLessonsOptions(getOptionsFromData(lessonslist?.data));
  }, [lessonslist?.data]);

  // Convert modules list API data to array of objects (value & label)
  useEffect(() => {
    setModulesOptions(getOptionsFromData(moduleslist?.data));
  }, [moduleslist?.data]);

  // Set document aws url
  const handleFileChange = (file, setFieldValue, field, setLoading) => {
    setFieldValue(field, file?.[0]);
  };

  // handle module change
  const handleOptionChange = (e, setFieldValue, field, setSelected) => {
    setSelected(e);
    setFieldValue(field, e.value);
    dispatch(lessonsList({ id, moduleId: e.value }));
  };

  // handle lesson change
  const handleOptionsChange = (e, setFieldValue, field) => {
    setLessonsSelected(e);
    const ids = e.map((elt) => elt.value);
    setFieldValue(field, ids);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Resource add or edit API
        let submitFunc = resourcesInsert(values);
        if (resourcesSingle?._id) {
          const id = resourcesSingle?._id;
          submitFunc = resourcesUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(resourcesList({ id }));
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.RESOURCES_SINGLE, payload: "" });
            dispatch({ type: types.RESOURCES_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextArea
              type="text"
              name="description"
              className="form-control"
              errors={touched?.description ? errors?.description : undefined}
              touched={touched?.description}
              label={Constants.description}
            />
          </div>
          {coursesSingle?.module_lessons && (
            <div className="col-md-6 mb-4">
              <ReactSelect
                label={Constants.selectModule}
                required={true}
                options={modulesOptions}
                errors={touched?.module_id ? errors?.module_id : undefined}
                touched={touched?.module_id}
                onChange={(selected) =>
                  handleOptionChange(
                    selected,
                    setFieldValue,
                    "module_id",
                    setModulesSelected
                  )
                }
                value={modulesSelected}
              />
            </div>
          )}
          <div className="col-md-6 mb-2">
            <label className="form-label">{`${Constants.selectLessons}`}</label>
            <RequiredIcon />
            <MultiSelect
              key="multi_select"
              options={lessonsOptions}
              onChange={(selected) =>
                handleOptionsChange(selected, setFieldValue, "lesson_id")
              }
              value={lessonsSelected}
              errors={touched?.lesson_id ? errors?.lesson_id : undefined}
              touched={touched?.lesson_id}
              isSelectAll={true}
              menuPlacement={"bottom"}
            />
          </div>
          <div className="col-md-12 mb-4">
            <label className="form-label">{`${Constants.document}`}</label>
            <RequiredIcon />
            <div className="width-outer-up ">
              {values && !values?.document && (
                <FileAwsUpload
                  fileType={[".pdf", ".doc", ".docx", ".ppt", ".pptx", ".xls"]}
                  errors={touched?.document ? errors?.document : undefined}
                  touched={touched?.document}
                  label={`${Constants.upload} ${Constants.document}`}
                  handleFile={(document) =>
                    handleFileChange(document, setFieldValue, "document")
                  }
                  maxFiles={1}
                />
              )}
              {values && values?.document && (
                <ViewFile
                  file={values?.document}
                  onClick={() => setFieldValue("document", "")}
                />
              )}
            </div>
          </div>
          {((addPermission && !resourcesSingle?._id) ||
            (editPermission && resourcesSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ResourceForm;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  roleUsersDelete,
  roleUsersList,
  roleUsersUpdateStatus,
  rolesList,
} from "../../../Redux/Actions";
import { deleteConfirmBox } from "../../Common/Functions/deleteConfirmBox";
import { types } from "../../../Redux/Actions/types";
import { Constants } from "../../Common/Constants";
import responseMethod from "../../Common/Functions/responseMethod";
import dayjs from "dayjs";
import { Dropdown } from "react-bootstrap";
import EditListButton from "../../Common/Fields/EditListButton";
import DeleteListButton from "../../Common/Fields/DeleteListButton";
import ToggleListField from "../../Common/Fields/ToggleListField";
import PageHeader from "../../Common/PageHeader";
import TableList from "../../Common/UiModel/TableList";
import ReactPagination from "../../Common/Fields/ReactPagination";
import ModalPopup from "../../Common/UiModel/ModalPopup";
import RoleUsersForm from "./RoleUsersForm";
import { Link } from "react-router-dom";
import usePermissions from "../../Common/Functions/usePermissions";

const ManageUsers = () => {
  const dispatch = useDispatch();
  const { roleuserslist, popupmodel } = useSelector((state) => state.Roles);
  const [roleUsersData, setRoleUsersData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const addPermission = usePermissions(Constants.permission.ROLE_MGT_ADD);
  const editPermission = usePermissions(Constants.permission.ROLE_MGT_EDIT);
  const deletePermission = usePermissions(Constants.permission.ROLE_MGT_DELETE);

  // Get all the roles, and all the user added for those roles
  useEffect(() => {
    dispatch(roleUsersList());
    dispatch(rolesList());
  }, []);

  useEffect(() => {
    // Set role Users data
    setRoleUsersData(roleuserslist.data);
  }, [roleuserslist]);

  const onDelete = (delete_id) => {
    // Delete role Users
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(roleUsersDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch(roleUsersList());
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  const triggerToggle = (rowData) => {
    // Update status toggle (used in App) for roleUsers
    const itemId = rowData._id;
    const data = {
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(roleUsersUpdateStatus(itemId, data)).then((response) => {
      const tempList = roleUsersData;
      const index = tempList.findIndex((item) => item._id === itemId);
      tempList[index].status = data.status;
      setRoleUsersData(tempList);
      responseMethod(response, dispatch, roleUsersList);
    });
  };

  // Columns to free Video Table List
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.name,
      cell: (row) => <Link onClick={() => onEdit(row)}>{row?.name}</Link>,
    },
    {
      name: Constants.email,
      cell: (row) => (
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.email}
        >
          {row?.email?.slice(0, 15) + "..."}
        </span>
      ),
    },
    {
      name: Constants.number,
      cell: (row) => `${row?.dial_code} ${row?.phone_number}`,
    },
    {
      name: Constants.role,
      cell: (row) => row?.role?.name,
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    // Dropdown to edit , delete the user or change the status of that user
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  {editPermission && (
                    <>
                      <div className="list-group">
                        <EditListButton
                          onClick={() => {
                            onEdit(row);
                          }}
                        />
                      </div>
                      <div className="list-group">
                        <ToggleListField
                          label={Constants.statusKey}
                          onChange={() => {
                            triggerToggle(row);
                          }}
                          checked={row.status === Constants.status.ACTIVE}
                        />
                      </div>
                    </>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // If there is no permission, hide the action button dropdown
  if (!editPermission && !deletePermission) {
    const index = columns.findIndex((elt) => elt.name === Constants.actions);
    if (index !== -1) columns.splice(index, 1);
  }

  // edit the role
  const onEdit = (row) => {
    dispatch({
      type: types.ROLE_USERS_FORM_MODEL,
      payload: {
        msg: Constants.editUser,
        show: true,
        add: 0,
      },
    });
    dispatch({
      type: types.ROLE_USERS_SINGLE,
      payload: row,
    });
  };

  // Pagination function
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
    };
    dispatch(roleUsersList(data));
  };

  return (
    <div className="container-xxl mt-3">
      <PageHeader
        pagetitle={Constants.usersList}
        buttonLink={addPermission}
        onClick={(e) => {
          e.preventDefault();
          dispatch({
            type: types.ROLE_USERS_FORM_MODEL,
            payload: { msg: Constants.addUser, show: true },
          });
        }}
      />
      <TableList
        columns={columns}
        data={roleUsersData}
        defaultSortFieldId
        highlightOnHover
      />
      {roleuserslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={roleuserslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.ROLE_USERS_SINGLE, payload: "" });
          dispatch({ type: types.ROLE_USERS_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-md"}
        title={popupmodel.msg}
        component={<RoleUsersForm />}
      />
    </div>
  );
};

export default ManageUsers;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../Common/Constants";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import { Link, useNavigate } from "react-router-dom";
import { RoutesUrl } from "../Common/RoutesUrl";
import ReactPagination from "../Common/Fields/ReactPagination";
import { usersList } from "../../Redux/Actions";
import ReactSelect from "react-select";
import { Button, Dropdown } from "react-bootstrap";
import ViewListButton from "../Common/Fields/ViewListButton";
import DateRangePicker from "../Common/Fields/DateRangePicker";
import dayjs from "dayjs";
import { types } from "../../Redux/Actions/types";
import saveExportedFile from "../Common/Functions/saveExportedFile";

const initDate = {
  startDate: new Date(),
  endDate: new Date(),
  key: "selection",
};

const KycRequestList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [initialDate, setInitialDate] = useState(initDate);
  const { userslist } = useSelector((state) => state.Users);
  const [usersData, setUsersData] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState({ value: null, label: "All" });
  const [pageIndex, setPageIndex] = useState(1);
  const [showCalender, setShowCalender] = useState(false);
  const [range, setRange] = useState({});

  useEffect(() => {
    // Users & Goals list API
    dispatch(usersList({ kyc_request: true }));
  }, []);

  useEffect(() => {
    // Set user list data
    setUsersData(userslist.data);
  }, [userslist]);

  // Debounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  // Method to hit user list API
  const getData = (searchKey) => {
    const data = {
      search: searchKey || "",
      kyc_request: true,
      skip: 0,
      limit: Constants.limitCount,
      status: status.value,
    };
    dispatch(usersList(data));
  };

  // Columns for User list
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link to={`${RoutesUrl.kyc}/${row?._id}`}>
          {row?.name || Constants.na}
        </Link>
      ),
    },
    {
      name: Constants.email,
      cell: (row) => row?.email || Constants.na,
    },
    {
      name: Constants.aadhaarNumber,
      cell: (row) => row?.aadhaar_card_number || Constants.na,
    },
    {
      name: Constants.panNumber,
      cell: (row) => row?.pan_card_number || Constants.na,
    },
    {
      name: Constants.statusKey,
      cell: (row) => {
        const type = Constants.kycRequests.find(
          (elt) => elt.value === row?.kyc_status
        );
        return type?.label || Constants.na;
      },
    },
    // Dropdwn to view kyc detail
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <ViewListButton
                      onClick={() => {
                        navigate(`${RoutesUrl.kyc}/${row?._id}`);
                      }}
                    />
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Pagination function
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      kyc_request: true,
      search,
    };
    dispatch(usersList(data));
  };

  // Function to filter status
  const onChangeStatus = (e) => {
    setStatus(e);
    dispatch(
      usersList({
        skip: 0,
        limit: Constants.limitCount,
        status: e.value,
        search,
        kyc_request: true,
      })
    );
  };

  // Function to reset all filters
  const onResetFilter = () => {
    setShowCalender(false);
    setInitialDate(initDate);
    setRange({});
    setSearch("");
    setStatus({
      value: null,
      label: "All",
    });
    dispatch(
      usersList({
        skip: 0,
        limit: Constants.limitCount,
        status: null,
        kyc_request: true,
      })
    );
  };

  // On range change
  const onRangeChange = (range) => {
    setInitialDate({
      startDate: range?.[0]?.startDate,
      endDate: range?.[0]?.endDate,
      key: "selection",
    });
    setRange({
      start_date: dayjs(range?.[0]?.startDate).format("YYYY-MM-DD"),
      end_date: dayjs(range?.[0]?.endDate).format("YYYY-MM-DD"),
    });
  };

  // Date range filter
  const onDateFilter = () => {
    setShowCalender(false);
    const data = {
      skip: 0,
      limit: Constants.limitCount,
      search,
      start_date: range?.start_date,
      end_date: range?.end_date,
      status: status.value,
      kyc_request: true,
    };
    dispatch(usersList(data));
  };

  // Function to export the kyc users
  const exportKycUserList = () => {
    const data = {
      skipPagination: true,
      search,
      start_date: range?.start_date,
      end_date: range?.end_date,
      status: status.value,
      kyc_request: true,
    };
    dispatch(usersList(data)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        handleDownloadClick(response?.data?.data);
      }
    });
  };

  // Function to handle the download click for exported CSV data
  const handleDownloadClick = (kycList) => {
    const data = kycList?.map((element) => {
      return {
        [Constants.name]: element?.name || Constants.na,
        [Constants.email]: element?.email || Constants.na,
        [Constants.aadhaarNumber]: element?.aadhaar_card_number || Constants.na,
        [Constants.panNumber]: element?.pan_card_number || Constants.na,
        [Constants.statusKey]:
          Constants.kycRequests.find((elt) => elt.value === element?.kyc_status)
            ?.label || Constants.na,
      };
    });
    const csvData = saveExportedFile(Constants.customer, dispatch, data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", Constants.csvKycRequests);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="container-xxl">
      <div className="mt-2">
        <PageHeader
          pagetitle={Constants.kycRequestList}
          buttonLink={usersData?.length > 0}
          buttonName={Constants.export}
          onClick={() => exportKycUserList()}
        />
      </div>
      <div className="row mb-4 align-items-end position-relative justify-content-end">
        <div
          className={`calender-outer position-initial ${
            range?.start_date ? "calender-active" : ""
          }`}
        >
          <i
            style={{
              cursor: "pointer",
              fontSize: "25px",
            }}
            className="fa fa-calendar"
            aria-hidden="true"
            onClick={() => {
              setShowCalender((prevState) => !prevState);
            }}
          ></i>
          {showCalender && (
            <div className="date-pos">
              <div>
                <DateRangePicker
                  initialDate={initialDate}
                  rangeSelected={(range) => {
                    onRangeChange(range);
                  }}
                />
              </div>
              <div className="appy-btn">
                <Button
                  className="text-uppercase fw-bold text-center mx-auto d-block"
                  onClick={() => {
                    onDateFilter();
                  }}
                >
                  {Constants.apply}
                </Button>
              </div>
            </div>
          )}
        </div>
        {/* Payment Status filter */}
        <div className="col-md-3">
          <label className="form-label">{`${Constants.select} ${Constants.statusKey}`}</label>
          <ReactSelect
            value={status}
            placeholder="Status"
            isSearchable={false}
            onChange={(e) => onChangeStatus(e)}
            options={Constants.kycRequests}
          />
        </div>
        {/* Search filter */}
        <div className="col-md-3">
          <label className="form-label">{`${Constants.search}`}</label>
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByNameEmailPanAadhaar}
            onChange={(event) => {
              setSearch(event.target.value);
              if (event.target.value === "") getData();
            }}
          ></input>
        </div>
        {/* Reset */}
        <div className="col-md-1 reset-btn">
          <button
            className="btn btn-primary text-uppercase px-4  cross-reset p-right fw-bold"
            onClick={() => onResetFilter()}
          >
            {Constants.reset}
          </button>
        </div>
      </div>
      <TableList
        columns={columns}
        data={usersData}
        defaultSortFieldId
        highlightOnHover
      />
      {userslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={userslist?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
    </div>
  );
};

export default KycRequestList;

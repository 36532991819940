import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const modulesAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.MODULES, data);
  return response;
};

export const modulesEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.MODULES + "?id=" + id,
    data
  );
  return response;
};

export const modulesRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.MODULES + "?id=" + id
  );
  return response;
};

export const modulesStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.MODULES_STATUS_UPDATE + "?id=" + id,
    data
  );
  return response;
};

export const modules = async (data) => {
  const url = `${ApiUrl.MODULES}?course_id=${data?.id}&${
    data?.skipPagination
      ? Constants.paginationFalse
      : `skip=${data?.skip || 0}&limit=${data?.limit || Constants.limitCount}`
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};

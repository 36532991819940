import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Constants } from "../../../Common/Constants";
import PageHeader from "../../../Common/PageHeader";
import TableList from "../../../Common/UiModel/TableList";
import { bannersUsers } from "../../../../Redux/Actions";
import dayjs from "dayjs";
import { RoutesUrl } from "../../../Common/RoutesUrl";
import secondsToHour from "../../../Common/Functions/secondsToHour";
import ReactPagination from "../../../Common/Fields/ReactPagination";
import ViewListButton from "../../../Common/Fields/ViewListButton";
import usePermissions from "../../../Common/Functions/usePermissions";

const Users = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bannersSingle, bannersuserslist } = useSelector(
    (state) => state.Banners
  );
  const [bannerUsersData, setBannersUsersData] = useState([]);
  const [activeCellId, setActiveCellId] = useState(null);
  const userViewPermission = usePermissions(Constants.permission.USER_VIEW);

  useEffect(() => {
    // Users list API
    if (bannersSingle?.redirection_type) {
      const data = {
        skip: 0,
        limit: Constants.limitCount,
        id,
        type: bannersSingle?.redirection_type,
      };
      dispatch(bannersUsers(data));
    }
  }, [bannersSingle]);

  useEffect(() => {
    // Set users data
    setBannersUsersData(bannersuserslist);
  }, [bannersuserslist]);

  // Columns for User Table List
  const columns = [
    {
      name: Constants.name,
      cell: (row) => (
        <Link to={userViewPermission ? `${RoutesUrl.users}/${row?._id}` : ""}>
          {row?.name || Constants.na}
        </Link>
      ),
    },
    {
      name: Constants.email,
      cell: (row) => row?.email || Constants.na,
    },
    {
      name: Constants.responseDate,
      cell: (row) => dayjs(row?.registered_date).format(Constants.dateFormat),
    },
    {
      name: Constants.timeSpent,
      cell: (row) =>
        row?.time_spent_on_video ? secondsToHour(row?.time_spent_on_video) : 0,
    },
  ];

  if (bannersSingle?.redirection_type === Constants.formKey) {
    // For form redirection type users, view action
    columns.push({
      name: Constants.actions,
      cell: (row) => (
        // Actions for category
        <div
          className="switch-tn2 position-relative btn-h"
          onBlur={() => setActiveCellId(null)}
          tabIndex="0"
        >
          <i
            className="fa fa-ellipsis-h ellips-t cursor-pointer"
            onClick={() => setActiveCellId(row._id)}
            aria-hidden="true"
          ></i>
          <div className="outer-dot">
            <ul
              className={`nav ${
                activeCellId === row._id ? "d-block" : "d-none"
              } d-block btn-lists`}
            >
              <li>
                <ViewListButton
                  onClick={() => {
                    navigate(
                      `${RoutesUrl.banners}/${Constants.userFormResponseKey}/${id}/${row?._id}`
                    );
                  }}
                />
              </li>
            </ul>
          </div>
        </div>
      ),
    });
    // Remove time spent column for form redirection type
    const index = columns.findIndex((elt) => elt.name === Constants.timeSpent);
    if (index !== -1) columns.splice(index, 1);
  }

  // Banner users based on pagination
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      id,
      type: bannersSingle?.redirection_type,
    };
    dispatch(bannersUsers(data));
  };

  return (
    <>
      <div className="mt-3">
        <PageHeader pagetitle={Constants.responseList} />
      </div>
      <TableList
        columns={columns}
        data={bannerUsersData}
        defaultSortFieldId
        highlightOnHover
      />
      {bannersuserslist?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={bannersuserslist?.total}
          newOffset={handleOffset}
        />
      )}
    </>
  );
};

export default Users;

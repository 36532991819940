import React, { useEffect, useState } from "react";
import {
  getTransactionsList,
  transactionsListExport,
} from "../../Redux/Actions/Transactions";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../Common/PageHeader";
import { Constants } from "../Common/Constants";
import TableList from "../Common/UiModel/TableList";
import { Link, useNavigate } from "react-router-dom";
import { RoutesUrl } from "../Common/RoutesUrl";
import dayjs from "dayjs";
import ReactPagination from "../Common/Fields/ReactPagination";
import { types } from "../../Redux/Actions/types";
import { Button, Dropdown } from "react-bootstrap";
import DateRangePicker from "../Common/Fields/DateRangePicker";
import ReactSelect from "react-select";
import MultiSelect from "../Common/Fields/MultiSelect";
import ViewListButton from "../Common/Fields/ViewListButton";
import saveExportedFile from "../Common/Functions/saveExportedFile";
import usePermissions from "../Common/Functions/usePermissions";

const initDate = {
  startDate: new Date(),
  endDate: new Date(),
  key: "selection",
};

const Transactions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { transactionsList } = useSelector((state) => state.Transactions);
  const [transactionData, setTransactionData] = useState([]);
  const [showCalender, setShowCalender] = useState(false);
  const [initialDate, setInitialDate] = useState(initDate);
  const [range, setRange] = useState({});
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState([]);
  const [status, setStatus] = useState({ value: null, label: "All" });
  const [pageIndex, setPageIndex] = useState(1);
  const userViewPermission = usePermissions(Constants.permission.USER_VIEW);

  useEffect(() => {
    // Transaction list API
    dispatch(getTransactionsList());
  }, []);

  useEffect(() => {
    // Set transaction list data
    setTransactionData(transactionsList?.data);
  }, [transactionsList]);

  // Debounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  // Columns for transaction list
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.transactionId,
      cell: (row) => (
        <Link to={`${RoutesUrl.transactions}/${row?._id}`}>
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.transaction_id}
          >
            {row?.transaction_id?.slice(0, 10) +
              `${row?.transaction_id?.length > 10 ? "..." : ""}`}
          </span>
        </Link>
      ),
    },
    {
      name: Constants.userName,
      cell: (row) => (
        <Link
          onClick={() => {
            if (userViewPermission)
              window.open(`${RoutesUrl.users}/${row?.user_id?._id}`, "_blank");
          }}
        >
          {row?.user_id?.name || Constants.na}
        </Link>
      ),
    },
    {
      name: Constants.date,
      cell: (row) => dayjs(row?.transaction_date).format(Constants.dateFormat),
    },
    {
      name: Constants.email,
      cell: (row) => row?.user_id?.email || Constants.na,
    },
    {
      name: Constants.amount,
      cell: (row) => row?.payment_amount || 0,
    },
    {
      name: `${Constants.refunded} ${Constants.amount}`,
      cell: (row) =>
        row?.refunded_amount ? (row?.refunded_amount / 100).toFixed(2) : 0,
    },
    {
      name: Constants.paymentStatus,
      cell: (row) => {
        const status = Constants.transactionFilterStatus.find(
          (elt) => elt.value === row?.payment_status
        );
        const val = status?.value;
        return (
          <div
            className={`payment-status-badges ${
              val === "captured" || val === "authorized"
                ? "paid"
                : val === "failed"
                ? "failed"
                : val === "refunded"
                ? "refunded"
                : val === "processed"
                ? "refund-int"
                : val === "refund_requested"
                ? "refund-request"
                : val === "Imported"
                ? "imported"
                : ""
            }`}
          >
            {status?.label}
          </div>
        );
      },
    },
    // Dropdown to view the detailed transaction
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  <div className="list-group">
                    <ViewListButton
                      onClick={() => {
                        navigate(`${RoutesUrl.transactions}/${row?._id}`);
                      }}
                    />
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Function to handle pagination offset and fetch transactions list
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      payment_status: status?.value,
      search,
      start_date: range?.start_date,
      end_date: range?.end_date,
      payment_type:
        filters?.length > 0 ? filters?.map((elt) => elt?.value) : null,
    };
    dispatch(getTransactionsList(data));
  };

  // Function to export transaction list to CSV format
  const exportTransactionList = () => {
    const data = {
      skipPagination: true,
      search,
      payment_status: status.value,
      start_date: range?.start_date,
      end_date: range?.end_date,
      payment_type:
        filters?.length > 0 ? filters?.map((elt) => elt?.value) : null,
    };
    dispatch(transactionsListExport(data)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        handleDownloadClick(response?.data?.data);
      }
    });
  };

  // Function to handle the download click for exported CSV data
  const handleDownloadClick = (transactionList) => {
    const data = transactionList?.map((element) => {
      const type =
        Constants.transactionPaymentType.find(
          (elt) => elt.value === element?.enrollment_type
        )?.label || Constants.na;
      const status =
        Constants.transactionFilterStatus.find(
          (elt) => elt.value === element?.payment_status
        )?.label || Constants.na;
      return {
        [Constants.transactionId]: element.transaction_id,
        [Constants.userName]: element.user_id?.name || Constants.na,
        [Constants.transactionDate]: dayjs(element?.createdAt).format(
          Constants.dateFormat
        ),
        [Constants.email]: element?.user_id?.email,
        [Constants.type]: type,
        [Constants.amount]: element?.payment_amount,
        [Constants.refundedAmount]: element?.refunded_amount
          ? (element?.refunded_amount / 100).toFixed(2)
          : Constants.na,
        [Constants.statusKey]: status,
      };
    });
    const csvData = saveExportedFile(Constants.transactions, dispatch, data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", Constants.csvTransaction);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Function to handle date range change for filtering transactions
  const onRangeChange = (range) => {
    setInitialDate({
      startDate: range?.[0]?.startDate,
      endDate: range?.[0]?.endDate,
      key: "selection",
    });
    setRange({
      start_date: dayjs(range?.[0]?.startDate).format("YYYY-MM-DD"),
      end_date: dayjs(range?.[0]?.endDate).format("YYYY-MM-DD"),
    });
  };

  // Function to apply date filter
  const onDateFilter = () => {
    setShowCalender(false);
    const data = {
      skip: 0,
      limit: Constants.limitCount,
      search,
      start_date: range?.start_date,
      end_date: range?.end_date,
      payment_type:
        filters?.length > 0 ? filters?.map((elt) => elt?.value) : null,
    };
    dispatch(getTransactionsList(data));
  };

  // Function to handle status change in filter
  const onChangePaymentStatus = (e) => {
    setStatus(e);
    dispatch(
      getTransactionsList({
        skip: 0,
        limit: Constants.limitCount,
        payment_status: e.value,
        search,
        start_date: range?.start_date,
        end_date: range?.end_date,
        payment_type:
          filters?.length > 0 ? filters?.map((elt) => elt?.value) : null,
      })
    );
  };

  // Function to handle type filter change in filter
  const onChangePaymentType = (e) => {
    setFilters(e);
    const data = {
      skip: 0,
      limit: Constants.limitCount,
      search,
      start_date: range?.start_date,
      end_date: range?.end_date,
      payment_status: status.value,
      payment_type: e?.map((elt) => elt?.value),
    };
    dispatch(getTransactionsList(data));
  };

  // Function to fetch transactions based on search term and other filters
  const getData = (searchTerm) => {
    const data = {
      limit: Constants.limitCount,
      skip: 0,
      search: searchTerm || "",
      payment_status: status.value,
      start_date: range?.start_date,
      end_date: range?.end_date,
      payment_type:
        filters?.length > 0 ? filters?.map((elt) => elt?.value) : null,
    };
    dispatch(getTransactionsList(data));
  };

  // Function to reset all filters
  const onResetFilter = () => {
    setShowCalender(false);
    setInitialDate(initDate);
    setRange({});
    setSearch("");
    setStatus({
      value: null,
      label: "All",
    });
    setFilters([]);
    dispatch(
      getTransactionsList({
        skip: 0,
        limit: Constants.limitCount,
        payment_status: null,
      })
    );
  };

  return (
    <>
      <div className="container-xxl">
        <div className="mt-2">
          <PageHeader
            pagetitle={Constants.transactionList}
            buttonLink={transactionData?.length > 0}
            buttonName={Constants.export}
            onClick={() => exportTransactionList()}
          />
        </div>
        <div className="flex-header-search ">
          <div className="search-w space-calender position-relative">
            <div
              className={`calender-outer ${
                range?.start_date ? "calender-active" : ""
              }`}
            >
              <i
                style={{
                  cursor: "pointer",
                  fontSize: "25px",
                }}
                className="fa fa-calendar"
                aria-hidden="true"
                onClick={() => {
                  setShowCalender((prevState) => !prevState);
                }}
              ></i>
            </div>
            <div className="row mb-4 ">
              {/* Date filter */}
              {showCalender && (
                <div className="date-pos">
                  <div>
                    <DateRangePicker
                      initialDate={initialDate}
                      rangeSelected={(range) => {
                        onRangeChange(range);
                      }}
                    />
                  </div>
                  <div className="appy-btn">
                    <Button
                      className="text-uppercase fw-bold text-center mx-auto d-block"
                      onClick={() => {
                        onDateFilter();
                      }}
                    >
                      {Constants.apply}
                    </Button>
                  </div>
                </div>
              )}
              {/* Payment Status filter */}
              <div className="col-md-4">
                <label className="form-label">{`${Constants.select} ${Constants.paymentStatus}`}</label>
                <ReactSelect
                  value={status}
                  placeholder="Status"
                  isSearchable={false}
                  onChange={(e) => onChangePaymentStatus(e)}
                  options={Constants.transactionFilterStatus}
                />
              </div>
              {/* Payment Type filter */}
              <div className="col-md-4">
                <label className="form-label">{`${Constants.select} ${Constants.paymentType}`}</label>
                <MultiSelect
                  isMulti={true}
                  value={filters}
                  menuPlacement={"bottom"}
                  isSelectAll={true}
                  options={Constants.transactionPaymentType}
                  onChange={(e) => {
                    onChangePaymentType(e);
                  }}
                />
              </div>
              {/* Search filter */}
              <div className="col-md-4">
                <label className="form-label">{`${Constants.search}`}</label>
                <input
                  className="px-2 filter-sec-trans"
                  type="search"
                  value={search}
                  placeholder={Constants.searchByNameEmail}
                  onChange={(event) => {
                    setSearch(event.target.value);
                    if (event.target.value === "") getData();
                  }}
                ></input>
              </div>
            </div>
          </div>
          {/* Reset */}
          <div className="bnt-filters mt-1">
            <button
              className="btn btn-primary text-uppercase px-4  cross-reset p-right fw-bold reset-btn"
              onClick={() => onResetFilter()}
            >
              {Constants.reset}
            </button>
          </div>
        </div>
        {/* Transaction Table Listing */}
        <TableList
          columns={columns}
          data={transactionData}
          defaultSortFieldId
          highlightOnHover
        />
      </div>
      {transactionsList?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={transactionsList?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
    </>
  );
};

export default Transactions;

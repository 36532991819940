import { types } from "../../Actions/types";

const initialState = {
  courseslist: [],
  coursescategorylist: [],
  coursesOptionsSingle: [],
  error: null,
  coursesSingle: "",
  show: false,
  popupmodel: "",
};

export default function Courses (state = initialState, action) {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }
  switch (type) {
    case types.COURSES_LIST:
      return {
        ...state,
        courseslist: payload,
        error,
      };
    case types.COURSES_CAT_LIST:
      return {
        ...state,
        coursescategorylist: payload,
        error,
      };
    case types.COURSES_SINGLE:
      return {
        ...state,
        coursesSingle: payload,
        error,
      };
    case types.COURSES_OPTIONS_SINGLE:
      return {
        ...state,
        coursesOptionsSingle: payload,
        error,
      };
    case types.COURSES_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
}

import React from "react";
import { Link } from "react-router-dom";
import { Constants } from "../Constants";

// BackButton component receives a prop named 'list'
const BackButton = ({ list }) => {
  return (
    // Link component from react-router-dom is used to create a navigation link
    <Link to={list} title="" className="btn btn-back">
      <i className="fa fa-chevron-left "></i>
      {Constants.back}
    </Link>
  );
};

export default BackButton;

import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import RequiredIcon from "../../Common/Fields/RequiredIcon";
import TextField from "../../Common/Fields/TextField";
import { Constants } from "../../Common/Constants";
import ButtonCustom from "../../Common/Fields/ButtonCustom";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { goalsInsert, goalsList, goalsUpdate } from "../../../Redux/Actions";
import { types } from "../../../Redux/Actions/types";
import ViewImageFile from "../../Common/Fields/ViewImageFile";
import FileAwsUpload from "../../Common/Fields/FileAwsUpload";
import usePermissions from "../../Common/Functions/usePermissions";

const FaqForm = () => {
  const dispatch = useDispatch();
  const { goalSingle } = useSelector((state) => state.Goals);
  // Initial values to Goal form
  const [initialValues, setInitialValues] = useState({
    name: "",
    icon: "",
  });
  const addPermission = usePermissions(Constants.permission.CONFG_ADD);
  const editPermission = usePermissions(Constants.permission.CONFG_EDIT);

  // Validations for Goal form
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
    icon: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    // Set initial values to pre fill goal form
    if (goalSingle) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (goalSingle[key]) {
          editValue[key] = goalSingle[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [goalSingle]);

  // set goal aws url
  const handleFileChange = (file, setFieldValue, field) => {
    setFieldValue(field, file?.[0]);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update Goal API
        let submitFunc = goalsInsert(values);
        if (goalSingle._id) {
          const id = goalSingle._id;
          submitFunc = goalsUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response.data.message,
            });
            dispatch({ type: types.GOALS_SINGLE, payload: "" });
            dispatch({ type: types.GOALS_FORM_MODEL, payload: "" });
          }
          dispatch(goalsList());
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <div className="col-md-12 mb-2">
            <label className="form-label">{`${Constants.imageSvg}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up contain-ic">
                {values && !values?.icon && (
                  <FileAwsUpload
                    fileType={[".svg"]}
                    errors={touched?.icon ? errors?.icon : undefined}
                    touched={touched?.icon}
                    label={`${Constants.upload} ${Constants.imageSvg}`}
                    handleFile={(icon) =>
                      handleFileChange(icon, setFieldValue, "icon")
                    }
                    maxFiles={1}
                  />
                )}
                {values && values?.icon && (
                  <ViewImageFile
                    file={values?.icon}
                    onClick={() => setFieldValue("icon", "")}
                  />
                )}
              </div>
            </div>
          </div>
          {((addPermission && !goalSingle?._id) ||
            (editPermission && goalSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default FaqForm;

import { types } from "../../Actions/types";

const initialState = {
  welcomeEmaillist: [],
  error: null,
};

export default function WelcomeEmail (state = initialState, action) {
  let { type, payload, error } = action;
  if (payload && payload.error !== null) {
    error = payload.error;
  }

  switch (type) {
    case types.WELCOME_EMAIL_LIST:
      return {
        ...state,
        welcomeEmaillist: payload,
        error,
      };

    default:
      return state;
  }
}

import React, { useState } from "react";
import { Constants } from "../../../Common/Constants";
import Dropzone from "react-dropzone";
import { Modal } from "react-bootstrap";
import { ApiUrl } from "../../../Common/ApiUrl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  enrolledUsersList,
  importCourseUsers,
} from "../../../../Redux/Actions";
import { types } from "../../../../Redux/Actions/types";

const ImportUsers = (props) => {
  const [file, setFile] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();

  // download the sample excel file for import
  const downloadFile = () => {
    const url = `${process.env.REACT_APP_API_STAGING_URL}${ApiUrl.COURSE_USERS_SAMPLE_EXCEL}`;
    const link = document.createElement("a");
    const fileName = "file.extension";
    link.setAttribute("download", fileName);
    link.setAttribute("target", "_self");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  // Method to remove the uploaded file from drop area
  const removeFile = (fileName) => () => {
    const newFiles = [...file];
    newFiles.splice(newFiles.indexOf(fileName), 1);
    setFile(newFiles);
  };

  const files = file.map((file) => (
    <li key={file.path}>
      {`${file.path} - ${file.size} ${Constants.bytes}`}
      <button
        style={{ color: "red", border: "none", backgroundColor: "#fff" }}
        onClick={removeFile(file)}
      >
        <i className="icofont-trash"></i>
      </button>
    </li>
  ));

  // Upload the excel file to import the users
  const handleUploadExcel = () => {
    const formData = new FormData();
    formData.append("file", file?.[0]);
    formData.append("course_id", id);
    dispatch(importCourseUsers(formData)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        props.onHide();
        setFile([]);
        const data = {
          skip: 0,
          limit: Constants.limitCount,
          id,
          type: Constants.course,
        };
        dispatch(enrolledUsersList(data));
        dispatch({
          type: types.SUCCESS_ALERT_VISIBLE,
          payload: response.data.message,
        });
      }
    });
  };

  return (
    <Modal
      show={props.show}
      className={`${props.dialogClassName} modal-popup`}
      onHide={props.onHide}
      backdrop="static"
    >
      <Modal.Header className="modal-header add_food_item">
        <div className="btns-flex">
          <h4 className="modal-title fw-bold" id="expeditLabel">
            {Constants.importUsers}
          </h4>
          <div className="btn-hover">
            <button
              style={{ cursor: "pointer" }}
              onClick={() => downloadFile()}
              className="fw-bold btn btn-primary"
            >
              {Constants.downloadSample}
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="modal-body min-body-h">
        <div className="deadline-form">
          <Dropzone
            maxFiles={1}
            onDrop={(acceptedFiles) => {
              // Filter out files that are not .xlsx
              const xlsxFiles = acceptedFiles.filter(
                (file) =>
                  file.type ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              );
              setFile(xlsxFiles);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="">
                <div className="out-cr">
                  <div className="width-outer-up">
                    <div className="body d-flex py-3 design-popup cursor-pointer">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <i className="fa fa-upload upload-ic"></i>

                        <p className="mb-2">{Constants.dragAndDropOrClick}</p>
                      </div>
                      <aside>
                        <ul>{files.at(-1)}</ul>
                      </aside>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer btn-hover">
        <button className="mr-2 btn btn-primary grey-bg" onClick={props.onHide}>
          {Constants.close}
        </button>
        <button
          className="mr-2 btn btn-primary"
          disabled={file?.length === 0}
          onClick={() => handleUploadExcel()}
        >
          {Constants.upload}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportUsers;

import {
  coursesFaqs,
  coursesFaqsAdd,
  coursesFaqsEdit,
  coursesFaqsRemove,
  coursesFaqsStatusUpdate,
} from "../../../services/Courses/faqs.service";
import { types } from "../types";

export const coursesFaqsInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await coursesFaqsAdd(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const coursesFaqsList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await coursesFaqs(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.COURSES_FAQS_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.COURSES_FAQS_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const coursesFaqsUpdate = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await coursesFaqsEdit(id, data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const coursesFaqsDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await coursesFaqsRemove(id);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const coursesFaqsUpdateStatus = (id, data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await coursesFaqsStatusUpdate(id, data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

import { types } from "../Actions/types";

const initialState = {
  userslist: [],
  usercommentslist: [],
  usersSingle: "",
  usercommentsSingle: "",
  popupmodel: "",
};

export default function Users (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.USERS_LIST:
      return {
        ...state,
        userslist: payload,
      };
    case types.USERS_SINGLE:
      return {
        ...state,
        usersSingle: payload,
      };
    case types.USER_COMMENTS_LIST:
      return {
        ...state,
        usercommentslist: payload,
      };
    case types.USER_COMMENTS_SINGLE:
      return {
        ...state,
        usercommentsSingle: payload,
      };
    case types.USER_COMMENTS_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };

    default:
      return state;
  }
}

import {
  welcomeEmail,
  welcomeEmailAdd,
} from "../../../services/Courses/welcomeEmail.service";
import { types } from "../types";

export const welcomeEmailInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await welcomeEmailAdd(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const welcomeEmailList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await welcomeEmail(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.WELCOME_EMAIL_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.WELCOME_EMAIL_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWithdrawRequests } from "../../Redux/Actions/Withdraw";
import { Constants } from "../Common/Constants";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import ReactPagination from "../Common/Fields/ReactPagination";

const WithdrawList = () => {
  const dispatch = useDispatch();
  const { withdrawRequestsList } = useSelector((state) => state.Withdraw);
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [withdrawData, setWithdrawData] = useState([]);

  // to get all the withdraw requests from users
  useEffect(() => {
    dispatch(getWithdrawRequests());
  }, []);

  useEffect(() => {
    // Set withdraw request list data
    setWithdrawData(withdrawRequestsList?.data);
  }, [withdrawRequestsList]);

  // debounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  // withdraw reuqests data API
  const getData = (searchTerm) => {
    const data = {
      limit: Constants.limitCount,
      skip: 0,
      search: searchTerm || "",
    };
    dispatch(getWithdrawRequests(data));
  };

  // columns for withdraw list
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
  ];

  // pagination function
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      search,
    };
    dispatch(getWithdrawRequests(data));
  };

  return (
    <>
      <div className="container-xxl">
        <div className="mt-2">
          <PageHeader pagetitle={Constants.withdrawList} />
        </div>
        <div className="row mb-4 align-items-end position-relative justify-content-end">
          {/* Search filter */}
          <div className="col-md-4">
            <input
              className="px-2 filter-sec-trans"
              type="search"
              value={search}
              placeholder={Constants.searchByName}
              onChange={(event) => {
                setSearch(event.target.value);
                if (event.target.value === "") getData();
              }}
            ></input>
          </div>
        </div>
        {/* Withdraw requests Table Listing */}
        <TableList
          columns={columns}
          data={withdrawData}
          defaultSortFieldId
          highlightOnHover
        />
      </div>
      {withdrawRequestsList?.total > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={withdrawRequestsList?.total}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
        />
      )}
    </>
  );
};

export default WithdrawList;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Constants } from "../Common/Constants";
import { Formik, Form } from "formik";
import {
  bannersInsert,
  bannersList,
  bannersUpdate,
  coursesList,
  eventsList,
  packagesList,
} from "../../Redux/Actions";
import { types } from "../../Redux/Actions/types";
import TextField from "../Common/Fields/TextField";
import RequiredIcon from "../Common/Fields/RequiredIcon";
import ViewImageFile from "../Common/Fields/ViewImageFile";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import RadioButton from "../Common/Fields/RadioButton";
import ToggleField from "../Common/Fields/ToggleField";
import ViewVideoFile from "../Common/Fields/ViewVideoFile";
import { RoutesUrl } from "../Common/RoutesUrl";
import FileAwsUpload from "../Common/Fields/FileAwsUpload";
import VideoTusUpload from "../Common/Fields/VideoTusUpload";
import VideoDuration from "../Common/Fields/VideoDuration";
import ReactSelect from "../Common/Fields/ReactSelect";
import getOptionsFromData from "../Common/Functions/getOptionsFromData";
import usePermissions from "../Common/Functions/usePermissions";

const BannerForm = () => {
  const dispatch = useDispatch();
  const [videoFree, setVideoFree] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [redirectionType, setRedirectionType] = useState(
    Constants.redirectionTypeOptions?.[0]
  );
  const [selectedType, setSelectedType] = useState(
    Constants.typeCoursePckgOptions[0]
  );
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [packageOptions, setPackageOptions] = useState([]);
  const [eventOptions, setEventOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [selectedTypeOption, setSelectedTypeOption] = useState("");
  const { bannersSingle } = useSelector((state) => state.Banners);
  const { courseslist } = useSelector((state) => state.Courses);
  const { packageslist } = useSelector((state) => state.Packages);
  const { eventslist } = useSelector((state) => state.Events);
  const addPermission = usePermissions(Constants.permission.BANNER_ADD);
  const editPermission = usePermissions(Constants.permission.BANNER_EDIT);

  // Initial values for banner form
  const [initialValues, setInitialValues] = useState({
    name: "",
    image: "",
    image_web: "",
    redirection_type: Constants.redirectionTypeOptions?.[0]?.key,
    video: "",
    video_thumbnail: "",
    is_video_free: false,
    is_one_time_playable: false,
    video_price: null,
    duration: 45,
    image_type: 0,
    course_id: "",
    package_id: "",
    event_id: "",
  });

  // Validation for banner form
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
    image: Yup.string().required(Constants.required),
    image_web: Yup.string().required(Constants.required),
    video:
      redirectionType?.key === Constants?.videoKey
        ? Yup.string().required(Constants.required)
        : "",
    video_thumbnail:
      redirectionType?.key === Constants?.videoKey
        ? Yup.string().required(Constants.required)
        : "",
    video_price:
      redirectionType?.key === Constants?.videoKey && !videoFree
        ? Yup.string()
            .required(Constants.required)
            .matches(Constants.invalidPriceRegex, Constants.invalidPrice)
        : "",
    course_id:
      redirectionType?.key === Constants?.imageKey
        ? Yup.string().required(Constants.required)
        : Yup.string(),
  });

  // Set Courses Options in value, label format
  useEffect(() => {
    const data = getOptionsFromData(courseslist?.data);
    setCoursesOptions(data);
    if (!bannersSingle?._id) setTypeOptions(data);
  }, [courseslist?.data]);

  // Set package Options in value, label format
  useEffect(() => {
    setPackageOptions(getOptionsFromData(packageslist?.data));
  }, [packageslist?.data]);

  // Set event Options in value, label format
  useEffect(() => {
    setEventOptions(getOptionsFromData(eventslist?.data));
  }, [eventslist?.data]);

  // Set the banener type based on selected type
  useEffect(() => {
    selectedType?.value === Constants.course
      ? setTypeOptions(coursesOptions)
      : selectedType?.value === Constants.event
      ? setTypeOptions(eventOptions)
      : setTypeOptions(packageOptions);
  }, [selectedType]);

  useEffect(() => {
    // Set initial values for pre-filled form
    if (bannersSingle?._id) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        editValue[key] = bannersSingle[key];
        if (key === "redirection_type") {
          const val = Constants.redirectionTypeOptions.find(
            (elt) => elt.key === bannersSingle[key]
          );
          // If redirection type is image then we need all list of packages, course  & events
          if (val.key === Constants.imageKey) {
            dispatch(coursesList({ skipPagination: true }));
            dispatch(packagesList({ skipPagination: true }));
            dispatch(eventsList({ skipPagination: true }));
          }
          setRedirectionType(val);
        }
        if (key === "image_type") {
          setSelectedType(
            bannersSingle[key] === 0
              ? Constants.typeCoursePckgOptions?.[0]
              : bannersSingle[key] === 1
              ? Constants.typeCoursePckgOptions?.[1]
              : Constants.typeCoursePckgOptions?.[2]
          );
          const val =
            bannersSingle[key] === 0
              ? bannersSingle?.course
              : bannersSingle[key] === 1
              ? bannersSingle?.course
              : bannersSingle?.event;
          setSelectedTypeOption({ value: val?._id, label: val?.name });
        }
        if (
          [
            "video",
            "is_video_free",
            "is_one_time_playable",
            "video_thumbnail",
            "video_price",
          ].includes(key) &&
          bannersSingle?.banner_videos?.[key]
        ) {
          if (key === "is_video_free") {
            setVideoFree(bannersSingle?.banner_videos?.[key]);
          }
          editValue[key] = bannersSingle?.banner_videos?.[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [bannersSingle]);

  // Image Aws url
  const handleFileChange = (file, setFieldValue, field) => {
    setFieldValue(field, file?.[0]);
  };

  const handleRedirectionType = (option, setValue, type) => {
    // Handle the redirection option for banner form
    if (option.key === Constants.imageKey) {
      dispatch(coursesList({ skipPagination: true }));
      dispatch(packagesList({ skipPagination: true }));
      dispatch(eventsList({ skipPagination: true }));
    }
    setRedirectionType(option);
    setValue(type, option.key);
  };

  // video url
  const handleVideoChange = (file, setFieldValue, field) => {
    setFieldValue(field, file);
    setShowProgress(true);
    setVideoUrl(file);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update banner API
        if (redirectionType?.key === Constants.imageKey) {
          if (selectedType?.value === Constants.course) {
            values.image_type = Constants.courseDbKey;
            values.course_id = selectedTypeOption?.value;
            delete values.package_id;
            delete values.event_id;
          } else if (selectedType?.value === Constants.package) {
            values.image_type = Constants.packageDbKey;
            values.package_id = selectedTypeOption?.value;
            delete values.course_id;
            delete values.event_id;
          } else {
            values.image_type = Constants.eventDbKey;
            values.event_id = selectedTypeOption?.value;
            delete values.course_id;
            delete values.package_id;
          }
        }
        let submitFunc = bannersInsert(values);
        if (bannersSingle?._id) {
          const id = bannersSingle?._id;
          submitFunc = bannersUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(bannersList());
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.BANNERS_SINGLE, payload: "" });
            dispatch({ type: types.BANNERS_FORM_MODEL, payload: "" });
            if (response?.data?.data?.redirection_type === Constants.formKey) {
              window.location.href = `${window.location.origin}${RoutesUrl.banner}/${response?.data?.data?._id}?activeTab=form`;
            } else {
              window.location.reload();
            }
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row form-in">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          {[
            {
              name: "image",
              type: Constants.imageApp,
              width: 315,
              height: 185,
            },
            {
              name: "image_web",
              type: Constants.imageWeb,
              width: 1808,
              height: 430,
            },
          ].map((item) => (
            <div className="col-md-6 mb-2" key={item.name}>
              <label className="form-label">{`${Constants.image} ${item?.type}`}</label>
              <RequiredIcon />
              <div className="out-cr">
                <div className="width-outer-up">
                  {values && !values?.[item.name] && (
                    <FileAwsUpload
                      fileType={[".jpeg", ".png", ".jpg"]}
                      width={item.width}
                      height={item.height}
                      errors={touched?.image ? errors?.image : undefined}
                      touched={touched?.image}
                      label={`${Constants.upload} ${Constants.image}`}
                      handleFile={(image) =>
                        handleFileChange(image, setFieldValue, item?.name)
                      }
                      maxFiles={1}
                    />
                  )}
                  {values && values[item.name] && (
                    <ViewImageFile
                      file={values[item.name]}
                      onClick={() => setFieldValue(item.name, "")}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="col-md-12 mt-2">
            <RadioButton
              label={Constants.redirectionType}
              options={Constants.redirectionTypeOptions}
              name="redirection_type"
              checked={redirectionType}
              onChange={(option) =>
                handleRedirectionType(option, setFieldValue, "redirection_type")
              }
            />
          </div>
          {/* If redirection type for banner form is video */}
          {redirectionType?.key === Constants.videoKey && (
            <>
              <div className="col-md-6 mb-2">
                <div className="flex-toggle ">
                  <ToggleField
                    onChange={(e) => {
                      setFieldValue("is_one_time_playable", e.target.checked);
                    }}
                    checked={values.is_one_time_playable}
                  />
                  <label className="form-label">
                    {Constants.oneTimePlayable}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div className="flex-toggle ">
                  <ToggleField
                    onChange={(e) => {
                      setFieldValue("is_video_free", e.target.checked);
                      setVideoFree(e.target.checked);
                    }}
                    checked={values.is_video_free}
                  />
                  <label className="form-label">{Constants.free}</label>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">{`${Constants.video}`}</label>
                <RequiredIcon />
                <div>
                  {values && !values?.video && (
                    <VideoTusUpload
                      errors={touched?.video ? errors?.video : undefined}
                      touched={touched?.video}
                      label={`${Constants.upload} ${Constants.video}`}
                      handleFile={(video) =>
                        handleVideoChange(video, setFieldValue, "video")
                      }
                    />
                  )}
                  {showProgress && videoUrl && (
                    <VideoDuration
                      url={videoUrl}
                      fetchDuration={(duration) => {
                        setFieldValue("duration", duration);
                        setShowProgress(false);
                      }}
                    />
                  )}
                  {values && !showProgress && values?.video && (
                    <ViewVideoFile
                      file={values?.video}
                      onClick={() => {
                        setFieldValue("video", "");
                        setFieldValue("duration", "");
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">{`${Constants.video} ${Constants.thumbnail}`}</label>
                <RequiredIcon />
                <div className="out-cr">
                  <div className="width-outer-up">
                    {values && !values?.video_thumbnail && (
                      <FileAwsUpload
                        fileType={[".jpeg", ".png", ".jpg"]}
                        width="1280"
                        height="720"
                        errors={
                          touched?.video_thumbnail
                            ? errors?.video_thumbnail
                            : undefined
                        }
                        touched={touched?.video_thumbnail}
                        label={`${Constants.upload} ${Constants.video} ${Constants.thumbnail}`}
                        handleFile={(video_thumbnail) =>
                          handleFileChange(
                            video_thumbnail,
                            setFieldValue,
                            "video_thumbnail"
                          )
                        }
                        maxFiles={1}
                      />
                    )}
                    {values && values?.video_thumbnail && (
                      <ViewImageFile
                        file={values?.video_thumbnail}
                        onClick={() => setFieldValue("video_thumbnail", "")}
                      />
                    )}
                  </div>
                </div>
              </div>
              {!values.is_video_free && (
                <div className="col-md-12 mb-2">
                  <TextField
                    type="text"
                    name="video_price"
                    className="form-control"
                    errors={
                      touched?.video_price ? errors?.video_price : undefined
                    }
                    touched={touched?.video_price}
                    label={Constants.price}
                  />
                </div>
              )}
            </>
          )}
          {redirectionType?.key === Constants.imageKey && (
            <>
              <div className="col-md-6">
                <ReactSelect
                  label={`${Constants.select} ${Constants.type}`}
                  required={false}
                  options={Constants.typeCoursePckgOptions}
                  onChange={(e) => {
                    setSelectedType(e);
                    setSelectedTypeOption("");
                  }}
                  value={selectedType}
                />
              </div>
              <div className="col-md-6">
                <ReactSelect
                  label={`${Constants.select} ${
                    selectedType?.value === Constants.course
                      ? Constants.course
                      : selectedType?.value === Constants.event
                      ? Constants.event
                      : Constants.package
                  }`}
                  required={true}
                  options={typeOptions}
                  errors={touched?.course_id ? errors?.course_id : undefined}
                  touched={touched?.course_id}
                  onChange={(e) => {
                    setFieldValue("course_id", e.value);
                    setSelectedTypeOption(e);
                  }}
                  value={selectedTypeOption}
                />
              </div>
            </>
          )}
          {((addPermission && !bannersSingle?._id) ||
            (editPermission && bannersSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default BannerForm;

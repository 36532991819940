import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import TextField from "../../../Common/Fields/TextField";
import { Constants } from "../../../Common/Constants";
import * as Yup from "yup";
import ButtonCustom from "../../../Common/Fields/ButtonCustom";
import ViewImageFile from "../../../Common/Fields/ViewImageFile";
import TextArea from "../../../Common/Fields/TextArea";
import { useState, useEffect } from "react";
import ViewVideoFile from "../../../Common/Fields/ViewVideoFile";
import {
  categoryList,
  coursesCategoryList,
  lessonsInsert,
  lessonsOptionsList,
  coursesOptionDetail,
  lessonsUpdate,
  modulesOptionsList,
  lessonsList,
} from "../../../../Redux/Actions";
import { types } from "../../../../Redux/Actions/types";
import ReactSelect from "../../../Common/Fields/ReactSelect";
import React, { useParams } from "react-router-dom";
import getOptionsFromData from "../../../Common/Functions/getOptionsFromData";
import ToggleField from "../../../Common/Fields/ToggleField";
import RequiredIcon from "../../../Common/Fields/RequiredIcon";
import FileAwsUpload from "../../../Common/Fields/FileAwsUpload";
import VideoTusUpload from "../../../Common/Fields/VideoTusUpload";
import VideoDuration from "../../../Common/Fields/VideoDuration";
import usePermissions from "../../../Common/Functions/usePermissions";

const LessonForm = () => {
  const today = new Date().toISOString().split("T")[0];
  const dispatch = useDispatch();
  const { id, moduleId } = useParams();
  const [lessonVideoSelect, setLessonVideoSelect] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [coursesSelected, setCoursesSelected] = useState([]);
  const [lessonsOptions, setLessonsOptions] = useState([]);
  const [lessonsSelected, setLessonsSelected] = useState([]);
  const [modulesOptions, setModulesOptions] = useState([]);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  let lessonData;
  // Set lesson data object based on presence or absence of module ID
  if (moduleId) {
    lessonData = { id, moduleId };
  } else {
    lessonData = { id };
  }
  // Permission
  const addPermission = usePermissions(Constants.permission.COURSES_ADD);
  const editPermission = usePermissions(Constants.permission.COURSES_EDIT);
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    thumbnail: "",
    thumbnail_web: "",
    video_thumbnail: "",
    video: "",
    set_rule_date: "",
    course: id,
    module: moduleId || null,
    category_id: null,
    subcategory_id: null,
    lesson_course: null,
    lesson_module: null,
    lesson_id: null,
  });
  const { lessonsSingle } = useSelector((state) => state.Lessons);
  const { categorylist } = useSelector((state) => state.Category);
  const { coursescategorylist, coursesOptionsSingle } = useSelector(
    (state) => state.Courses
  );
  const { lessonsOptionslist } = useSelector((state) => state.Lessons);
  const { modulesoptionslist } = useSelector((state) => state.Modules);

  // Validations for lesson form
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
    description: Yup.string().required(Constants.required),
    thumbnail: !lessonVideoSelect
      ? Yup.string().required(Constants.required)
      : "",
    thumbnail_web: !lessonVideoSelect
      ? Yup.string().required(Constants.required)
      : "",
    video_thumbnail: !lessonVideoSelect
      ? Yup.string().required(Constants.required)
      : "",
    video: !lessonVideoSelect ? Yup.string().required(Constants.required) : "",
    set_rule_date:
      coursesOptionsSingle?.set_rule === "2"
        ? Yup.string().required(Constants.required)
        : "",
    category_id: lessonVideoSelect
      ? Yup.string().required(Constants.required)
      : "",
    lesson_course: lessonVideoSelect
      ? Yup.string().required(Constants.required)
      : "",
    lesson_module:
      lessonVideoSelect && coursesOptionsSingle?.module_lessons
        ? Yup.string().required(Constants.required)
        : "",
    lesson_id: lessonVideoSelect
      ? Yup.string().required(Constants.required)
      : "",
  });

  // Get catgeory list
  useEffect(() => {
    dispatch(categoryList());
  }, []);

  // if video is to be added from previous videos
  useEffect(() => {
    if (lessonVideoSelect) {
      if (coursesOptionsSingle?.module_lessons) {
        dispatch(
          modulesOptionsList({
            id: coursesOptionsSingle?._id,
            skipPagination: true,
          })
        );
      } else {
        dispatch(
          lessonsOptionsList({
            id: coursesOptionsSingle?._id,
            skipPagination: true,
          })
        );
      }
    }
  }, [coursesOptionsSingle]);

  // Convert category list API data to array of objects (value & label)
  useEffect(() => {
    setCategoryOptions(getOptionsFromData(categorylist?.data));
  }, [categorylist?.data]);

  // Convert courses based on category list API data to array of objects (value & label)
  useEffect(() => {
    setCoursesOptions(getOptionsFromData(coursescategorylist?.data));
  }, [coursescategorylist?.data]);

  // Convert lessons list API data to array of objects (value & label)
  useEffect(() => {
    setLessonsOptions(getOptionsFromData(lessonsOptionslist?.data));
  }, [lessonsOptionslist?.data]);

  // Convert modules list API data to array of objects (value & label)
  useEffect(() => {
    setModulesOptions(getOptionsFromData(modulesoptionslist?.data));
  }, [modulesoptionslist?.data]);

  // set initial values to pre-fill the form
  useEffect(() => {
    if (lessonsSingle) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (lessonsSingle[key]) {
          editValue[key] = lessonsSingle[key];
        }
        if (key === "category_id") {
          const val = lessonsSingle[key];
          if (val?._id) {
            setLessonVideoSelect(true);
            editValue[key] = val?._id;
            setCategorySelected({ value: val?._id, label: val?.name });
            const data = { category_id: val?.id };
            dispatch(coursesCategoryList(data));
          }
        }
        if (key === "lesson_course") {
          const val = lessonsSingle[key];
          if (val?._id) {
            editValue[key] = val?._id;
            setCoursesSelected({ value: val?._id, label: val?.name });
            dispatch(modulesOptionsList({ id: val?._id }));
          }
        }
        if (key === "lesson_module") {
          const val = lessonsSingle[key];
          if (val?._id) {
            setModulesSelected({ value: val?._id, label: val?.name });
            dispatch(lessonsOptionsList({ id, moduleId: val?._id }));
            editValue[key] = val?._id;
          }
        }
        if (key === "lesson_id") {
          const val = lessonsSingle[key];
          setLessonsSelected({ value: val?._id, label: val?.name });
          editValue[key] = val?._id;
        }
        setInitialValues(editValue);
      }
    }
  }, [lessonsSingle]);

  // set thumbnail, video thumbnail from aws link
  const handleFileChange = (file, setFieldValue, field) => {
    setFieldValue(field, file?.[0]);
  };

  // On change of category, hit the category course API
  const handleCategoryOptionChange = (e, setFieldValue, field, setSelected) => {
    setCoursesSelected([]);
    const data = { category_id: e.value };
    dispatch(coursesCategoryList(data));
    setSelected(e);
    setFieldValue(field, e.value);
  };

  // On course change, hit course detail API to check whether module status is true or false
  const handleCourseChange = (e, setFieldValue, field, setSelected) => {
    setSelected(e);
    setFieldValue(field, e.value);
    dispatch(coursesOptionDetail(e.value));
  };

  // On change of module, get lessons list based on module
  const handleModuleChange = (e, setFieldValue, field, setSelected) => {
    setSelected(e);
    setFieldValue(field, e.value);
    dispatch(lessonsOptionsList({ id, moduleId: e.value }));
  };

  // Handle lesson change
  const handleLessonChange = (e, setFieldValue, field, setSelected) => {
    setSelected(e);
    setFieldValue(field, e.value);
  };

  // Set video aws url
  const handleVideoChange = (file, setFieldValue, field) => {
    setFieldValue(field, file);
    setShowProgress(true);
    setVideoUrl(file);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Lesson add or edit API
        let submitFunc = lessonsInsert(values);
        if (lessonsSingle?._id) {
          const id = lessonsSingle?._id;
          submitFunc = lessonsUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(lessonsList(lessonData));
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.LESSONS_SINGLE, payload: "" });
            dispatch({ type: types.LESSONS_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextArea
              type="text"
              name="description"
              className="form-control"
              errors={touched?.description ? errors?.description : undefined}
              touched={touched?.description}
              label={Constants.description}
            />
          </div>
          {coursesOptionsSingle?.set_rule === "2" && (
            <div className="col-md-12 mb-2">
              <TextField
                type="date"
                name="set_rule_date"
                className="form-control"
                min={today}
                errors={
                  touched?.set_rule_date ? errors?.set_rule_date : undefined
                }
                touched={touched?.set_rule_date}
                label={Constants.selectDate}
              />
            </div>
          )}
          <div className="col-md-12 mb-2">
            <label className="form-label">{`${Constants.select} ${Constants.video}`}</label>
            <ToggleField
              onChange={(e) => {
                setLessonVideoSelect((prevVal) => !prevVal);
              }}
              checked={lessonVideoSelect}
            />
          </div>
          {lessonVideoSelect && (
            <>
              <div className="col-md-6 mb-4">
                <ReactSelect
                  label={Constants.selectCategory}
                  required={true}
                  options={categoryOptions}
                  errors={
                    touched?.category_id ? errors?.category_id : undefined
                  }
                  touched={touched?.category_id}
                  onChange={(selected) =>
                    handleCategoryOptionChange(
                      selected,
                      setFieldValue,
                      "category_id",
                      setCategorySelected
                    )
                  }
                  value={categorySelected}
                />
              </div>
              {categorySelected?.value && (
                <div className="col-md-6 mb-4">
                  <ReactSelect
                    label={`${Constants.select} ${Constants.course}`}
                    required={true}
                    options={coursesOptions}
                    errors={
                      touched?.lesson_course ? errors?.lesson_course : undefined
                    }
                    touched={touched?.lesson_course}
                    onChange={(selected) =>
                      handleCourseChange(
                        selected,
                        setFieldValue,
                        "lesson_course",
                        setCoursesSelected
                      )
                    }
                    value={coursesSelected}
                  />
                </div>
              )}
              {coursesOptionsSingle?.module_lessons &&
                coursesSelected?.value && (
                  <div className="col-md-6 mb-4">
                    <ReactSelect
                      label={Constants.selectModule}
                      required={true}
                      options={modulesOptions}
                      errors={
                        touched?.lesson_module
                          ? errors?.lesson_module
                          : undefined
                      }
                      touched={touched?.lesson_module}
                      onChange={(selected) =>
                        handleModuleChange(
                          selected,
                          setFieldValue,
                          "lesson_module",
                          setModulesSelected
                        )
                      }
                      value={modulesSelected}
                    />
                  </div>
                )}
              {((coursesSelected?.value &&
                !coursesOptionsSingle?.module_lessons) ||
                (coursesOptionsSingle?.module_lessons &&
                  modulesSelected?.value)) && (
                <div
                  className={`${
                    coursesOptionsSingle?.module_lessons
                      ? "col-md-6"
                      : "col-md-12"
                  } mb-2`}
                >
                  <ReactSelect
                    label={Constants.selectLessons}
                    required={true}
                    options={lessonsOptions}
                    errors={touched?.lesson_id ? errors?.lesson_id : undefined}
                    touched={touched?.lesson_id}
                    onChange={(selected) =>
                      handleLessonChange(
                        selected,
                        setFieldValue,
                        "lesson_id",
                        setLessonsSelected
                      )
                    }
                    value={lessonsSelected}
                  />
                </div>
              )}
            </>
          )}
          {!lessonVideoSelect && (
            <>
              {[
                {
                  name: "thumbnail",
                  type: Constants.imageApp,
                  width: 85,
                  height: 85,
                },
                {
                  name: "thumbnail_web",
                  type: Constants.imageWeb,
                  width: 68,
                  height: 68,
                },
              ].map((item) => (
                <div className="col-md-6 mb-2" key={item.name}>
                  <label className="form-label">{`${Constants.thumbnail} ${item?.type}`}</label>
                  <RequiredIcon />
                  <div className="out-cr">
                    <div className="width-outer-up">
                      {values && !values?.[item.name] && (
                        <FileAwsUpload
                          fileType={[".jpeg", ".png", ".jpg"]}
                          width={item.width}
                          height={item.height}
                          errors={
                            touched?.[item.name]
                              ? errors?.[item.name]
                              : undefined
                          }
                          touched={touched?.[item.name]}
                          label={`${Constants.upload} ${Constants.thumbnail}`}
                          handleFile={(thumbnail) =>
                            handleFileChange(
                              thumbnail,
                              setFieldValue,
                              item.name
                            )
                          }
                          maxFiles={1}
                        />
                      )}
                      {values && values?.[item.name] && (
                        <ViewImageFile
                          file={values[item.name]}
                          onClick={() => setFieldValue(item.name, "")}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-md-6 mb-4">
                <label className="form-label">{`${Constants.video}`}</label>
                <RequiredIcon />
                {values && !values?.video && (
                  <VideoTusUpload
                    errors={touched?.video ? errors?.video : undefined}
                    touched={touched?.video}
                    label={`${Constants.upload} ${Constants.video}`}
                    handleFile={(video) =>
                      handleVideoChange(video, setFieldValue, "video")
                    }
                  />
                )}
                {showProgress && videoUrl && (
                  <VideoDuration
                    url={videoUrl}
                    fetchDuration={(duration) => {
                      setFieldValue("duration", duration);
                      setShowProgress(false);
                    }}
                  />
                )}
                {values && !showProgress && values?.video && (
                  <ViewVideoFile
                    file={values?.video}
                    onClick={() => {
                      setFieldValue("video", "");
                      setFieldValue("duration", "");
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">{`${Constants.video} ${Constants.thumbnail}`}</label>
                <RequiredIcon />
                <div className="out-cr">
                  <div className="width-outer-up">
                    {values && !values?.video_thumbnail && (
                      <FileAwsUpload
                        fileType={[".jpeg", ".png", ".jpg"]}
                        width="1280"
                        height="720"
                        errors={
                          touched?.video_thumbnail
                            ? errors?.video_thumbnail
                            : undefined
                        }
                        touched={touched?.video_thumbnail}
                        label={`${Constants.upload} ${Constants.video} ${Constants.thumbnail}`}
                        handleFile={(video_thumbnail) =>
                          handleFileChange(
                            video_thumbnail,
                            setFieldValue,
                            "video_thumbnail"
                          )
                        }
                        maxFiles={1}
                      />
                    )}
                    {values && values?.video_thumbnail && (
                      <ViewImageFile
                        file={values?.video_thumbnail}
                        onClick={() => setFieldValue("video_thumbnail", "")}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {((addPermission && !lessonsSingle?._id) ||
            (editPermission && lessonsSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default LessonForm;

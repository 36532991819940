import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Constants } from "../Common/Constants";
import { Form, Formik } from "formik";
import { types } from "../../Redux/Actions/types";
import TextField from "../Common/Fields/TextField";
import RequiredIcon from "../Common/Fields/RequiredIcon";
import ViewImageFile from "../Common/Fields/ViewImageFile";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import TextArea from "../Common/Fields/TextArea";
import {
  freeVideosInsert,
  freeVideosList,
  freeVideosUpdate,
  goalsList,
} from "../../Redux/Actions";
import ViewVideoFile from "../Common/Fields/ViewVideoFile";
import MultiSelect from "../Common/Fields/MultiSelect";
import getOptionsFromData from "../Common/Functions/getOptionsFromData";
import FileAwsUpload from "../Common/Fields/FileAwsUpload";
import VideoTusUpload from "../Common/Fields/VideoTusUpload";
import VideoDuration from "../Common/Fields/VideoDuration";
import usePermissions from "../Common/Functions/usePermissions";

const FreeVideoForm = () => {
  const dispatch = useDispatch();
  const [goalSelected, setGoalSelected] = useState([]);
  const [goalOptions, setGoalOptions] = useState([]);
  const { freeVideosSingle } = useSelector((state) => state.FreeVideos);
  const { goalslist } = useSelector((state) => state.Goals);
  // Initial values for free Video form
  const [initialValues, setInitialValues] = useState({
    name: "",
    teacher_name: "",
    description: "",
    thumbnail_image: "",
    thumbnail_image_web: "",
    video_thumbnail: "",
    video: "",
    duration: "",
    goals: [],
  });
  const [showProgress, setShowProgress] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const addPermission = usePermissions(Constants.permission.FREE_VIDEO_ADD);
  const editPermission = usePermissions(Constants.permission.FREE_VIDEO_EDIT);

  // Validation for free Video form
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
    teacher_name: Yup.string()
      .required(Constants.required)
      .matches(Constants.validNameRegex, Constants.invalid),
    description: Yup.string().required(Constants.required),
    thumbnail_image: Yup.string().required(Constants.required),
    thumbnail_image_web: Yup.string().required(Constants.required),
    video_thumbnail: Yup.string().required(Constants.required),
    video: Yup.string().required(Constants.required),
    goals: Yup.array().min(1, Constants.required),
  });

  useEffect(() => {
    // Goal list API
    dispatch(goalsList());
  }, []);

  useEffect(() => {
    // Set initial values for pre-filled form
    if (freeVideosSingle?._id) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        editValue[key] = freeVideosSingle[key];
        if (key === "goals") {
          setGoalSelected(
            freeVideosSingle.goals.map((elt) => ({
              label: elt?.name,
              value: elt?._id,
            }))
          );
        }
        setInitialValues(editValue);
      }
    }
  }, [freeVideosSingle]);

  useEffect(() => {
    // Convert goal list API data to array of objects (value & label)
    setGoalOptions(getOptionsFromData(goalslist?.data));
  }, [goalslist?.data]);

  // handle image for free video
  const handleFileChange = (file, setFieldValue, field) => {
    setFieldValue(field, file?.[0]);
  };
  // add video AWS URL
  const handleVideoChange = (file, setFieldValue, field) => {
    setFieldValue(field, file);
    setShowProgress(true);
    setVideoUrl(file);
  };
  // function to handle goal changes
  const handleOptionsChange = (e, setFieldValue, field, setSelected) => {
    setSelected(e);
    const ids = e.map((elt) => elt.value);
    setFieldValue(field, ids);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update free Video API
        let submitFunc = freeVideosInsert(values);
        if (freeVideosSingle?._id) {
          const id = freeVideosSingle?._id;
          submitFunc = freeVideosUpdate(id, values);
        }
        dispatch(submitFunc).then((response) => {
          if (response?.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response?.error,
            });
          } else {
            dispatch(freeVideosList());
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response?.data?.message,
            });
            dispatch({ type: types.FREE_VIDEOS_SINGLE, payload: "" });
            dispatch({ type: types.FREE_VIDEOS_FORM_MODEL, payload: "" });
          }
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="teacher_name"
              className="form-control"
              errors={touched?.teacher_name ? errors?.teacher_name : undefined}
              touched={touched?.teacher_name}
              label={Constants.teacherName}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextArea
              type="text"
              name="description"
              className="form-control"
              errors={touched?.description ? errors?.description : undefined}
              touched={touched?.description}
              label={Constants.description}
            />
          </div>
          {[
            {
              name: "thumbnail_image",
              type: Constants.imageApp,
              width: 343,
              height: 165,
            },
            {
              name: "thumbnail_image_web",
              type: Constants.imageWeb,
              width: 365,
              height: 192,
            },
          ].map((item, index) => (
            <div className="col-md-6 mb-2" key={index}>
              <label className="form-label">{`${Constants.thumbnail} ${item.type}`}</label>
              <RequiredIcon />
              <div className="out-cr">
                <div className="width-outer-up">
                  {values && !values[item.name] && (
                    <FileAwsUpload
                      fileType={[".jpeg", ".png", ".jpg"]}
                      errors={
                        touched[item.name] ? errors[item.name] : undefined
                      }
                      touched={touched[item.name]}
                      width={item.width}
                      height={item.height}
                      label={`${Constants.upload} ${Constants.thumbnail}`}
                      handleFile={(thumbnailImage) =>
                        handleFileChange(
                          thumbnailImage,
                          setFieldValue,
                          item.name
                        )
                      }
                      maxFiles={1}
                    />
                  )}
                  {values && values[item.name] && (
                    <ViewImageFile
                      file={values[item.name]}
                      onClick={() => setFieldValue(item.name, "")}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}

          <div className="col-md-6 mb-2">
            <label className="form-label">{`${Constants.video}`}</label>
            <RequiredIcon />
            <div>
              {values && !values?.video && (
                <VideoTusUpload
                  errors={touched?.video ? errors?.video : undefined}
                  touched={touched?.video}
                  label={`${Constants.upload} ${Constants.video}`}
                  handleFile={(video) =>
                    handleVideoChange(video, setFieldValue, "video")
                  }
                />
              )}
              {showProgress && videoUrl && (
                <VideoDuration
                  url={videoUrl}
                  fetchDuration={(duration) => {
                    setFieldValue("duration", duration);
                    setShowProgress(false);
                  }}
                />
              )}
              {values && !showProgress && values?.video && (
                <ViewVideoFile
                  file={values?.video}
                  onClick={() => {
                    setFieldValue("video", "");
                    setFieldValue("duration", "");
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">{`${Constants.video} ${Constants.thumbnail}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up">
                {values && !values?.video_thumbnail && (
                  <FileAwsUpload
                    fileType={[".jpeg", ".png", ".jpg"]}
                    width="1280"
                    height="720"
                    errors={
                      touched?.video_thumbnail
                        ? errors?.video_thumbnail
                        : undefined
                    }
                    touched={touched?.video_thumbnail}
                    label={`${Constants.upload} ${Constants.video} ${Constants.thumbnail}`}
                    handleFile={(video_thumbnail) =>
                      handleFileChange(
                        video_thumbnail,
                        setFieldValue,
                        "video_thumbnail"
                      )
                    }
                    maxFiles={1}
                  />
                )}
                {values && values?.video_thumbnail && (
                  <ViewImageFile
                    file={values?.video_thumbnail}
                    onClick={() => setFieldValue("video_thumbnail", "")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12 my-2">
            <label className="form-label">{`${Constants.select} ${Constants.configGoals}`}</label>
            <RequiredIcon />
            <MultiSelect
              key="multi_select"
              options={goalOptions}
              onChange={(selected) =>
                handleOptionsChange(
                  selected,
                  setFieldValue,
                  "goals",
                  setGoalSelected
                )
              }
              value={goalSelected}
              errors={touched?.goals ? errors?.goals : undefined}
              touched={touched?.goals}
              isSelectAll={true}
              menuPlacement={"bottom"}
            />
          </div>
          {((addPermission && !freeVideosSingle?._id) ||
            (editPermission && freeVideosSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default FreeVideoForm;

import React, { useEffect, useState } from "react";
import { City } from "country-state-city";
import MultiSelect from "../Common/Fields/MultiSelect";
import { Constants } from "../Common/Constants";

// City filter based on selected state
const StateCityFilter = ({ states, selectedCities }) => {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);

  useEffect(() => {
    if (states?.length === 0) {
      setSelectedCity([]);
      setCities([]);
      return;
    }
    // Get all the cities based on selected states
    const allCities = states?.flatMap((state) =>
      City.getCitiesOfState("IN", state.value).map((city) => ({
        value: city.name,
        label: city.name,
      }))
    );
    setCities(allCities);
  }, [states]);

  return (
    <>
      <label className="form-label">{`${Constants.city}`}</label>
      <MultiSelect
        key="multi_select"
        options={cities}
        onChange={(selected) => {
          setSelectedCity(selected);
          selectedCities(selected);
        }}
        isSelectAll={true}
        value={selectedCity}
        menuPlacement={"bottom"}
      />
    </>
  );
};

export default StateCityFilter;

export const ApiUrl = {
  ABANDONED_USERS: "abandoned_users",
  AFFILIATE: "affiliate_users",
  AFFILIATE_VIDEO: "affiliate_video",
  AFFILIATE_VIDEO_STATUS_UPDATE: "affiliate_video/update-status",
  AWS_FILE_SIGNATURE: "user/aws_file_signature?filename=",
  BANNERS: "banners",
  BANNERS_FORM: "banners-form",
  BANNERS_STATUS_UPDATE: "banners/update-status",
  BANNERS_USERS: "banners/users",
  CALCULATE_REFUND_AMOUNT: "user/calculate_refunded_amount",
  CATEGORY: "categories",
  CATEGORY_ORDER_UPDATE: "categories/update-order",
  CATEGORY_STATUS_UPDATE: "categories/update-status",
  CONFIGURATION: "configuration",
  CONFIG_FAQ: "configuration/faqs",
  CONFIG_STATUS_FAQ: "configuration/faqs/update-status",
  COURSE_COMPLETION: "change_course_percentage",
  COURSE_USERS_SAMPLE_EXCEL: "courses/download_sample",
  COURSES: "courses",
  COURSES_RECOMMENDED_UPDATE: "courses/update-recomended",
  COURSES_STATUS_UPDATE: "courses/update-status",
  COURSES_FAQS: "faqs",
  COURSES_FAQS_STATUS_UPDATE: "faqs/update-status",
  DASHBOARD: "dashboard",
  DOWNLOAD_CERTIFICATE: "download_certificate",
  EMAIL_SEND: "configuration/send-email",
  ENROLL_USER: "user/enrolled_from_admin",
  ENROLLED_USERS: "enrolled_users",
  ENROLLED_USER_RESET_COURSE: "enrolled_user/reset_course",
  EVENTS: "events",
  EVENTS_STATUS_UPDATE: "events/update-status",
  EVENTS_USERS: "events/users",
  FREE_VIDEOS: "free_videos",
  FREE_VIDEOS_STATUS_UPDATE: "free_videos/update-status",
  GOALS: "goals",
  GOALS_STATUS_UPDATE: "goals/update-status",
  GOALS_ORDER_UPDATE: "goals/update-order",
  IMAGE_URL: "user/image-uploads",
  IMPORT_COURSE_USER: "/courses/import_users",
  INVOICE_BILL: "billInvoice",
  KYC_APPROVAL: "users/kyc_approval",
  LESSONS: "lessons",
  LESSONS_STATUS_UPDATE: "lessons/update-status",
  LOGIN: "auth/login",
  LOGOUT: "auth/user_logout",
  MODULES: "modules",
  MODULES_STATUS_UPDATE: "modules/update-status",
  NEWS: "news",
  NEWS_STATUS_UPDATE: "news/update-status",
  NOTIFICATION_SEND: "configuration/send-notification",
  NOTIFICATION_LIST: "notification_list",
  PACKAGES: "packages",
  PACKAGE_COURSES: "packages/courses",
  PACKAGES_STATUS_UPDATE: "packages/update-status",
  PERMISSION: "permission",
  PROMOCODES: "promo_codes",
  PROMOCODES_STATUS_UPDATE: "promo_codes/update-status",
  PROMOCODES_USERS: "promo_response",
  QUES: "question",
  QUES_STATUS_UPDATE: "question/update-status",
  REFRESH_ACCESS_TOKEN: "auth/refresh_access_token",
  REFUND: "/admin/refund_payment",
  // REFUND: "user/refund_payment",
  REPORT: "reports",
  REPORT_GET_EXPORTS: "get_exports",
  RESEND_INVOICE: "resend_invoice",
  RESOURCES: "resources",
  RESOURCES_STATUS_UPDATE: "resources/update-status",
  REVIEWS: "reviews",
  REVIEWS_STATUS_UPDATE: "reviews/update-status",
  ROLES: "roles",
  SAVE_EXPORT: "/save_exports",
  SETS: "sets",
  SUB_CATEGORY: "subcategories",
  SUB_CATEGORY_STATUS_UPDATE: "subcategories/update-status",
  SUPPORT: "support",
  UPDATE_SUPPORT: "update_support_status",
  UPDATE_CONTACT_STATUS: "users/update_contact_status",
  TRANSACTIONS: "transactions",
  UPDATE_LEAD_STATUS: "users/update_lead_status",
  USERS: "users",
  USER_COMMENTS: "user_comments",
  WELCOME_EMAIL: "welcome_email",
  WITHDRAW: "admin/withdrawal_requests",
};

import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const configFaqsAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.CONFIG_FAQ, data);
  return response;
};

export const configFaqsEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.CONFIG_FAQ + "?id=" + id,
    data
  );
  return response;
};

export const configFaqsRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.CONFIG_FAQ + "?id=" + id
  );
  return response;
};

export const configFaqsStatusUpdate = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.CONFIG_STATUS_FAQ + "?id=" + id,
    data
  );
  return response;
};

export const configFaqs = async (data) => {
  const url = `${ApiUrl.CONFIG_FAQ}?skip=${data?.skip || 0}&limit=${
    data?.limit || Constants.limitCount
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};
